import styled, { css } from 'styled-components';
import { Checkbox as checkbox } from 'antd';
import { mainColor, mainTextColor } from '../../../constants/colors';

const Checkbox = styled(checkbox)`
  &:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${mainColor};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${mainColor};
    border-color: ${mainColor};
  }
  .ant-checkbox-checked::after {
    border: 1px solid ${mainColor};
  }
  & input {
    width: 100% !important;
  }

  ${props =>
    props.color_type === 'green' &&
    css`
      &:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #14b354;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #14b354;
        border-color: #14b354;

        &::after {
          border-color: #fff;
        }
      }
      .ant-checkbox-checked::after {
        border: 1px solid #14b354;
      }
    `}

  ${props =>
    props.color_type === 'blue' &&
    css`
      &:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: ${mainColor};
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${mainColor};
        border-color: ${mainColor};

        &::after {
          border-color: #fff;
        }
      }
      .ant-checkbox-checked::after {
        border: 1px solid ${mainColor};
      }
    `}
  ${props =>
    props.color_type === 'destructive' &&
    css`
      &:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #ef3e36;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #ef3e36;
        border-color: #ef3e36;

        &::after {
          border-color: #fff;
        }
      }
      .ant-checkbox-checked::after {
        border: 1px solid #ef3e36;
      }
    `}
  ${props =>
    props.color_type === 'ghost_destructive' &&
    css`
      &:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #ef3e36;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #fff;
        border-color: #ef3e36;

        &::after {
          border-color: #ef3e36;
        }
      }
      .ant-checkbox-checked::after {
        border: 1px solid #ef3e36;
      }
    `}
  ${props =>
    props.color_type === 'soft_blue' &&
    css`
      &:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: ${mainTextColor};
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${mainTextColor};
        border-color: ${mainTextColor};

        &::after {
          border-color: #fff;
        }
      }
      .ant-checkbox-checked::after {
        border: 1px solid ${mainTextColor};
      }
    `}
    ${props =>
    props.color &&
    css`
      &:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: ${props.color};
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${props.color};
        border-color: ${props.color};

        &::after {
          border-color: #fff;
        }
      }
      .ant-checkbox-checked::after {
        border: 1px solid ${props.color};
      }
    `}
  ${props =>
    props.size &&
    css`
      input,
      .ant-checkbox-inner {
        width: ${props.size}px !important;

        height: ${props.size}px !important;
      }

      .ant-checkbox-inner::after {
        left: ${props.size}% !important;
      }
    `}
  ${props =>
    props.width &&
    css`
      input,
      .ant-checkbox-inner {
        width: ${props.width};
      }
    `}
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px!important;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin}!important;
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color}!important;
    `}
    ${props =>
    props.top &&
    css`
      .ant-checkbox {
        top: ${props.top}!important;
      }
    `}
  ${props =>
    props.direction &&
    css`
      flex-direction: ${props.direction}!important;
    `}
  ${props =>
    props.label_margin &&
    css`
      > span:last-child {
        margin: ${props.label_margin};
      }
    `}
  ${props =>
    props.disabled_label_color &&
    css`
      > span:last-child {
        color: ${props.disabled_label_color};
      }
    `}
  ${props =>
    props.fw &&
    css`
      font-weight: ${props.fw} !important;
    `}
  ${props =>
    props.active_color &&
    css`
      &:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: ${props.active_color};
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${props.active_color};
        border-color: ${props.active_color};

        &::after {
          border-color: #fff;
        }
      }
      .ant-checkbox-checked::after {
        border: 1px solid ${props.active_color};
      }
    `}
`;
Checkbox.defaultProps = {};

/** @component */
export default Checkbox;
