import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { registerLicense } from '@syncfusion/ej2-base';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Provider } from 'react-redux';
import { SYNCFUSION_KEY } from 'configs/env-vars';
import reportWebVitals from './reportWebVitals';
import { store } from './app/store';
import App from './App';

registerLicense(SYNCFUSION_KEY);

const root = createRoot(document.getElementById('root'));

const app = (
  <Provider store={store}>
    {/* <BrowserRouter> */}
    <App />
    {/* </BrowserRouter> */}
  </Provider>
);

if (process.env.NODE_ENV === 'development') {
  root.render(app);
} else {
  Bugsnag.start({
    apiKey: 'c6eeac9b637a0b5b315563bffc438325',
    plugins: [new BugsnagPluginReact()],
  });

  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

  root.render(<ErrorBoundary>{app}</ErrorBoundary>);
}

reportWebVitals();
