import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import moment from 'moment';
import {
  SaveOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { Spin } from 'antd';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  FormItem,
  Input,
  InputEmail,
  InputPassword,
  MaskedInput,
  Modal,
  ModalWrapper,
  Row,
  Select,
  Title,
} from 'components/atoms/index';
import { getUser } from 'app/services/selector-helpers';
import { addTeamUserApi, updateTeamUserApi } from 'services/sa-user-teams';
import useApiCall from 'Utils/hooks/useApiCall';
import useGetMainColor from 'Utils/hooks/useGetMainColor';
import { phoneMask, phonePattern } from 'constants/etc';
import { fetchBrokerApi } from 'services/re-broker-agents';
import { checkActiveIcon, filterOption, setAllOnClear } from 'Utils/Helpers';
import { getUsersCountApi } from 'services/users';
import useGetBasicInfosByType from 'Utils/hooks/useGetBasicInfosByType';
import ModalContent from './ModalContent';
import ModalContentUpload from './ModalContentUpload';

const { confirm } = Modal;

const TeamsPopup = ({ editData, closeModal, setEditData, title, subTitle }) => {
  const user = useSelector(getUser);
  const [form] = Form.useForm();
  const [activeColor] = useGetMainColor();
  const [brokerType] = useGetBasicInfosByType('BrokerType');
  const [showConfirm, setShowConfirm] = useState(true);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [brokers, brokersLoading, brokersCall] = useApiCall(fetchBrokerApi);
  const [, updateTeamUserLoading, updateTeamUserCall] = useApiCall(updateTeamUserApi);
  const [, addTeamUserLoading, addTeamUserCall] = useApiCall(addTeamUserApi);

  useEffect(() => {
    brokersCall({ _sort: 'AgencyName', _limit: 5000 });
  }, []);

  useEffect(() => {
    editData?.id && setUploadedFile(editData?.avatar);
    form.setFieldsValue({
      ...(editData?.id ? { ...editData, confirmed: !editData?.confirmed } : {}),
      ActiveEffDate: editData?.ActiveEffDate ? moment(editData?.ActiveEffDate) : null,
      BrokerAgency_id: user?.broker?.BrokerAgency_id?.id,
      blocked: !!editData?.blocked,
      newPassword: null,
      password: null,
    });
  }, [editData]);

  const onFinish = async isSave => {
    const { secPassword, ActiveEffDate, BrokerAgency_id, newPassword, password, ...validValues } =
      await form.validateFields();

    const afterSave = res => {
      if (isSave) {
        setEditData({
          ...(res?.TeamUser_id || {}),
          id: res?.id,
          LeadUserName: editData?.LeadUserName,
          team_id: res?.TeamUser_id?.id,
          ActiveEffDate: res?.ActiveEffDate,
        });
      } else {
        setEditData(null);
        form.resetFields();
      }
      setShowConfirm(false);
    };

    getUsersCountApi({
      email: validValues.email,
      id_ne: editData?.team_id,
    })
      .then(({ data }) => {
        if (data) {
          form.setFields([
            {
              name: 'email',
              errors: ['User Account already exists!'],
            },
          ]);
          return;
        }

        const values = {
          userParams: {
            ...validValues,
            ...(password ? { password } : {}),
            LegalFullName: `${validValues?.firstName ? validValues?.firstName.trim() : ''} ${
              validValues?.lastName ? validValues?.lastName.trim() : ''
            }`.toLocaleLowerCase(),
            username: `${validValues?.firstName ? validValues?.firstName.trim() : ''}.${
              validValues?.lastName ? validValues?.lastName.trim() : ''
            }`.toLocaleLowerCase(),
            UserType_id: user?.UserType_id?.id,
            avatar: uploadedFile,
            role: user?.role?.id,
            confirmed: !validValues?.confirmed,
            LeadUser_id: user?.id,
            ...(!(editData?.id && isSave)
              ? {
                  reBrokerAgents: {
                    AgencyName: user?.broker?.BrokerAgency_id?.AgencyName,
                    BrokerAgency_id,
                    BrokerType_id: brokerType?.[0]?.id,
                    Email: validValues?.email,
                    FirstName: validValues?.firstName,
                    FullName: `${validValues?.firstName ? validValues?.firstName.trim() : ''} ${
                      validValues?.lastName ? validValues?.lastName.trim() : ''
                    }`.toLocaleLowerCase(),
                    LastName: validValues?.lastName,
                    Mobile: validValues?.mobile,
                  },
                }
              : {
                  id: editData?.team_id,
                }),
          },
          ActiveEffDate: ActiveEffDate ? moment(ActiveEffDate).format() : null,
          ...(!(editData?.id && isSave) ? { LeadUser_id: user?.id, BrokerAgency_id } : {}),
        };

        if (editData?.id && isSave) {
          updateTeamUserCall({ id: editData.id, values }, afterSave);
        } else {
          addTeamUserCall(values, afterSave);
        }
      })
      .catch(() => {});
  };

  const onEmailChange = debounce(e => {
    getUsersCountApi({
      email: e.target.value,
      id_ne: editData?.team_id,
    }).then(
      ({ data }) => {
        if (data) {
          form.setFields([
            {
              name: 'email',
              errors: ['User Account already exists!'],
            },
          ]);
        }
      },
      () => {}
    );
  }, 300);

  const footer = (
    <Row justify='space-between' align='middle'>
      <Col align='center'>
        <Spin spinning={addTeamUserLoading || updateTeamUserLoading}>
          <Button
            onClick={() => onFinish()}
            type='light_blue'
            icon={<PlusCircleOutlined />}
            disabled={!!editData?.id}
            color={!editData?.id && activeColor}
          >
            Team User
          </Button>
        </Spin>
      </Col>
      <Col align='center'>
        <Spin spinning={addTeamUserLoading || updateTeamUserLoading}>
          <Button
            icon={<SaveOutlined />}
            type='light_blue'
            onClick={() => onFinish(true)}
            color={activeColor}
          >
            SAVE
          </Button>
        </Spin>
        <Button
          type='light_blue'
          onClick={() =>
            confirm({
              title: 'Are you sure you want to leave this form?',
              icon: <ExclamationCircleOutlined />,
              content: 'All changes will be lost!',
              cancelText: 'Cancel',
              okText: 'OK',
              maskClosable: true,
              okButtonProps: {
                style: { backgroundColor: activeColor },
              },
              onOk: closeModal,
              onCancel() {},
            })
          }
          icon={<CloseCircleOutlined />}
          color={activeColor}
          ml='10px'
        >
          CANCEL
        </Button>
      </Col>
    </Row>
  );

  return (
    <ModalWrapper closeModal={closeModal} showConfirm={showConfirm} isEdit={!!editData?.id}>
      <ModalContent
        customWidth='550px'
        type='blue'
        title={title}
        subTitle={subTitle}
        closeModal={closeModal}
        footer={footer}
        showConfirm={showConfirm}
        isEdit={!!editData?.id}
        modalProps={{ title_back_color: activeColor }}
      >
        <Form
          type='blue_popup'
          form={form}
          layout='vertical'
          onFinish={onFinish}
          onValuesChange={() => setShowConfirm(true)}
          label_align_item='center'
          active_color={activeColor}
        >
          <Col span={24} padding='15px 30px'>
            <Row gutter={[20, 5]}>
              <Col sm={16} span={24}>
                <Row gutter={[20, 5]}>
                  <Col span={12}>
                    <FormItem
                      label='First Name'
                      name='firstName'
                      rules={[{ required: true, message: 'First Name is required!' }]}
                    >
                      <Input />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label='Last Name'
                      name='lastName'
                      rules={[{ required: true, message: 'Last Name is required!' }]}
                    >
                      <Input />
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem
                      label='Login | Email'
                      name='email'
                      rules={[
                        {
                          type: 'email',
                          message: 'Input valid E-mail!',
                        },
                        {
                          required: true,
                          message: 'Email is required!',
                        },
                      ]}
                    >
                      <InputEmail onChange={onEmailChange} />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label='Mobile'
                      name='mobile'
                      rules={[
                        {
                          pattern: phonePattern,
                          message: 'Input valid mobile!',
                        },
                        { required: true, message: 'Mobile is required!' },
                      ]}
                    >
                      <MaskedInput mask={phoneMask} addonBefore={<MenuOutlined />} />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label='Phone'
                      name='phone'
                      rules={[
                        {
                          pattern: phonePattern,
                          message: 'Input valid phone!',
                        },
                      ]}
                    >
                      <MaskedInput mask={phoneMask} addonBefore={<MenuOutlined />} />
                    </FormItem>
                  </Col>
                </Row>
              </Col>
              <ModalContentUpload
                uploadedFile={uploadedFile}
                setUploadedFile={setUploadedFile}
                icons={<></>}
                imageSize={60}
                mainSpan={8}
                title=''
              />
              <Col span={8}>
                <FormItem label='Broker Agency' name='BrokerAgency_id' disabled={true}>
                  <Select
                    showSearch={true}
                    filterOption={filterOption}
                    allowClear={true}
                    loading={brokersLoading}
                    disabled={true}
                    onClear={() => setAllOnClear(form, 'BrokerAgency_id', null)}
                  >
                    {brokers.map(el => (
                      <Select.Option value={el.id} key={el.id}>
                        {el?.AgencyName}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label='Job Title' name='JobTitle'>
                  <Input />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label='Password'
                  name='password'
                  rules={[
                    {
                      required: !editData?.id,
                      message: 'Password is required!',
                    },
                  ]}
                  mb={0}
                >
                  <InputPassword />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem mb='0' label='Access Start' name='ActiveEffDate'>
                  <DatePicker
                    format='MM/DD/YYYY HH:mm'
                    dropdownClassName='user_date'
                    icon_left={true}
                    showTime={{
                      format: 'HH:mm',
                    }}
                    suffixIcon={<MenuOutlined />}
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <Row gutter={[20, 5]} justify='end' height='100%'>
                  <Col align='end' mb={5} justify='end' direction='column'>
                    <FormItem name='confirmed' valuePropName='checked' display_item='none'>
                      <Checkbox disabled={!editData?.id} />
                    </FormItem>
                    <Title fz='12px' fw='500' lh='18px' color='#717579' mb='0'>
                      Active
                    </Title>
                    <FormItem shouldUpdate={(prev, next) => prev.confirmed !== next.confirmed}>
                      {() => (
                        <label htmlFor='confirmed'>
                          <Col
                            width='25px'
                            height='25px'
                            border='1px solid #eeeeee'
                            align='center'
                            justify='center'
                            mr='5px'
                            cursor={!editData?.id ? 'not-allowed' : 'pointer'}
                          >
                            {checkActiveIcon(form.getFieldValue('confirmed'))}
                          </Col>
                        </label>
                      )}
                    </FormItem>
                  </Col>
                  <Col align='end' mb={5} justify='end' direction='column'>
                    <FormItem name='blocked' valuePropName='checked' display_item='none'>
                      <Checkbox />
                    </FormItem>
                    <Title fz='12px' fw='500' lh='18px' color='#717579' mb='0'>
                      Locked
                    </Title>
                    <FormItem shouldUpdate={(prev, next) => prev.blocked !== next.blocked}>
                      {() => (
                        <label htmlFor='blocked'>
                          <Col
                            width='25px'
                            height='25px'
                            border='1px solid #eeeeee'
                            cursor='pointer'
                            align='center'
                            justify='center'
                            mr='5px'
                          >
                            {checkActiveIcon(form.getFieldValue('blocked'))}
                          </Col>
                        </label>
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <FormItem shouldUpdate={(prev, next) => prev.password !== next.password}>
                  {() => (
                    <FormItem
                      name='newPassword'
                      label='Confirm Password'
                      rules={[
                        {
                          required: !editData?.id || form.getFieldValue('password'),
                          message: 'Confirm Password is required!',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Password Mismatch!'));
                          },
                        }),
                      ]}
                      mb={0}
                    >
                      <InputPassword />
                    </FormItem>
                  )}
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Form>
      </ModalContent>
    </ModalWrapper>
  );
};

export default TeamsPopup;
