/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Carousel, Image, Upload, Spin } from 'antd';
import {
  LeftOutlined,
  RightOutlined,
  DeleteOutlined,
  PlusOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  FileWordOutlined,
  FileTextOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { Col, Button, Paragraph, Row } from 'components/atoms/index';
import { addUploadApi } from 'services/uploads';
import { convertFileUrl, checkValidUrl } from 'Utils/Helpers';
import { API_ROOT } from '../../../../configs/env-vars';

const ImageWrapper = styled(Col)`
  position: relative;
  width: fit-content;
  height: fit-content;
  display: inline-flex !important;
  width: 50%;
  .delete_wrapper {
    transition: all ease 0.3s;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 5px;
    right: 5px;
    bottom: 0;
    background-color: rgba(72, 77, 85, 0.85);
    border-radius: 8px;
    .delete_icon {
      margin-right: 5px;
      svg {
        color: #fff;
      }
    }
  }
  &:hover .delete_wrapper {
    opacity: 1;
  }
  img,
  video {
    border-radius: 10px;
    width: 100%;
    height: 400px;
    object-fit: contain;
  }
  /* & .ant-spin-nested-loading {
    height: 100%;
  } */

  .without_radius {
    border-radius: 0px !important;
  }

  ${props =>
    props.height &&
    css`
      img {
        height: ${props.height} !important;
      }
    `}
`;

const AddWrapper = styled.div`
  border: 1px solid #c0bfbf;
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 4px;
  background: #fafafa;
  padding: 2px 5px;
  z-index: 10;
  cursor: pointer;
`;

const FileIconsWrapper = styled.div`
  margin: 0 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: -webkit-fill-available;
  border: 1px solid #c0bfbf;
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.border &&
    css`
      border: ${props.border};
    `}
  ${props =>
    props.max_width &&
    css`
      & img,
      & .ant-image {
        max-width: ${props.max_width} !important;
      }
    `}
    ${props =>
    props.max_height &&
    css`
      & img,
      & .ant-image {
        max-height: ${props.max_height} !important;
      }
    `};
`;

export const fileIcons = (
  item,
  videoStyles,
  imgHeight,
  imgProps,
  customPdf,
  customDoc,
  containerStyles
) => {
  const style = { fontSize: '50px', color: '#5F6982' };
  const fileTypeContents = [
    [
      '.pdf',
      customPdf ? (
        <iframe
          title='floor_plan'
          src={convertFileUrl(`${API_ROOT}custom/${item?.name}?id=${item?.id}`)}
          frameBorder='0'
          scrolling='no'
          style={{ width: '100%', height: imgHeight || '100%' }}
        />
      ) : (
        <FileIconsWrapper height={imgHeight} {...containerStyles}>
          <FilePdfOutlined style={style} />
          <Paragraph
            color='#5F6982'
            fz='12px'
            fw='400'
            margin='10px 5px 0'
            width='inherit'
            align='center'
            ellipsis={{ tooltip: true }}
          >
            {item?.LineItemFile || item?.name}
          </Paragraph>
        </FileIconsWrapper>
      ),
    ],
    [
      '.xlsx',
      <FileIconsWrapper height={imgHeight} {...containerStyles}>
        <FileExcelOutlined style={style} />
        <Paragraph
          color='#5F6982'
          fz='12px'
          fw='400'
          margin='10px 5px 0'
          width='inherit'
          align='center'
          ellipsis={{ tooltip: true }}
        >
          {item?.LineItemFile || item?.name}
        </Paragraph>
      </FileIconsWrapper>,
    ],
    [
      ['.doc', '.docm', '.docx'],
      customDoc ? (
        <iframe
          title='floor_plan'
          src={convertFileUrl(
            `https://view.officeapps.live.com/op/embed.aspx?src=${API_ROOT}custom/${item?.name}?id=${item?.id}`.replace(
              '&',
              ' and '
            )
          )}
          // src={convertFileUrl(`https://docs.google.com/gview?url=${item?.url}&embedded=true`)}
          frameBorder='0'
          scrolling='no'
          style={{ width: '100%', height: imgHeight || '100%' }}
        />
      ) : (
        <FileIconsWrapper height={imgHeight} {...containerStyles}>
          <FileWordOutlined style={style} />
          <Paragraph
            color='#5F6982'
            fz='12px'
            fw='400'
            margin='10px 5px 0'
            width='inherit'
            align='center'
            ellipsis={{ tooltip: true }}
          >
            {item?.LineItemFile || item?.name}
          </Paragraph>
        </FileIconsWrapper>
      ),
    ],
    [
      ['.jpg', '.png', '.svg'],
      <FileIconsWrapper
        max_width={imgProps?.max_width}
        max_height={imgProps?.max_height}
        {...containerStyles}
      >
        <Image src={item?.url} alt={item?.name} height={imgHeight} width='100%' {...imgProps} />
      </FileIconsWrapper>,
    ],
    [
      '.mp4',
      <FileIconsWrapper {...containerStyles}>
        <video controls style={videoStyles || {}}>
          <source src={checkValidUrl(item?.url)} type='video/mp4' />
        </video>
      </FileIconsWrapper>,
    ],
  ];

  return (
    fileTypeContents.find(el => el[0].includes(item?.ext))?.[1] || (
      <FileIconsWrapper height={imgHeight} {...containerStyles}>
        <FileTextOutlined style={style} />
        <Paragraph
          color='#5F6982'
          fz='12px'
          fw='400'
          margin='10px 5px 0'
          width='inherit'
          ellipsis={{ tooltip: true }}
        >
          {item?.LineItemFile || item?.name}
        </Paragraph>
      </FileIconsWrapper>
    )
  );
};

const PrevArrow = props => (
  <Button
    radius={'50%'}
    type='slide'
    z_index={1}
    {...props}
    onClick={e => {
      props?.onClick?.();
      e.stopPropagation();
    }}
  >
    <LeftOutlined style={{ fontSize: '10px', color: '#fff' }} />
  </Button>
);

const NextArrow = props => (
  <Button
    radius={'50%'}
    type='slide'
    z_index={1}
    {...props}
    onClick={e => {
      props?.onClick?.();
      e.stopPropagation();
    }}
  >
    <RightOutlined style={{ fontSize: '10px', color: '#fff' }} />
  </Button>
);

const FeaturedImages = ({
  setAllImages,
  allImages = [],
  imgHeight,
  isEditable,
  imgProps = {},
  videoStyles = {},
  accept,
  maxCount,
  multiple,
  showSingle,
  showCount,
  customPdf,
  customDoc,
  actualImageSize = false,
  centerAlign = false,
  customPreviewFunc,
  containerStyles = {},
  checkAccept = false,
}) => {
  const [uploadLoading, setUploadLoading] = useState(false);
  const [currentFiles, setCurrentFiles] = useState([]);

  useEffect(() => {
    if (currentFiles?.length) {
      setUploadLoading(true);
      Promise.all(currentFiles?.map(el => addUploadApi(el)))
        .then(res => {
          const uploadedList = res?.map(file => file?.data?.[0]) || [];
          setAllImages(prev =>
            [
              ...(prev?.length ? prev : []),
              ...uploadedList?.map(imgData => ({
                ...imgData,
                url: convertFileUrl(imgData?.url),
              })),
            ]?.filter(el => el?.id)
          );
          setUploadLoading(false);
          setCurrentFiles([]);
        })
        .catch(() => {
          setUploadLoading(false);
          setCurrentFiles([]);
        });
    }
  }, [currentFiles]);

  const uploadFile = async (file, fileList) => {
    if (multiple) {
      setCurrentFiles(fileList);
    } else {
      setUploadLoading(true);
      const callback = await addUploadApi(file);
      setUploadLoading(false);
      if (callback) {
        const uploadedData = callback?.data ? callback?.data[0] : null;
        setAllImages(prev =>
          showSingle
            ? [{ ...uploadedData, url: convertFileUrl(uploadedData?.url) }]?.filter(el =>
                checkAccept ? el?.id && accept?.includes(el.ext) : el?.id
              )
            : [
                ...(prev || []),
                { ...uploadedData, url: convertFileUrl(uploadedData?.url) },
              ]?.filter(el => (checkAccept ? el?.id && accept?.includes(el.ext) : el?.id))
        );
      }
    }
    return false;
  };

  const images = value => (
    <ImageWrapper key={value?.id}>
      {fileIcons(
        value,
        videoStyles,
        imgHeight,
        { ...imgProps, ...(actualImageSize ? { width: value?.width } : {}) },
        customPdf,
        customDoc,
        containerStyles
      )}
      {isEditable && (
        <div className='delete_wrapper'>
          {customPreviewFunc ? (
            <Col
              align='baseline'
              cursor='pointer'
              mb={5}
              onClick={() => customPreviewFunc([value])}
            >
              <EyeOutlined className='delete_icon' />
              <Paragraph mb={0} color='#fff'>
                Preview
              </Paragraph>
            </Col>
          ) : (
            <a
              target='_blank'
              href={
                ['.jpeg', '.jpg', '.png'].includes(value?.ext)
                  ? value?.url
                  : convertFileUrl(`${API_ROOT}custom/${value?.name}?id=${value?.id}`)
              }
              rel='noreferrer'
            >
              <Col align='baseline' cursor='pointer' mb={5}>
                <EyeOutlined className='delete_icon' />
                <Paragraph mb={0} color='#fff'>
                  Preview
                </Paragraph>
              </Col>
            </a>
          )}
          <Col
            align='baseline'
            padding='0'
            margin='0 10px 0 0'
            cursor='pointer'
            onClick={() => setAllImages(prev => prev.filter(item => item?.url !== value?.url))}
          >
            <DeleteOutlined className='delete_icon' />
            <Paragraph mb={0} color='#fff'>
              Delete
            </Paragraph>
          </Col>
        </div>
      )}
    </ImageWrapper>
  );

  return (
    <Spin spinning={uploadLoading} wrapperClassName='full_height_spin'>
      <Row width='100%' gutter={0} position='relative' overflow='hidden' height='100%'>
        {!!allImages?.length && (
          <Carousel
            slidesToShow={
              showCount || (allImages?.length >= maxCount ? maxCount : allImages?.length)
            }
            dots={false}
            arrows={true}
            prevArrow={<PrevArrow />}
            nextArrow={<NextArrow />}
            className={centerAlign ? 'center-slider' : ''}
            infinite={allImages.length >= (showCount || 3)}
          >
            {allImages.map(el => images(el))}
          </Carousel>
        )}
        {isEditable && (
          <AddWrapper>
            <Upload
              beforeUpload={(file, fileList) => uploadFile(file, fileList)}
              maxCount={1}
              onRemove={() => setAllImages(null)}
              showUploadList={false}
              fileList={allImages ? [allImages] : []}
              customRequest={() => {}}
              accept={accept || ''}
              listType='text'
              display='flex'
              justify='space-between'
              width='100%'
              multiple={multiple}
            >
              <PlusOutlined style={{ color: '#C0BFBF' }} />
            </Upload>
          </AddWrapper>
        )}
      </Row>
    </Spin>
  );
};

export default FeaturedImages;
