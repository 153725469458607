import { createCustomApi } from '../../configs/rtk-query';

export const importFormsApi = createCustomApi({
  reducerPath: 'importForms',
  endpoints: builder => ({
    getImportForms: builder.query({
      query: params => ({
        url: '/sm-import-forms',
        params,
      }),
    }),
  }),
});

export const { useGetImportFormsQuery } = importFormsApi;
