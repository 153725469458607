import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import user from './slices/user';
import salesData from './slices/sales-data';
import settings from './slices/settings';
import decor from './slices/decor';
import projectFilterValue from './slices/project-filter-value';
import { basicInfoApi } from './services/basic-information';
import { importFormsApi } from './services/import-forms';
import { appSettingsApi } from './services/app-settings';

export const store = configureStore({
  reducer: {
    [basicInfoApi.reducerPath]: basicInfoApi.reducer,
    [importFormsApi.reducerPath]: importFormsApi.reducer,
    [appSettingsApi.reducerPath]: appSettingsApi.reducer,
    projectFilterValue,
    user,
    salesData,
    settings,
    decor,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      basicInfoApi.middleware,
      importFormsApi.middleware,
      appSettingsApi.middleware,
    ]),
});

setupListeners(store.dispatch);
