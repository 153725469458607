/* eslint-disable no-nested-ternary */
import { useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { Spin } from 'antd';
import { Col, Select } from '../atoms/index';

const getValue = (data, text) => text.split('.').reduce((p, c) => p?.[c], data);

const defaultLimit = 25;

const LazySelect = ({
  value,
  call,
  callOne,
  loading,
  total,
  labelKey,
  filteredOptions = el => el,
  selectOptions = () => ({}),
  ...props
}) => {
  const result = useRef([]);
  const filters = useRef({ _start: 0, _limit: defaultLimit });

  useEffect(() => {
    if ((value || value?.length) && !result?.length) {
      if (callOne) {
        callOne({ id: value }, res => {
          result.current = [res];
        });
      } else {
        call({ id_in: Array.isArray(value) ? value : [value] }, res => {
          result.current = res;
        });
      }
    }
  }, [value]);

  const onScroll = debounce(async e => {
    const { target } = e;
    if (
      !loading &&
      Math.floor(target.scrollTop + target.offsetHeight) >= target.scrollHeight - 1 &&
      total > result.current?.length
    ) {
      call(
        {
          ...filters?.current,
          id_nin: !value || !value?.length ? undefined : Array.isArray(value) ? value : [value],
        },
        data => {
          filters.current = {
            ...filters?.current,
            _start: filters?.current?._start + defaultLimit,
          };
          result.current = [...result.current, ...data];
        }
      );
    }
  }, 300);

  const onSearch = debounce(async searchValue => {
    filters.current = { _start: 0, _limit: defaultLimit };
    call(
      {
        _start: 0,
        _limit: defaultLimit,
        [`${labelKey}_contains`]: searchValue,
      },
      data => {
        result.current = data;
      }
    );
  }, 500);

  const request = (open, withClear, isClear) => {
    if (open && filters?.current?._start === 0) {
      call(
        {
          ...filters?.current,
          ...(isClear || !value || !value?.length
            ? {}
            : { id_nin: Array.isArray(value) ? value : [value] }),
        },
        data => {
          filters.current = { ...filters?.current, _start: defaultLimit };
          result.current = withClear ? data : [...result.current, ...data];
        }
      );
    }
  };

  return (
    <Select
      value={value}
      {...props}
      onDropdownVisibleChange={request}
      onPopupScroll={onScroll}
      onSearch={searchValue => {
        if (searchValue) {
          onSearch(searchValue);
        } else {
          filters.current = { _start: 0, _limit: defaultLimit };
          request(true, true);
        }
      }}
      onClear={() => {
        filters.current = { _start: 0, _limit: defaultLimit };
        request(true, true, true);
      }}
      dropdownRender={menu => (
        <>
          {menu}
          {loading ? (
            <Col justify='center' width='100%'>
              <Spin size='small' />
            </Col>
          ) : null}
        </>
      )}
      virtual={false}
    >
      {filteredOptions(result.current).map(el => (
        <Select.Option value={el?.id} key={el?.id} {...selectOptions(el)}>
          {getValue(el, labelKey)}
        </Select.Option>
      ))}
    </Select>
  );
};

export default LazySelect;
