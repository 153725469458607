import { groupBy } from 'lodash';
import SignedDocIcon from '../assets/images/custom-icons/SignedDocIcon';
import UnsignedDocIcon from '../assets/images/custom-icons/UnsignedDocIcon';
import InReviewDocIcon from '../assets/images/custom-icons/InReviewDocIcon';
import allCities from './city-province.json';

export const ssnPattern = /^\d{3}(?:\d{6}|([ ./-])\d{3}\1\d{3})$/;
export const phonePattern = /\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})/;
export const phoneMask = '(999) 999-9999';

export const postalPattern = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
export const postalMask = '*** ***';

export const docsContent = {
  signed: { icon: <SignedDocIcon />, text: 'Signed', color: '#81E18A' },
  unsigned: { icon: <UnsignedDocIcon />, text: 'Missing signature', color: '#EF3E36' },
  review: { icon: <InReviewDocIcon />, text: 'On review', color: '#B3C5B4' },
};

export const additionalQuestionsKeys = [
  'whatBrokerage',
  'contactRanking',
  'areYouRealtor',
  'agentName',
  'currentHomeStatus',
  'purchaseType',
  'lookingTo',
  'exposure',
  'ageRange',
  // 'hearAboutUs',
];

export const defFormLayout = {
  layout: 'horizontal',
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export const sortDirectionKeys = {
  ascend: 'ASC',
  descend: 'DESC',
};

export const projectStatusColors = {
  ACTIVE: '#61DC00',
  LASTCHANCE: '#E79F33',
  HOTLIST: '#E79F33',
  COMINGSOON: '#EBC231',
};

export const provinceCityList = groupBy(allCities, 'province_id');

export const honorifics = [
  'Mr',
  'Ms',
  'Miss',
  'Mrs',
  'Mx',
  'Master',
  'Sir',
  'Madam',
  'Dame',
  'Lord',
  'Lady',
  'Dr',
  'Prof',
  'Br',
  'Sr',
  'Fr',
  'Rev',
  'Pr',
  'Elder',
];
