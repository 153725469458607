import styled, { css } from 'styled-components';
import { Select as select } from 'antd';
import { LoadingOutlined, MenuOutlined } from '@ant-design/icons';
import { mainColor } from '../../../constants/colors';

const Select = styled(select)`
  width: 100%;
  .ant-select-selector {
    background: #fff !important;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    //border-radius: 50px !important;
    //height: 44px !important;
    position: relative;
    height: 35px;
    font-weight: 300;
    padding: 12px 15px !important;
    display: flex;
    align-items: center;
  }

  .ant-select-selection-placeholder {
    font-size: 12px;
  }

  & .ant-select-selection-search-input {
    height: 100% !important;
  }
  .ant-select-arrow .anticon:not(.ant-select-suffix) {
    pointer-events: none;
  }

  .ant-select-item-option-content {
    text-align: left;
  }

  ${props =>
    props.drop_min_width &&
    css`
      .ant-select-dropdown {
        min-width: ${props.drop_min_width}!important;
      }
    `}

  &:hover {
    border-color: ${mainColor};
  }
  &:focus,
  &.ant-input-focused {
    border-color: ${mainColor};
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px ${mainColor}2e;
  }
  & .ant-select-suffix svg {
    display: none;
  }
  & .ant-select-suffix {
    position: unset;
  }
  & .ant-select-arrow > span:after {
    content: '';
    position: absolute;
    top: 4px;
    right: 2px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #c0bfbf;
  }

  &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #00000096;
  }

  ${props =>
    props.hide === 'custom-arrow' &&
    css`
      & .ant-select-arrow > span:after {
        display: none;
      }
    `}
  ${props =>
    props.hide === 'burger' &&
    css`
      & .ant-select-arrow {
        display: none !important;
      }
      & .ant-select-selector {
        padding-right: 0 !important;
      }
      & .ant-select-selection-item {
        padding-right: 5px !important;
      }
    `}
  ${props =>
    props.loading &&
    css`
      & .ant-select-arrow > span:after {
        display: none;
      }
    `}
    ${props =>
    props.icon_right &&
    css`
      & .ant-select-arrow {
        right: ${props.icon_right} !important;
      }
    `}
  ${props =>
    props.type === 'blue' &&
    css`
      .ant-select-selector {
        border: 1px solid #c9c9c9 !important;
      }
      .ant-select-arrow {
        color: #c9c9c9 !important;
      }

      &:hover {
        .ant-select-selector {
          border-color: ${mainColor} !important;
        }
      }
      &:focus,
      &.ant-input-focused {
        .ant-select-selector {
          border-color: ${mainColor} !important;
          box-shadow: none;

          .ant-select-arrow {
            color: ${mainColor} !important;
          }
        }
      }
    `}
  ${props =>
    props.mode === 'multiple' &&
    css`
      .ant-select-selector {
        padding: 0 5px !important;
      }
    `}
      ${props =>
    props.type === 'sale' &&
    css`
      .anticon::after {
        display: none;
      }
      .ant-select-clear {
        right: 8px;
      }
    `}
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px!important;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.padding &&
    css`
      & .ant-select-selector {
        padding: ${props.padding} !important;
      }
    `}
  ${props =>
    props.max_width &&
    css`
      max-width: ${props.max_width}!important;
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width}!important;
    `}
  ${props =>
    props.min_width &&
    css`
      min-width: ${props.min_width}!important;
    `}
  ${props =>
    props.height &&
    css`
      .ant-select-selector {
        height: ${props.height} !important;
      }
    `}
  ${props =>
    props.input_height &&
    css`
      .ant-select-selector .ant-select-selection-search-input {
        height: ${props.input_height}!important;
      }
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.fw &&
    css`
      .ant-select-selection-item {
        font-weight: ${props.fw};
      }
    `}
  ${props =>
    props.text_align &&
    css`
      .ant-select-selection-item {
        text-align: ${props.text_align};
      }
    `}
  ${props =>
    props.fz &&
    css`
      .ant-select-selection-item,
      .ant-select-selection-search-input {
        font-size: ${props.fz};
      }
    `}
    ${props =>
    props.clear_opacity &&
    css`
      .ant-select-clear {
        opacity: ${props.clear_opacity} !important;
      }
    `}
   ${props =>
    props.clear_right &&
    css`
      .ant-select-clear {
        right: ${props.clear_right} !important;
      }
    `}
  ${props =>
    props.color &&
    css`
      .ant-select-selection-item {
        color: ${props.color};
      }
    `}
  ${props =>
    props.border &&
    css`
      .ant-select-selector {
        border: ${props.border} !important;
      }
    `}

  ${props =>
    props.custom_arrow_display &&
    css`
      & .ant-select-arrow > span:after {
        display: ${props.custom_arrow_display} !important;
      }
    `}
  ${props =>
    props.disabled_color &&
    css`
      &.ant-select-disabled {
        .ant-select-selection-item {
          color: ${props.disabled_color};
        }
      }
    `}
  ${props =>
    props.placeholder_color &&
    css`
      .ant-select-selection-placeholder {
        color: ${props.placeholder_color};
      }
    `}
  ${props =>
    props.back_color &&
    css`
      .ant-select-selector {
        background-color: ${props.back_color}!important;
      }
    `}
`;

const SelectWithBurger = ({ children, suffixIconProps = {}, ...props }) => (
  <Select
    getPopupContainer={trigger => trigger.parentElement}
    {...(props?.type === 'sale'
      ? {
          suffixIcon: props?.loading ? (
            <LoadingOutlined spin={true} {...suffixIconProps} />
          ) : (
            <MenuOutlined {...suffixIconProps} />
          ),
        }
      : {})}
    {...props}
  >
    {children}
  </Select>
);

SelectWithBurger.Option = Select.Option;

export default SelectWithBurger;
