import React, { Suspense } from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import styled from 'styled-components';
import routes, { protectedRouts } from 'constants/routes';
import { getPageComponents } from './getPageContainer';
import { getUser, getUserRole } from './app/services/selector-helpers';
import NotFoundPage from './pages/not-found.page';
import DashboardWrapper from './DashboardWrapper';
import GuestWrapper from './GuestWrapper';

export const PageSpinner = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
`;

const fallback = (
  <PageSpinner>
    <Spin spinning={true} size='large' />
  </PageSpinner>
);

const lazyLoadPage = path => {
  const Comp = React.lazy(() => import(`./${path}`));
  return (
    <Suspense fallback={fallback}>
      <Comp />
    </Suspense>
  );
};

const RouteList = () => {
  const role = useSelector(getUserRole);
  const user = useSelector(getUser);
  const routeList = getPageComponents(user ? protectedRouts[role] : routes);

  const router = createBrowserRouter([
    {
      path: '/',
      element: user?.id ? (
        <DashboardWrapper>
          <Suspense fallback={fallback}>
            <Outlet />
          </Suspense>
        </DashboardWrapper>
      ) : (
        <GuestWrapper>
          <Suspense fallback={fallback}>
            <Outlet />
          </Suspense>
        </GuestWrapper>
      ),
      children: Object.values(routeList)
        .filter(el => el.pathname)
        .map(el => ({
          path: el.pathname,
          element: lazyLoadPage(el.component),
        })),
    },
    {
      path: '*',
      element: <NotFoundPage fallback={fallback} />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default RouteList;
