import { useState } from 'react';

const useApiCall = (callFunc, countFunc, withoutLoading, cleanOnFailure) => {
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);

  const handleApiCall = (params, callback = () => {}) => {
    handleLoadingChange(true);
    setFinished(false);
    if (callFunc) {
      callFunc(params)
        .then(res => {
          handleLoadingChange();
          setData(res?.data || []);
          setFinished(true);
          callback(res?.data || []);
        })
        .catch(() => {
          handleLoadingChange();
          setFinished(true);
          if (cleanOnFailure) {
            setDefaultValues();
          }
        });
    }
    if (countFunc) {
      countFunc(params)
        .then(res => {
          setCount(res?.data || 0);
        })
        .catch(() => {});
    }
  };

  const handleLoadingChange = (value = false) => {
    if (!withoutLoading) {
      setLoading(value);
    }
  };

  const setDefaultValues = () => {
    setData([]);
    setCount(0);
  };

  return [data, loading, handleApiCall, count, setDefaultValues, finished];
};

export default useApiCall;
