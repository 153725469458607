import React, { useState } from 'react';
import { CloseCircleOutlined, MenuOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  Button,
  Col,
  DatePicker,
  FormItem,
  Input,
  ModalWrapper,
  Row,
  TextArea,
  Form,
  Paragraph,
} from '../../atoms';
import ModalContent from '../../molecules/ModalContent';
import { darkBlue, salesMainColor } from '../../../constants/colors';
import useGetMainColor from '../../../Utils/hooks/useGetMainColor';
import { findBasicByCode, toStartOfDay } from '../../../Utils/Helpers';
import { defFormLayout } from '../../../constants/etc';
import { getUser } from '../../../app/services/selector-helpers';
import useUpdateSalesData from '../../../Utils/hooks/useUpdateSalesData';
import useGetBasicInfosByType from '../../../Utils/hooks/useGetBasicInfosByType';

const getModalTitle = type =>
  ({
    rescindDeal: 'RESCIND OFFER',
    releaseDeal: 'RELEASE DEAL',
    assignDeal: 'ASSIGN DEAL',
    cancelSale: 'CANCEL DEAL',
  }?.[type]);

const SalesStatusPopup = ({ closeModal, type, firmDate }) => {
  const user = useSelector(getUser);
  const [form] = Form.useForm();
  const [salesStatuses] = useGetBasicInfosByType('SaleStatus');
  const [activeColor] = useGetMainColor();
  const [handleSave] = useUpdateSalesData();
  const [showConfirm, setShowConfirm] = useState(false);

  const typeData =
    {
      rescindDeal: {
        date: {
          label: 'RESCIND DATE',
          name: 'RescindEffDate',
        },
        reason: {
          label: 'RESCIND REASON',
          name: 'RescindReason',
          max: 25,
        },
        notes: {
          name: 'RescindNotes',
        },
        updateDateKey: 'RescindRecDate',
        userKey: 'RescindUser_id',
        statusKey: 'rescinded',
      },
      releaseDeal: {
        date: {
          label: 'RELEASE DATE',
          name: 'ReleaseEffDate',
        },
        reason: {
          label: 'RELEASE REASON',
          name: 'ReleaseReason',
          max: 25,
        },
        notes: {
          name: 'ReleaseNotes',
        },
        updateDateKey: 'ReleaseRecDate',
        userKey: 'ReleaseUser_id',
        statusKey: 'released',
      },
      assignDeal: {
        date: {
          label: 'ASSIGN DATE',
          name: 'AssignEffDate',
        },
        reason: {
          label: 'ASSIGN REASON',
          name: 'AssignReason',
          max: 25,
        },
        notes: {
          name: 'AssignNotes',
        },
        updateDateKey: 'AssignRecDate',
        userKey: 'AssignUser_id',
        statusKey: 'assigned',
      },
      cancelSale: {
        date: {
          label: 'CANCEL DATE',
          name: 'CancelEffDate',
        },
        reason: {
          label: 'CANCEL REASON',
          name: 'CancelReason',
          max: 50,
        },
        notes: {
          name: 'CancelNotes',
        },
        updateDateKey: 'CancelRecDate',
        userKey: 'CancelUser_id',
        statusKey: 'canceled',
      },
    }?.[type] || {};

  const handleSubmit = values => {
    const extras =
      type !== 'assignDeal'
        ? {
            AssignEffDate: null,
            AssignReason: null,
            AssignNotes: null,
            AssignRecDate: null,
            AssignUser_id: null,
          }
        : {};
    handleSave({
      action: 'changeStatus',
      condition: type,
      changeData: {
        ...values,
        [typeData?.date?.name]: values?.[typeData?.date?.name]
          ? moment(values?.[typeData?.date?.name]).format('YYYY-MM-DD')
          : null,
        [typeData?.updateDateKey]: moment().format(),
        [typeData?.userKey]: user?.id,
        SaleStatus_id: findBasicByCode(salesStatuses, typeData?.statusKey)?.id,
        ...extras,
      },
    });
    closeModal();
  };

  const footer = (
    <Row justify='space-between' align='middle'>
      <Button
        type='sales_blue'
        back_color={darkBlue}
        onClick={() => closeModal()}
        icon={<CloseCircleOutlined />}
        width='150px'
        height='25px'
      >
        CANCEL
      </Button>
      <Button
        type='sales_blue'
        width='150px'
        height='25px'
        back_color={darkBlue}
        onClick={form.submit}
      >
        OK
      </Button>
    </Row>
  );

  return (
    <ModalWrapper closeModal={closeModal} showConfirm={showConfirm}>
      <ModalContent
        type='blue'
        title={getModalTitle(type)}
        closeModal={closeModal}
        footer={footer}
        footerPadding='7.5px 15px'
        modalProps={{ title_back_color: '#03215F', width: '450px' }}
        showConfirm={showConfirm}
        titlePadding='5px 30px'
        // titleColor={salesMainColor}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          {...defFormLayout}
          type='orange'
          input_border_color={salesMainColor}
          onValuesChange={() => setShowConfirm(true)}
          active_color={activeColor}
        >
          <Row padding='10px 15px' gutter={[20, 15]} mb={15}>
            <Col span={10}>
              <FormItem
                label={typeData?.date?.label}
                name={typeData?.date?.name}
                rules={[
                  {
                    required: true,
                    message: `Select ${typeData?.date?.label}!`,
                  },
                ]}
              >
                <DatePicker
                  format='ll'
                  dropdownClassName='user_date'
                  className='orange_outlined'
                  disabledDate={current => {
                    return (
                      (current && toStartOfDay(current) < toStartOfDay(firmDate)) ||
                      toStartOfDay(current) > toStartOfDay()
                    );
                  }}
                />
              </FormItem>
            </Col>
            <Col span={14}>
              <FormItem
                mb='0'
                label={typeData?.reason?.label}
                name={typeData?.reason?.name}
                label_align='right'
                rules={[
                  {
                    required: true,
                    message: `Input ${typeData?.reason?.label}!`,
                  },
                ]}
              >
                <Input
                  className='orange_outlined'
                  maxLength={typeData?.reason?.max}
                  addonAfter={<MenuOutlined style={{ fontSize: 12, color: '#bfbfbf' }} />}
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem name={typeData?.notes?.name}>
                <TextArea
                  autoSize={{ minRows: 2, maxRows: 3 }}
                  className='orange_outlined'
                  placeholder='Enter notes'
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <Col
                span={24}
                align='center'
                justify='space-between'
                border={`1px solid ${salesMainColor}`}
                padding='3px 10px'
              >
                <Paragraph mb={0} fz={12} type='secondary'>
                  {moment().format('MM-DD-YYYY HH:mm')}
                </Paragraph>
                <Paragraph mb={0} fz={12} type='secondary'>
                  {user?.LegalFullName || `${user?.firstName || ''} ${user?.lastName || ''}`}
                </Paragraph>
              </Col>
            </Col>
          </Row>
        </Form>
      </ModalContent>
    </ModalWrapper>
  );
};

export default SalesStatusPopup;
