export const getUserRole = state => state?.user?.user?.role?.type;
export const getUser = state => state?.user?.user;

export const getSalesInfo = state => state.salesData?.data;

export const getSalesProject = state => state.salesData?.Project_id;

export const getProjectFilterValue = state => state.projectFilterValue?.data;

export const getSalesLoading = state => state.salesData?.loading;

export const getDecorFilters = state => state.decor?.filters;
