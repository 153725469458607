import Icon from '@ant-design/icons';

const InReviewDocSVG = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M23.8324 13.9145C23.7822 13.7033 23.5935 13.5543 23.3763 13.5543C22.7381 13.5543 21.5929 13.2364 20.6245 12.8155V10.7815C20.6245 10.0061 19.9936 9.37524 19.2183 9.37524H18.2808V0.93772C18.2808 0.420796 17.8602 0.000244141 17.3433 0.000244141H3.28117C2.76424 0.000244141 2.34369 0.420796 2.34369 0.93772V6.56281H0.937476C0.420552 6.56281 0 6.98336 0 7.50029V21.0937C0 21.8691 0.630828 22.5 1.40621 22.5H16.6315C17.1829 23.0095 17.8363 23.4958 18.611 23.9381C18.683 23.9792 18.7632 23.9997 18.8434 23.9997C18.9236 23.9997 19.0038 23.9792 19.0758 23.9381C22.3197 22.0863 23.4424 19.4624 23.8131 17.5873C24.2137 15.5604 23.8481 13.9808 23.8324 13.9145ZM3.28117 0.93772H17.3433V9.37524H9.22341L8.43771 7.41104C8.2316 6.89576 7.75537 6.56277 7.22447 6.56277H3.28117V0.93772ZM0.937476 21.0937V20.625H3.37473C3.63361 20.625 3.84347 20.4151 3.84347 20.1562C3.84347 19.8973 3.63361 19.6875 3.37473 19.6875H0.937476V7.50029H7.22447C7.38975 7.50029 7.51575 7.63032 7.56731 7.75922L8.47089 10.0181C8.5421 10.196 8.71445 10.3127 8.90612 10.3127H19.2184C19.4768 10.3127 19.6871 10.523 19.6871 10.7815V12.3354C19.4793 12.2067 19.3027 12.0762 19.173 11.9479C18.9904 11.7673 18.6965 11.7673 18.5138 11.9479C17.7451 12.7081 15.3521 13.5542 14.3104 13.5542C14.0934 13.5542 13.9046 13.7033 13.8544 13.9145C13.8386 13.9808 13.4731 15.5603 13.8738 17.5873C14.1028 18.746 14.6194 20.1907 15.7459 21.5625H1.40621C1.14775 21.5625 0.937476 21.3522 0.937476 21.0937ZM22.8934 17.4055C22.4254 19.7726 21.0635 21.6494 18.8434 22.9876C16.6355 21.6567 15.2761 19.7925 14.8008 17.4427C14.5349 16.1277 14.6309 15.0034 14.7078 14.4672C15.9172 14.3311 17.8273 13.6482 18.8434 12.8936C19.8597 13.6484 21.7704 14.3314 22.9798 14.4673C23.0564 14.9964 23.1515 16.0995 22.8934 17.4055Z'
      fill='#717579'
    />
    <path
      d='M4.99218 2.81272H15.6325C15.8914 2.81272 16.1012 2.60287 16.1012 2.34398C16.1012 2.0851 15.8914 1.87524 15.6325 1.87524H4.99218C4.73329 1.87524 4.52344 2.0851 4.52344 2.34398C4.52344 2.60287 4.73329 2.81272 4.99218 2.81272Z'
      fill='#717579'
    />
    <path
      d='M16.1012 4.21874C16.1012 3.95985 15.8914 3.75 15.6325 3.75H4.99218C4.73329 3.75 4.52344 3.95985 4.52344 4.21874C4.52344 4.47762 4.73329 4.68747 4.99218 4.68747H15.6325C15.8913 4.68747 16.1012 4.47762 16.1012 4.21874Z'
      fill='#717579'
    />
    <path
      d='M15.6327 5.625H10.3125C10.0536 5.625 9.84375 5.83485 9.84375 6.09374C9.84375 6.35262 10.0536 6.56248 10.3125 6.56248H15.6327C15.8915 6.56248 16.1014 6.35262 16.1014 6.09374C16.1014 5.83485 15.8915 5.625 15.6327 5.625Z'
      fill='#717579'
    />
    <path
      d='M15.6327 7.5H12.8672C12.6083 7.5 12.3984 7.70985 12.3984 7.96874C12.3984 8.22762 12.6083 8.43748 12.8672 8.43748H15.6327C15.8916 8.43748 16.1015 8.22762 16.1015 7.96874C16.1015 7.70985 15.8916 7.5 15.6327 7.5Z'
      fill='#717579'
    />
    <path
      d='M5.48438 19.6873C5.22548 19.6873 5.01562 19.8971 5.01562 20.156C5.01562 20.4149 5.22548 20.6248 5.48438 20.6248H5.4847C5.74359 20.6248 5.95331 20.4149 5.95331 20.156C5.95331 19.8971 5.74327 19.6873 5.48438 19.6873Z'
      fill='#717579'
    />
  </svg>
);

const InReviewDocIcon = props => <Icon component={InReviewDocSVG} {...props} />;

export default InReviewDocIcon;
