import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { getSalesInfo } from 'app/services/selector-helpers';
import { updateSaleApi } from 'services/project-settings';
import { changeSalesLoading, saveSalesData } from '../../app/slices/sales-data';

const useUpdateSalesData = () => {
  const dispatch = useDispatch();
  const sale = useSelector(getSalesInfo);

  const handleLoadingChange = (value, loadingData) => {
    if (loadingData?.isLocal) {
      loadingData?.setLoading(value);
    } else {
      dispatch(changeSalesLoading(value));
    }
  };

  const handleUpdate = (
    values,
    callBack = () => {},
    onError = () => {},
    hideNotification = false,
    successMassage = '',
    loadingData = {}
  ) => {
    handleLoadingChange(true, loadingData);
    updateSaleApi({ id: sale?.id, values })
      .then(({ data }) => {
        handleLoadingChange(false, loadingData);
        if (values?.condition !== 'deleteSale') {
          dispatch(saveSalesData(data));
        }
        if (!hideNotification) {
          notification.success({
            message: successMassage || 'The sale is successfully updated!',
            duration: 3,
          });
        }
        callBack?.(data);
      })
      .catch(() => {
        onError();
        handleLoadingChange(false, loadingData);
      });
  };
  return [handleUpdate];
};

export default useUpdateSalesData;
