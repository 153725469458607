import styled, { css } from 'styled-components';
import Icon from '@ant-design/icons';

const IconWrapper = styled(Icon)`
  ${props =>
    props.color &&
    css`
      svg path {
        fill: ${props.color}!important;
      }
    `}
  ${props =>
    props.stroke_color &&
    css`
      svg path {
        stroke: ${props.stroke_color}!important;
      }
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor}!important;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin}!important;
    `}
  ${props =>
    props.width &&
    css`
      svg {
        width: ${props.width}!important;
      }
    `}
  ${props =>
    props.height &&
    css`
      svg {
        height: ${props.height}!important;
      }
    `}
`;

export default IconWrapper;
