/* eslint-disable import/no-cycle */
import { InputNumber } from '../index';
import { convertPriceFormat } from '../../../Utils/Helpers';

const fixDecimals = e => {
  window.requestAnimationFrame(() => {
    e.target.value = convertPriceFormat(String(e.target.value));
    // eslint-disable-next-line no-param-reassign
    e.target.selectionStart = 0;
    // eslint-disable-next-line no-param-reassign
    e.target.selectionEnd = e.target.value?.length;
  });
};

const InputPrice = ({ isPositive = true, price, ...props }) => (
  <InputNumber
    prefix={price && '$'}
    text_align={price && 'end'}
    decimalSeparator={price && '.'}
    hide={price && 'controls'}
    step='0.01'
    formatter={value => (Number(value) ? value.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0.00')}
    parser={value => value.replace(/\$\s?|(,*)/g, '')}
    onBlur={fixDecimals}
    onFocus={fixDecimals}
    {...props}
  />
);

export default InputPrice;
