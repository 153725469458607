import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import { Spin, notification } from 'antd';
import {
  CloseCircleFilled,
  ApartmentOutlined,
  LockOutlined,
  EditOutlined,
  CheckCircleOutlined,
  UpCircleOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  UnlockOutlined,
  MenuOutlined,
  StopOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';
import useGetBasicInfosByType from 'Utils/hooks/useGetBasicInfosByType';
import {
  Row,
  Form,
  Col,
  FormItem,
  Select,
  Paragraph,
  DatePicker,
  Tabs,
  Input,
  Divider,
  Collapse,
  Title,
  Button,
  Modal,
} from 'components/atoms/index';
import { defFormLayout } from 'constants/etc';
import useApiCall from 'Utils/hooks/useApiCall';
import { darkBlue, mainColor, salesMainColor } from 'constants/colors';
import {
  fetchOneReBrokerAgentApi,
  fetchOneSaleApi,
  fetchReBrokerAgentsApi,
  fetchReBrokerAgentsCountApi,
  updateSaleApi,
} from 'services/project-settings';
import { getSalesInfo, getUser } from 'app/services/selector-helpers';
import useUpdateSalesData from 'Utils/hooks/useUpdateSalesData';
import {
  backToAdminFromSale,
  filterOption,
  findBasicByCode,
  isSaleEditable,
  toStartOfDay,
} from 'Utils/Helpers';
import { fetchBrokerApi } from 'services/re-broker-agents';
import LazySelect from 'components/molecules/lazy-select';
import ImageListModal from 'components/molecules/image-list-modal';
import { changeSalesLoading, saveSalesData } from '../../../app/slices/sales-data';
import CoBrokeAgentsClientsModal from '../broker-agents-modal';
import { updateUnitApi } from '../../../services/units';
import SalesStatusPopup from './sales-status-popup';

const { Panel } = Collapse;
const { confirm } = Modal;

const ProjectFormWrapper = styled(Col)`
  & .ant-col-10 {
    border: 1px solid #a6a6a6;
    border-right: none;
    border-bottom: none;
    background: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0 5px 0 0;
    height: 33px;
  }

  & .ant-col-14 {
    border: 1px solid #a6a6a6;
    border-bottom: none;
  }
  & .ant-picker,
  & .ant-select-selector {
    border: none !important;
    height: 30px !important;
  }

  & .border_bottom {
    border-bottom: 1px solid #a6a6a6 !important;
    margin-bottom: 10px;
  }
`;

const { TabPane } = Tabs;

export const tabOptions = {
  Purchasers: '/purchasers',
  'Add-Ons': '/add-ons',
  Adjustments: '/adjustments',
  Deposits: '/deposits',
  'Critical Dates': '/critical-dates',
  'Co-Broker(s)': '/co-broker',
  'Finance & Legal': '/financing',
  Documents: '/documents',
  Amendments: '/amendments',
};

const SalesInfoWrapper = ({ children }) => {
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const salesInfo = useSelector(getSalesInfo);
  const [handleSave] = useUpdateSalesData();
  const [form] = Form.useForm();
  const salesAlloc = useGetBasicInfosByType('SalesAlloc')?.[0].filter(el => el?.Inactive === false);
  const [salesStatuses, refetch, isLoading] = useGetBasicInfosByType('SaleStatus');
  const [unitStatuses] = useGetBasicInfosByType('UnitStatus');
  const salesSources = useGetBasicInfosByType('SaleSource')?.[0].filter(
    el => el?.Inactive === false
  );
  const [brokers, , brokersCall] = useApiCall(fetchBrokerApi);
  const [brokerAgents, brokerAgentsLoading, brokerAgentsCall, brokerAgentsCount] = useApiCall(
    fetchReBrokerAgentsApi,
    fetchReBrokerAgentsCountApi
  );
  const [, reBrokerAgentLoading, reBrokerAgentCall] = useApiCall(fetchOneReBrokerAgentApi);
  const [editData, setEditData] = useState(null);
  const [statusPopupType, setStatusPopupType] = useState(null);
  const [imageList, setImageList] = useState(null);

  useEffect(() => {
    salesInfo?.id &&
      form.setFieldsValue({
        ...salesInfo,
        SalesAlloc_id: salesInfo?.SalesAlloc_id?.id,
        SaleStatus_id: salesInfo?.SaleStatus_id?.id,
        coBrokers: salesInfo?.coBrokers?.[0]?.BrokerAgent_id?.id,
        DepositRef_id: salesInfo?.DepositRef_id?.id,
        SaleOfferDate: salesInfo?.SaleOfferDate && moment(salesInfo?.SaleOfferDate),
        OfferAckDate: salesInfo?.OfferAckDate && moment(salesInfo?.OfferAckDate),
        DealFirmDate: salesInfo?.DealFirmDate && moment(salesInfo?.DealFirmDate),
        TargetCloseDate: salesInfo?.TargetCloseDate && moment(salesInfo?.TargetCloseDate),
      });
  }, [salesInfo]);

  useEffect(() => {
    const salesData = localStorage.getItem('sales_data');
    if (salesData) {
      dispatch(changeSalesLoading(true));
      fetchOneSaleApi({ id: salesData })
        .then(({ data: values }) => {
          !isSaleEditable(values) &&
            notification.error({ description: 'The deal is not editable anymore!' });
          dispatch(saveSalesData(values));
          dispatch(changeSalesLoading(false));
        })
        .catch(() => {
          dispatch(changeSalesLoading(false));
        });
    } else if (!salesData) {
      navigate('/');
    }
    brokersCall({ _sort: 'AgencyName', _limit: 1000 });
  }, []);

  // const handleDepositChange = debounce((value = {}) => {
  //   handleSave({ TotInitialDeposit: value });
  // }, 500);

  const handleCoBrokerChange = debounce((value = {}, callBack) => {
    handleSave(value, callBack);
  }, 500);

  const closeModal = () => {
    setEditData(null);
  };

  const handleBrokerClick = () => {
    setEditData({
      ...(brokerAgents?.find(el => el?.id === form.getFieldValue('coBrokers')) || {}),
    });
  };

  const handleFirmClick = () => {
    confirm({
      title: 'Selected deal will be set to Firm Sale!',
      icon: <ExclamationCircleOutlined />,
      // content: 'All changes will be lost!',
      cancelText: 'NO',
      okText: 'YES',
      maskClosable: true,
      okButtonProps: {
        style: { backgroundColor: darkBlue },
      },
      onOk() {
        const updateSale = updateSaleApi({
          id: salesInfo?.id,
          values: {
            SaleStatus_id: findBasicByCode(salesStatuses, 'Firm Sale')?.id,
            FirmStatDate: moment().format(),
          },
        });
        const updateUnit = updateUnitApi({
          id: salesInfo?.ProjUnit_id?.id,
          values: { UnitStatus_id: findBasicByCode(unitStatuses, 'sold')?.id },
        });
        return Promise.all([updateSale, updateUnit]).then(() => {
          notification.success({
            message: 'Deal is updated to Firm Sale!',
            duration: 3,
          });
          handleSave(
            {},
            () => {},
            () => {},
            true
          );
        });
      },
      onCancel() {},
    });
  };

  const checkEnabled = (statuses, dateKey) =>
    statuses.includes(salesInfo?.SaleStatus_id?.code.toLowerCase()) && !salesInfo?.[dateKey];

  const controls = [
    {
      icon: <StopOutlined style={{ color: '#EF3E36', fontSize: '20px' }} />,
      title: 'RESCIND DEAL',
      condition: 'rescindDeal',
      enabled: checkEnabled(['firm sale', 'assigned'], 'RescindEffDate'),
    },
    {
      icon: <CloseCircleFilled style={{ color: darkBlue, fontSize: '20px' }} />,
      title: 'RELEASE DEAL',
      condition: 'releaseDeal',
      enabled: checkEnabled(['firm sale', 'assigned'], 'ReleaseEffDate'),
    },
    {
      icon: <ApartmentOutlined style={{ fontSize: '20px' }} />,
      title: 'ASSIGN DEAL',
      condition: 'assignDeal',
      enabled: checkEnabled(['firm sale'], 'AssignEffDate'),
    },
    {
      icon: <CloseCircleFilled style={{ color: '#EF3E36', fontSize: '20px' }} />,
      title: 'CANCEL SALE',
      condition: 'cancelSale',
      enabled: checkEnabled(['firm sale', 'assigned'], 'CancelEffDate'),
      hidden: ['draft', 'worksheet'].includes(salesInfo?.SaleStatus_id?.code.toLowerCase()),
    },
    {
      icon: <CloseCircleFilled style={{ color: '#EF3E36', fontSize: '20px' }} />,
      title: 'DELETE SALE',
      condition: 'deleteSale',
      enabled: true,
      notificationMessage: 'The sale is successfully deleted!',
      hidden: !['draft', 'worksheet'].includes(salesInfo?.SaleStatus_id?.code.toLowerCase()),
      onClick: () => {
        if (user?.role?.type === 'admin_sale') {
          backToAdminFromSale();
        } else {
          navigate('/');
          localStorage.removeItem('sales_data');
        }
      },
    },
  ];

  return (
    <>
      {imageList && (
        <ImageListModal
          isSale={true}
          imageList={imageList?.FloorPlanFile}
          closeModal={() => setImageList(null)}
          customWidth='1000px'
          title={imageList?.Project_id?.Name}
          subTitle={
            (imageList?.ModelCode || imageList?.ModelName) && (
              <Col align='center' min_height='0'>
                {imageList?.ModelCode && (
                  <Paragraph mb={0} fz='inherit' color='inherit'>
                    {imageList.ModelCode}
                  </Paragraph>
                )}
                {imageList?.ModelName && (
                  <>
                    {imageList?.ModelCode && (
                      <Col height='17px' margin='0 6px' width='2px' back_color='white' />
                    )}
                    <Paragraph mb={0} fz='inherit' color='inherit'>
                      {imageList.ModelName}
                    </Paragraph>
                  </>
                )}
              </Col>
            )
          }
          imgProps={{
            imgHeight: '77vh',
            maxCount: 1,
            videoStyles: {
              height: '77vh',
            },
            customPdf: true,
          }}
        />
      )}
      {statusPopupType && (
        <SalesStatusPopup
          closeModal={() => setStatusPopupType(null)}
          type={statusPopupType}
          firmDate={salesInfo?.DealFirmDate}
        />
      )}
      {editData && (
        <CoBrokeAgentsClientsModal
          closeModal={closeModal}
          editData={editData}
          setEditData={setEditData}
          title='Broker Agent'
          subTitle={
            editData?.FirstName &&
            editData?.LastName &&
            `${editData?.FirstName} ${editData?.LastName}`
          }
          brokerage={brokers}
          setTriggerRequest={() => {}}
          afterSave={values => {
            handleCoBrokerChange({
              BrokerAgent_id: values,
              SeqNo: 0,
              action: 'setBrokerAgent',
            });
          }}
          isSale={true}
        />
      )}
      <Form
        form={form}
        {...defFormLayout}
        type='orange'
        input_border_color={salesMainColor}
        disabled={!isSaleEditable(salesInfo)}
      >
        <Row gutter={[10, 10]}>
          <Col span={8}>
            <FormItem name={['Project_id', 'Name']} label='Project'>
              <Input disabled={true} className='orange_outlined' />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem name={['ProjUnit_id', 'UnitNo']} label='Unit No'>
              <Input disabled={true} text_align='center' className='orange_outlined' />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem
              name={['ProjModel_id', 'ModelName']}
              label={
                <>
                  Model
                  <Button
                    onClick={() => {
                      setImageList(salesInfo?.ProjModel_id);
                    }}
                    type='primary'
                    back_color={darkBlue}
                    margin='0 3px 0px auto'
                    ml_span='4px'
                    height='fit-content'
                    padding='3px 10px'
                    fw={500}
                    fz='10px'
                    shadow='none'
                    line_height='11px'
                    align='center'
                    icon={<FileSearchOutlined />}
                  >
                    Floor Plan
                  </Button>
                </>
              }
            >
              <Input disabled={true} text_align='center' className='orange_outlined' />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem
              name='SalesAlloc_id'
              label='Allocation'
              rules={[{ required: true, message: 'Allocation is required!' }]}
            >
              <Select
                height='30px'
                onChange={value => {
                  value && handleSave({ SalesAlloc_id: value });
                }}
                type='sale'
                className='orange_outlined'
                text_align='center'
                filterOption={filterOption}
                showSearch={true}
                allowClear={true}
                clear_opacity='1'
                clear_right='30px'
                onDropdownVisibleChange={open => open && refetch()}
                dropdownRender={menu => (
                  <Spin size='small' spinning={isLoading}>
                    {menu}
                  </Spin>
                )}
              >
                {salesAlloc.map(el => (
                  <Select.Option value={el?.id} key={el?.id}>
                    {el?.value}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label='Buyer Type'
              name='IsInvestment'
              rules={[{ required: true, message: 'Buyer Type is required!' }]}
            >
              <Select
                className='orange_outlined'
                height='30px'
                onChange={value => {
                  ![undefined, null].includes(value) && handleSave({ IsInvestment: value });
                }}
                type='sale'
                filterOption={filterOption}
                showSearch={true}
                allowClear={true}
                clear_opacity='1'
                clear_right='30px'
              >
                {[
                  { value: false, label: 'End-User' },
                  { value: true, label: 'Investment' },
                ].map(el => (
                  <Select.Option value={el.value} key={el.value}>
                    {el.label}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label='Residency'
              name='NonResident'
              rules={[{ required: true, message: 'Residency is required!' }]}
            >
              <Select
                onChange={value => {
                  ![undefined, null].includes(value) && handleSave({ NonResident: value });
                }}
                type='sale'
                className='orange_outlined'
                filterOption={filterOption}
                showSearch={true}
                allowClear={true}
                clear_opacity='1'
                clear_right='30px'
              >
                {[
                  { value: false, label: 'Resident' },
                  { value: true, label: 'Non-Resident' },
                ].map(el => (
                  <Select.Option value={el?.value} key={el?.value}>
                    {el.label}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label='In-Person'
              name='BuyerPresent'
              rules={[{ required: true, message: 'In-Person is required!' }]}
            >
              <Select
                onChange={value => {
                  ![undefined, null].includes(value) && handleSave({ BuyerPresent: value });
                }}
                type='sale'
                className='orange_outlined'
                text_align='center'
                filterOption={filterOption}
                showSearch={true}
                allowClear={true}
                clear_opacity='1'
                clear_right='30px'
              >
                {[
                  { value: true, label: 'In Person' },
                  { value: false, label: 'Virtual' },
                ].map(el => (
                  <Select.Option value={el?.value} key={el?.value}>
                    {el.label}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={6}>
            <Spin spinning={reBrokerAgentLoading}>
              <FormItem
                label={
                  <>
                    Broker name
                    <Button
                      onClick={handleBrokerClick}
                      type='primary'
                      back_color={darkBlue}
                      margin='0 3px 0px auto'
                      ml_span='4px'
                      height='fit-content'
                      padding='3px 10px'
                      fw={500}
                      fz='10px'
                      shadow='none'
                      line_height='10px'
                      align='center'
                      icon={
                        form.getFieldValue('coBrokers') ? (
                          <EditOutlined
                            style={{
                              color: 'white',
                              fontSize: 8,
                            }}
                          />
                        ) : (
                          <PlusOutlined
                            style={{
                              color: 'white',
                              fontSize: 8,
                            }}
                          />
                        )
                      }
                    >
                      Broker
                    </Button>
                  </>
                }
                name='coBrokers'
                rules={[{ required: true, message: 'Broker name is required!' }]}
                className='orange_outlined'
                label_overflow='inherit'
              >
                <LazySelect
                  showSearch={true}
                  filterOption={filterOption}
                  allowClear={true}
                  call={(props, afterCall) =>
                    brokerAgentsCall({ _sort: 'FullName', ...props }, afterCall)
                  }
                  callOne={reBrokerAgentCall}
                  value={salesInfo?.coBrokers?.[0]?.BrokerAgent_id?.id}
                  loading={brokerAgentsLoading}
                  total={brokerAgentsCount}
                  labelKey='FullName'
                  clear_opacity='1'
                  clear_right='30px'
                  type='sale'
                  selectOptions={el => ({
                    className: el?.Project_ids?.includes(salesInfo?.Project_id?.id)
                      ? 'gray_option'
                      : '',
                  })}
                  onChange={value => {
                    value &&
                      handleSave({
                        action: 'setBrokerAgent',
                        BrokerAgent_id: brokerAgents.find(item => item?.id === value),
                        SeqNo: 0,
                      });
                  }}
                  className='orange_outlined'
                />
              </FormItem>
            </Spin>
          </Col>
          <Col span={6}>
            <FormItem
              name='SaleSource_id'
              label='Sale Source'
              rules={[{ required: true, message: 'Sale Source is required!' }]}
            >
              <Select
                showSearch={true}
                height='30px'
                allowClear={true}
                onChange={value => {
                  value && handleSave({ SaleSource_id: value });
                }}
                filterOption={filterOption}
                type='sale'
                className='orange_outlined'
                text_align='center'
                clear_opacity='1'
                clear_right='30px'
                onDropdownVisibleChange={open => open && refetch()}
                dropdownRender={menu => (
                  <Spin size='small' spinning={isLoading}>
                    {menu}
                  </Spin>
                )}
              >
                {salesSources.map(el => (
                  <Select.Option value={el?.id} key={el?.id}>
                    {el?.value}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row margin='10px 0 0' className='collapse_section'>
          <Collapse
            type='blue'
            expandIconPosition='right'
            ghost={false}
            head_back_color='#DEE6F0'
            head_padding='5px 11px'
            expandIcon={rec => <UpCircleOutlined rotate={rec.isActive ? 0 : 180} />}
            defaultActiveKey={['General']}
          >
            <Panel
              header={
                <Title fz='12px' fw='600' lh='18px' color='#595859' mb='0' height='18px'>
                  Sales Summary Details
                </Title>
              }
              key='General'
            >
              <Row gutter={[10, 10]} margin='10px 0 0'>
                <ProjectFormWrapper span={9}>
                  <Row>
                    <Col span={10}>
                      <Paragraph mb={0} fw={700}>
                        Sale Date:
                      </Paragraph>
                    </Col>
                    <Col span={14}>
                      <FormItem name='SaleOfferDate' mb={0}>
                        <DatePicker
                          format='MMMM DD, YYYY'
                          height='100%'
                          className='custom-height'
                          allowClear={false}
                          onChange={value => {
                            handleSave({ SaleOfferDate: value });
                          }}
                          disabledDate={current =>
                            current &&
                            toStartOfDay(current) >
                              (salesInfo?.OfferAckDate
                                ? toStartOfDay(salesInfo?.OfferAckDate)
                                : toStartOfDay())
                          }
                          clear_opacity='1'
                          clear_right='20px'
                          fz='14px'
                        />
                      </FormItem>
                    </Col>
                    <Col span={10}>
                      <Paragraph mb={0} fw={700}>
                        Ack. Date:
                      </Paragraph>
                    </Col>
                    <Col span={14}>
                      <FormItem name='OfferAckDate' mb={0}>
                        <DatePicker
                          format='MMMM DD, YYYY'
                          height='100%'
                          allowClear={
                            (salesInfo?.SaleStatus_id?.code || '')?.toLowerCase() === 'conditional'
                          }
                          className='custom-height'
                          onChange={value => {
                            handleSave({
                              OfferAckDate: value ? moment(value).format('YYYY-MM-DD') : null,
                              DealFirmDate: value
                                ? moment(value)
                                    .add(salesInfo?.Project_id?.SaleFirmDays || 10, 'day')
                                    .format('YYYY-MM-DD')
                                : null,
                              currentDate: moment().format('YYYY-MM-DD'),
                              callback: 'updateStatus',
                            });
                          }}
                          disabledDate={current => {
                            return (
                              current &&
                              ((salesInfo?.SaleOfferDate
                                ? toStartOfDay(current) < toStartOfDay(salesInfo?.SaleOfferDate)
                                : true) ||
                                toStartOfDay(current) > toStartOfDay())
                            );
                          }}
                          clear_opacity='1'
                          clear_right='20px'
                          fz='14px'
                        />
                      </FormItem>
                    </Col>
                    <Col span={10} className='border_bottom'>
                      <Paragraph
                        mb={0}
                        fw={700}
                        {...(salesInfo?.DealFirmDate &&
                        toStartOfDay(salesInfo?.DealFirmDate) < toStartOfDay() &&
                        !salesInfo?.FirmStatDate
                          ? {
                              style: {
                                color: mainColor,
                                textDecoration: 'underline',
                                float: 'left',
                                cursor: 'pointer',
                              },
                              onClick: handleFirmClick,
                            }
                          : {})}
                      >
                        Firm Date:
                      </Paragraph>
                    </Col>
                    <Col span={14} className='border_bottom'>
                      <FormItem name='DealFirmDate' mb={0}>
                        <DatePicker
                          format='MMMM DD, YYYY'
                          height='100%'
                          className='custom-height'
                          allowClear={false}
                          disabled={!salesInfo?.OfferAckDate || !isSaleEditable(salesInfo)}
                          onChange={value => {
                            handleSave({
                              DealFirmDate: moment(value).format('YYYY-MM-DD'),
                              currentDate: moment().format('YYYY-MM-DD'),
                            });
                          }}
                          disabledDate={current =>
                            current &&
                            toStartOfDay(current) <
                              (salesInfo?.OfferAckDate
                                ? toStartOfDay(salesInfo?.OfferAckDate)
                                : toStartOfDay())
                          }
                          clear_opacity='1'
                          clear_right='20px'
                          fz='14px'
                        />
                      </FormItem>
                    </Col>
                    <Col span={10} className='border_bottom'>
                      <Paragraph mb={0} fw={700}>
                        Closing Date:
                      </Paragraph>
                    </Col>
                    <Col span={14} className='border_bottom'>
                      <FormItem name='TargetCloseDate' mb={0}>
                        <DatePicker
                          format='MMMM DD, YYYY'
                          height='100%'
                          className='custom-height'
                          allowClear={false}
                          onChange={value => {
                            handleSave({
                              TargetCloseDate: value ? moment(value).format('YYYY-MM-DD') : null,
                            });
                          }}
                          disabledDate={current =>
                            current &&
                            toStartOfDay(current) <
                              (salesInfo?.DealFirmDate
                                ? toStartOfDay(salesInfo?.DealFirmDate)
                                : toStartOfDay().add(
                                    (salesInfo?.Project_id?.SaleFirmDays || 10) - 1,
                                    'day'
                                  ))
                          }
                          clear_opacity='1'
                          clear_right='20px'
                          fz='14px'
                        />
                      </FormItem>
                    </Col>
                    <Col span={10} className='border_bottom'>
                      <Paragraph mb={0} fw={700}>
                        Deposit Plan:
                      </Paragraph>
                    </Col>
                    <Col span={14} className='border_bottom' pl='5px'>
                      <FormItem name='DepositRef_id' mb={0}>
                        <Select
                          onChange={value => {
                            handleSave({
                              action: 'setDepositPlan',
                              DepositRef_id: (salesInfo?.DepositRefs || []).find(
                                el => el.id === value
                              ),
                            });
                          }}
                          showSearch={true}
                          filterOption={filterOption}
                          type='sale'
                          icon_right='15px'
                          clear_opacity='1'
                          clear_right='30px'
                          fz='14px'
                          suffixIconProps={{ style: { marginRight: '1px' } }}
                        >
                          {(salesInfo?.DepositRefs || []).map(el => (
                            <Select.Option value={el.id} key={el.id}>
                              {el.Description}
                            </Select.Option>
                          ))}
                        </Select>
                      </FormItem>
                    </Col>
                    <Col span={10} className='border_bottom' mb={0}>
                      <Paragraph mb={0} fw={700}>
                        Total Deposits:
                      </Paragraph>
                    </Col>
                    <Col
                      span={14}
                      className='border_bottom'
                      mb={0}
                      justify='space-between'
                      align='center'
                    >
                      {/* <FormItem name='TotInitialDeposit' mb={0}> */}
                      {/* <FormItem name='totalDeposits' mb={0}>
                        <InputPrice
                          controls={false}
                          className='custom_height remove_addon_style'
                          addonBefore='$'
                          height='100%'
                          text_align='end'
                          // onChange={handleDepositChange}
                          disabled={true}
                          max={9000000}
                          padding='0 15px 0 0'
                          disabled_color='#000000'
                          addonAfter={
                            <MenuOutlined
                              onClick={() => navigate('/deposits')}
                              style={{ fontSize: 12, color: '#bfbfbf' }}
                            />
                          }
                        />
                      </FormItem> */}
                      <Paragraph
                        justify='space-between'
                        vert_align='center'
                        mb={0}
                        padding='0 5px'
                        height='100%'
                        width='-webkit-fill-available'
                      >
                        <span>$</span>
                        <span>
                          {Number(salesInfo?.totalDeposits || 0).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </span>
                      </Paragraph>
                      <MenuOutlined
                        onClick={() => navigate('/deposits')}
                        style={{ fontSize: 12, color: '#000000d9', margin: '0 16px 0 10px' }}
                      />
                    </Col>
                  </Row>
                </ProjectFormWrapper>
                <ProjectFormWrapper span={9}>
                  <Row>
                    <Col span={10}>
                      <Paragraph mb={0} fw={700}>
                        Base Price:
                      </Paragraph>
                    </Col>
                    <Col span={14} justify='space-between' align='center'>
                      <Paragraph
                        justify='space-between'
                        vert_align='center'
                        mb={0}
                        padding='0 5px'
                        height='100%'
                        width='-webkit-fill-available'
                      >
                        <span>$</span>
                        <span>
                          {Number(salesInfo?.BaseModelPrice || 0).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </span>
                      </Paragraph>
                      <MenuOutlined
                        style={{ fontSize: 12, color: '#000000d9', margin: '0 10px' }}
                      />
                    </Col>
                    <Col span={10}>
                      <Paragraph mb={0} fw={700}>
                        Premiums:
                      </Paragraph>
                    </Col>
                    <Col span={14} justify='space-between' align='center'>
                      <Paragraph
                        justify='space-between'
                        vert_align='center'
                        mb={0}
                        padding='0 5px'
                        height='100%'
                        width='-webkit-fill-available'
                      >
                        <span>$</span>
                        <span>
                          {Number(salesInfo?.TotalPremiums || 0).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </span>
                      </Paragraph>
                      <MenuOutlined
                        onClick={() => navigate('/adjustments', { state: { showPremium: true } })}
                        style={{ fontSize: 12, color: '#000000d9', margin: '0 10px' }}
                      />
                    </Col>
                    <Col span={10} className='border_bottom'>
                      <Paragraph mb={0} fw={700}>
                        Add-Ons:
                      </Paragraph>
                    </Col>
                    <Col span={14} className='border_bottom' justify='space-between' align='center'>
                      <Paragraph
                        justify='space-between'
                        vert_align='center'
                        mb={0}
                        padding='0 5px'
                        height='100%'
                        width='-webkit-fill-available'
                      >
                        <span>$</span>
                        <span>
                          {Number(salesInfo?.TotalOptionals || 0).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </span>
                      </Paragraph>
                      <MenuOutlined
                        onClick={() => navigate('/add-ons', { state: { showSelected: true } })}
                        style={{ fontSize: 12, color: '#000000d9', margin: '0 10px' }}
                      />
                    </Col>
                    <Col span={10} className='border_bottom'>
                      <Paragraph mb={0} fw={700} color={darkBlue}>
                        TOTAL PRICE:
                      </Paragraph>
                    </Col>
                    <Col span={14} className='border_bottom' justify='space-between' align='center'>
                      <Paragraph
                        justify='space-between'
                        vert_align='center'
                        mb={0}
                        padding='0 5px'
                        height='100%'
                        width='-webkit-fill-available'
                      >
                        <span>$</span>
                        <span>
                          {Number(
                            (salesInfo?.TotalOptionals || 0) +
                              (salesInfo?.TotalPremiums || 0) +
                              (salesInfo?.BaseModelPrice || 0)
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </span>
                      </Paragraph>
                      <MenuOutlined
                        style={{ fontSize: 12, color: '#000000d9', margin: '0 10px' }}
                      />
                    </Col>
                    <Col span={10} className='border_bottom'>
                      <Paragraph mb={0} fw={700}>
                        Incentives:
                      </Paragraph>
                    </Col>
                    <Col span={14} className='border_bottom' justify='space-between' align='center'>
                      <Paragraph
                        justify='space-between'
                        vert_align='center'
                        mb={0}
                        padding='0 5px'
                        height='100%'
                        width='-webkit-fill-available'
                      >
                        <span>$</span>
                        <span>
                          {Number(salesInfo?.TotalIncentives || 0).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </span>
                      </Paragraph>
                      <MenuOutlined
                        onClick={() =>
                          navigate('/adjustments', { state: { showIncentives: true } })
                        }
                        style={{ fontSize: 12, color: '#000000d9', margin: '0 10px' }}
                      />
                    </Col>
                    <Col span={10} className='border_bottom' mb={0}>
                      <Paragraph mb={0} fw={700} color={darkBlue}>
                        Final Price:
                      </Paragraph>
                    </Col>
                    <Col
                      span={14}
                      className='border_bottom'
                      mb={0}
                      justify='space-between'
                      align='center'
                    >
                      <Paragraph
                        justify='space-between'
                        vert_align='center'
                        mb={0}
                        padding='0 5px'
                        height='100%'
                        width='-webkit-fill-available'
                      >
                        <span>$</span>
                        <span>
                          {Number(
                            (salesInfo?.TotalOptionals || 0) +
                              (salesInfo?.TotalPremiums || 0) +
                              (salesInfo?.BaseModelPrice || 0) -
                              (salesInfo?.TotalIncentives || 0)
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </span>
                      </Paragraph>
                      <MenuOutlined
                        style={{ fontSize: 12, color: '#000000d9', margin: '0 10px' }}
                      />
                    </Col>
                  </Row>
                </ProjectFormWrapper>
                <Col span={6}>
                  <Row gutter={[0, 10]}>
                    <Col span={24}>
                      <FormItem name='SaleStatus_id'>
                        <Select
                          showSearch={true}
                          height='30px'
                          // allowClear={true}
                          onChange={value => {
                            handleSave({ SaleStatus_id: value });
                          }}
                          type='sale'
                          className='orange_outlined'
                          text_align='center'
                          filterOption={filterOption}
                          clear_opacity='1'
                          clear_right='30px'
                          onDropdownVisibleChange={open => open && refetch()}
                          dropdownRender={menu => (
                            <Spin size='small' spinning={isLoading}>
                              {menu}
                            </Spin>
                          )}
                          disabled={!(user?.IsAppAdmin || user?.IsSysAdmin)}
                        >
                          {salesStatuses.map(el => (
                            <Select.Option value={el?.id} key={el?.id}>
                              {el?.value}
                            </Select.Option>
                          ))}
                        </Select>
                      </FormItem>
                    </Col>
                    <Col span={24} border='1px solid #a6a6a6' padding='10px 10px 9px'>
                      <Row gutter={[0, 10]}>
                        {salesInfo?.LockSetDate ? (
                          <Col
                            span={11}
                            onClick={() => {
                              handleSave(
                                { action: 'changeStatus', condition: 'unlockSales' },
                                () => {},
                                () => {},
                                false,
                                'Deal is UNLOCKED for edits!'
                              );
                            }}
                            cursor='pointer'
                            display='flex'
                          >
                            <UnlockOutlined style={{ fontSize: '20px' }} />
                            <Paragraph mb={0} ml='5'>
                              UNLOCK
                            </Paragraph>
                          </Col>
                        ) : (
                          <Col
                            span={11}
                            onClick={() => {
                              handleSave(
                                { action: 'changeStatus', condition: 'lockSales' },
                                () => {},
                                () => {},
                                false,
                                'Deal is LOCKED to further edits!'
                              );
                            }}
                            cursor='pointer'
                            display='flex'
                          >
                            <LockOutlined style={{ fontSize: '20px' }} />
                            <Paragraph mb={0} ml='5'>
                              LOCK
                            </Paragraph>
                          </Col>
                        )}
                        <Col span={1}>
                          <Divider type='vertical' height='100%' margin='0' back_color='#171b1e' />
                        </Col>
                        <Col
                          span={11}
                          onClick={() => {
                            handleSave({ action: 'changeStatus', condition: 'validateDeal' });
                          }}
                          display='flex'
                          justify='end'
                          cursor='pointer'
                        >
                          <Paragraph mb={0} mr='5' white_space='nowrap'>
                            CHECK
                          </Paragraph>
                          <CheckCircleOutlined style={{ fontSize: '20px' }} />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24} border='1px solid #a6a6a6' padding='10px'>
                      <Row gutter={[5, 10]}>
                        {controls.map(item => (
                          <Col
                            span={24}
                            cursor='not-allowed'
                            key={item?.title}
                            {...(item.enabled
                              ? {
                                  cursor: 'pointer',
                                  onClick: e => {
                                    if (item?.condition === 'deleteSale') {
                                      confirm({
                                        title: 'Are you sure you want to Delete this Sale?',
                                        icon: <ExclamationCircleOutlined />,
                                        cancelText: 'Cancel',
                                        okText: 'OK',
                                        maskClosable: true,
                                        okButtonProps: { style: { backgroundColor: darkBlue } },
                                        onOk: () => {
                                          handleSave(
                                            {
                                              action: 'changeStatus',
                                              condition: item.condition,
                                            },
                                            () => item?.onClick?.(e),
                                            () => {},
                                            false,
                                            item.notificationMessage || ''
                                          );
                                        },
                                        onCancel() {},
                                      });
                                    } else {
                                      setStatusPopupType(item.condition);
                                      // handleSave(
                                      //   {
                                      //     action: 'changeStatus',
                                      //     condition: item.condition,
                                      //   },
                                      //   () => item?.onClick?.(e),
                                      //   () => {},
                                      //   false,
                                      //   item.notificationMessage || ''
                                      // );
                                    }
                                  },
                                }
                              : {})}
                            display={item.hidden ? 'none' : ''}
                          >
                            <Row gutter={[5, 10]}>
                              <Col span={4} display='flex'>
                                {item.icon}
                              </Col>
                              <Col span={20}>
                                <Paragraph mb={0}>{item.title}</Paragraph>
                              </Col>
                            </Row>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Row>
      </Form>
      <Tabs
        type='sales'
        activeKey={location.pathname}
        onChange={url => navigate(url)}
        renderTabBar={(props, TabNavList) => <TabNavList {...props} mobile={false} />}
      >
        {Object.entries(tabOptions).map(item => (
          <TabPane tab={item[0]} key={item[1]} />
        ))}
      </Tabs>
      {children}
    </>
  );
};

export default SalesInfoWrapper;
