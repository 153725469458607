/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const projectFilterValueSlice = createSlice({
  name: 'projectFilterValue',
  initialState: {
    data: null,
  },
  reducers: {
    saveProjectFilterValue(state, { payload }) {
      state.data = payload;
    },
  },
});

export const { saveProjectFilterValue } = projectFilterValueSlice.actions;
export default projectFilterValueSlice.reducer;
