import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useGetMainColor from 'Utils/hooks/useGetMainColor';
import { Button, Form, Modal, Paragraph, Row } from '../atoms';
import { mainColor } from '../../constants/colors';

const { confirm } = Modal;
const ModalContentWrapper = styled.div`
  width: 70%;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  height: max-content;
  margin: 50px 0;
  transform: scale(0);
  transition: all ease 0.3s;
  overflow: hidden;

  .title_content_child {
    color: #fff !important;
    position: relative;
    padding-left: 10px;
    &:after {
      content: '';
      font-size: 18px;
      font-weight: 500;
      position: absolute;
      left: 0;
      top: 0;
      width: 2px;
      height: 17px;
      background-color: #fff;
      bottom: 0;
      margin: auto;
    }
  }
  ${props =>
    props.open_status === 'open' &&
    css`
      transform: scale(1);
    `}
  ${props =>
    props.type === 'blue' &&
    css`
      & .title_wrapper {
        background: ${mainColor};
        padding: 7.5px 30px !important;
        & .ant-btn,
        & .ant-typography {
          color: white !important;
        }
        & .subtitle,
        & .title {
          position: relative;
          padding-left: 15px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }
        & .title,
        & .subtitle {
          color: #fff !important;
          position: relative;
          padding-left: 10px;
          &:after {
            content: '';
            font-size: 18px;
            font-weight: 500;
            position: absolute;
            left: 0;
            top: 0;
            width: 2px;
            height: 17px;
            background-color: #fff;
            bottom: 0;
            margin: auto;
          }
        }

        & .title {
          font-weight: 500;
          font-size: 18px;
        }
      }
      & .footer {
        background: #dee6f0;
        padding: 7.5px 32px;
      }
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width}!important;
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height}!important;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin}!important;
    `}
    ${props =>
    props.bottom &&
    css`
      position: relative;
      bottom: ${props.bottom}!important;
    `}
    ${props =>
    props.title_fz &&
    css`
      & .title {
        font-size: ${props.title_fz}!important;
      }
    `}
    ${props =>
    props.title_color &&
    css`
      & .title {
        color: ${props.color}!important;
      }
    `}
      ${props =>
    props.subtitle_fz &&
    css`
      & .subtitle {
        font-size: ${props.subtitle_fz}!important;
      }
    `}
  ${props =>
    props.title_padding &&
    css`
      .title_wrapper {
        padding: ${props.title_padding}!important;
      }
    `}
  ${props =>
    props.footer_padding &&
    css`
      .footer {
        padding: ${props.footer_padding}!important;
      }
    `}
    ${props =>
    props.footer_back_color &&
    css`
      .footer {
        background-color: ${props.footer_back_color}!important;
      }
    `}
    ${props =>
    props.footer_padding &&
    css`
      .footer {
        padding: ${props.footer_padding}!important;
      }
    `}
  ${props =>
    props.overflow &&
    css`
      overflow: ${props.overflow}!important;
    `}
  ${props =>
    props.shadow &&
    css`
      box-shadow: ${props.shadow}!important;
    `}
  ${props =>
    props.max_width &&
    css`
      max-width: ${props.max_width}!important;
    `}
    ${props =>
    props.min_width &&
    css`
      min-width: ${props.min_width}!important;
    `}
    ${props =>
    props.z_index &&
    css`
      z-index: ${props.z_index}!important;
    `}
  ${props =>
    props.title_back_color &&
    css`
      & .title_wrapper {
        background-color: ${props.title_back_color}!important;
      }
    `}
  ${props =>
    props.title_color &&
    css`
      .title_wrapper {
        .title_content,
        .ant-typography,
        button {
          color: ${props.title_color}!important;
        }
      }
    `}
   ${props =>
    props.head_color &&
    css`
      .title_wrapper .title,
      .title_wrapper .subtitle,
      .title_wrapper .ant-btn {
        color: ${props.head_color} !important;
        &:after {
          background-color: ${props.head_color} !important;
        }
      }
    `}
    ${props =>
    props.md_title_padding &&
    css`
      @media screen and (max-width: 768px) {
        .title_wrapper {
          padding: ${props.md_title_padding}!important;
        }
      }
    `}
    ${props =>
    props.sm_title_padding &&
    css`
      @media screen and (max-width: 576px) {
        .title_wrapper {
          padding: ${props.sm_title_padding}!important;
        }
      }
    `}
`;

const ModalContent = ({
  title,
  type,
  subTitle,
  closeModal,
  children,
  customWidth,
  customHeight,
  customMaxWidth,
  customMinWidth,
  customMargin,
  titlePadding,
  modalProps,
  showConfirm,
  footer,
  hideTitle = false,
  isEdit,
  titleColor,
  footerPadding,
}) => {
  const [activeColor, liteActiveColor] = useGetMainColor();
  const [openStatus, setOpenStatus] = useState('');
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpenStatus('open');
    }, 0);

    return () => setOpenStatus('');
  }, []);

  const onFormChange = () => {
    if (!formChanged) {
      setFormChanged(true);
    }
  };

  const handleWrapperClick = () => {
    if (showConfirm && formChanged) {
      confirm({
        title: 'Are you sure you want to leave this form?',
        icon: <ExclamationCircleOutlined />,
        content: 'All changes will be lost!',
        cancelText: 'Cancel',
        okText: 'OK',
        okButtonProps: {
          style: { backgroundColor: activeColor },
        },
        maskClosable: true,
        onOk() {
          closeModal();
        },
        onCancel() {},
      });
    } else {
      closeModal();
    }
  };

  return (
    <ModalContentWrapper
      type={type}
      onClick={event => event.stopPropagation()}
      open_status={openStatus}
      width={customWidth}
      height={customHeight}
      margin={customMargin}
      max_width={customMaxWidth}
      min_width={customMinWidth}
      title_padding={titlePadding || (subTitle ? '5px 30px' : '5px 30px 27px')}
      title_back_color={activeColor}
      title_color={titleColor}
      footer_back_color={liteActiveColor}
      footer_padding={footerPadding}
      {...modalProps}
    >
      {!hideTitle && (
        <Row justify='space-between' className='title_wrapper'>
          <div style={{ width: 'calc(100% - 100px)' }}>
            <Paragraph className={title ? 'title' : ''} fz={18} mb={0}>
              {title}
            </Paragraph>
            {subTitle && (
              <Paragraph className='subtitle' fz={14} mb={0}>
                {subTitle}
              </Paragraph>
            )}
          </div>
          <Button
            type='grey_ghost'
            padding='0'
            height='fit-content'
            onClick={handleWrapperClick}
            fontSize='14px'
          >
            Close
          </Button>
        </Row>
      )}
      <Form.Provider onFormChange={onFormChange}>{children}</Form.Provider>
      {footer && <footer className='footer'>{footer}</footer>}
    </ModalContentWrapper>
  );
};

export default ModalContent;
