import client from './main-client';

export const fetchAllProjectsApi = params => client().get('/projects', { params });
export const fetchAllProjectsCountApi = params => client().get('/projects/count', { params });
export const fetchOneProjectApi = ({ id, ...params }) =>
  client().get(`/projects/${id}`, { params });
export const updateProjectApi = params => client().put(`/projects/${params.id}`, params.values);
export const addProjectApi = data => client().post('/projects', data);
export const deleteProjectApi = params => client().delete(`/projects/${params.id}`, { params });

export const fetchAllPubProjectsApi = params => client().get('/pub-projects', { params });
export const fetchAllPubProjectsCountApi = params =>
  client().get('/pub-projects/count', { params });
export const fetchOnePubProjectApi = ({ id, ...params }) =>
  client().get(`/pub-projects/${id}`, { params });

export const fetchAllProjectBrokerAssetsApi = params =>
  client().get('/project-broker-assets', { params });
export const fetchAllProjectBrokerAssetsCountApi = params =>
  client().get('/project-broker-assets/count', { params });
export const updateProjectBrokerAssetApi = params =>
  client().put(`/project-broker-assets/${params.id}`, params.values);
export const addProjectBrokerAssetApi = data => client().post('/project-broker-assets', data);
export const deleteProjectBrokerAssetApi = params =>
  client().delete(`/project-broker-assets/${params.id}`, { params });

export const fetchAllProjectAssetTabsApi = params =>
  client().get('/project-web-asset-tabs', { params });
export const fetchAllProjectZoneRefsApi = params =>
  client().get('/project-web-zone-refs', { params });
export const fetchAllProjectAssetDetsApi = params =>
  client().get('/project-web-asset-dets', { params });

export const updateWebAssetApi = params =>
  client().put(`/pub-projects/${params.id}`, params.values);
export const fetchAllWebAssetsApi = params => client().get('/pub-projects', { params });

export const fetchAllWebAssetsCountApi = params => client().get('/pub-projects/count', { params });
