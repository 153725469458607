import { Collapse as collapse } from 'antd';
import styled, { css } from 'styled-components';

const Collapse = styled(collapse)`
  .ant-collapse-arrow svg {
    font-size: 14px;
    color: #717579;
  }
  .ant-collapse-item-disabled > .ant-collapse-header {
    cursor: initial;
  }
  .ant-collapse-header-text {
    width: 100%;
  }
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px!important;
    `}
  ${props =>
    (props.mt || props.mt === 0) &&
    css`
      margin-top: ${props.mt}px!important;
    `}
  ${props =>
    (props.head_mb || props.head_mb === 0) &&
    css`
      .ant-collapse-header {
        margin-bottom: ${props.head_mb}px !important;
      }
    `}
  ${props =>
    (props.item_mb || props.item_mb === 0) &&
    css`
      & .ant-collapse-item {
        margin-bottom: ${props.item_mb}px !important;
      }
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.border &&
    css`
      border: ${props.border};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding}!important;
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width}!important;
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height}!important;
    `}
  ${props =>
    props.transition &&
    css`
      transition: ${props.transition}!important;
    `}
  ${props =>
    props.back_color &&
    css`
      background-color: ${props.back_color}!important;
    `}
    ${props =>
    props.head_back_color &&
    css`
      .ant-collapse-header {
        background-color: ${props.head_back_color}!important;
      }
    `}
    ${props =>
    props.head_text_width &&
    css`
      .ant-collapse-header-text {
        width: ${props.head_text_width}!important;
      }
    `}
    ${props =>
    props.head_padding &&
    css`
      .ant-collapse-header {
        padding: ${props.head_padding}!important;
      }
    `}
  ${props =>
    props.border_bot &&
    css`
      border-bottom: ${props.border_bot};
    `}
  ${props =>
    props.border_top &&
    css`
      border-top: ${props.border_top};
    `}
  ${props =>
    props.hide === 'arrow' &&
    css`
      .ant-collapse-arrow {
        display: none !important;
      }
    `}
  ${props =>
    props.arrow_right &&
    css`
      .ant-collapse-arrow {
        right: ${props.arrow_right}!important;
      }
    `}
  ${props =>
    props.arrow_color &&
    css`
      .ant-collapse-arrow path {
        fill: ${props.arrow_color}!important;
      }
    `}
  ${props =>
    props.arrow_size &&
    css`
      .ant-collapse-arrow svg {
        font-size: ${props.arrow_size}!important;
      }
    `}
  ${props =>
    props.content_padding &&
    css`
      .ant-collapse-content-box {
        padding: ${props.content_padding}!important;
      }
    `}
  ${props =>
    props.header_align &&
    css`
      .ant-collapse-header {
        display: flex;
        align-items: ${props.header_align};
      }
    `}
    ${props =>
    props.type === 'blue' &&
    css`
      border: none !important;
      background: transparent;
      width: 100% !important;

      & .ant-collapse-content {
        border-top: none !important;
      }
      & .ant-collapse-header-text {
        width: 100%;
      }
      & svg,
      & span {
        transform-origin: center center;
      }
      & .ant-collapse-header {
        height: fit-content !important;
        margin: 0 !important;
      }
      & .ant-collapse-content-box {
        padding: 0px !important;
      }
      & .ant-collapse-item {
        border-bottom: none !important;
        margin-bottom: 10px;
      }

      & .ant-collapse-content-box {
        margin: 5px 0;
      }
    `}
    ${props =>
    props.sec_type === 'broker-finance' &&
    css`
      height: 100%;
      border: none !important;
      background: transparent;
      width: 100% !important;
      display: flex;
      flex-direction: column;
      & .ant-collapse-item:last-child {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
      }
    `}
    ${props =>
    props.sec_type === 'sales' &&
    css`
      display: flex;
      width: 100%;
      margin-top: 15px;
      border: none;
      background: transparent;

      & .ant-collapse-item {
        width: 100%;
        border: none;
      }
      &.ant-collapse .ant-collapse-item:first-child {
        margin-right: 10px !important;
      }

      & .ant-collapse-item {
        border-radius: 0px !important;
      }
      & .anticon {
        margin-right: 0px;
        & svg {
          color: #595859;
        }
      }
      & .ant-collapse-header-text {
        color: #595859;
        font-size: 12px;
        text-transform: uppercase;
      }
      & .ant-collapse-content-box {
        margin-top: 5px;
        border: 1px solid #d8d8d8;
        background: #f2f2f2;
        padding: 10px;
      }
    `}
`;

export default Collapse;
