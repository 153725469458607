import styled, { css } from 'styled-components';
import { Radio as radio } from 'antd';
import { mainColor } from '../../../constants/colors';

const Radio = styled(radio)`
  ${props =>
    props.type === 'blue' &&
    css`
      .ant-radio-checked {
        .ant-radio-inner {
          border-color: ${mainColor};

          &:after {
            background-color: ${mainColor};
          }
        }
      }
      .ant-radio-wrapper:hover .ant-radio,
      .ant-radio:hover .ant-radio-inner,
      .ant-radio-input:focus + .ant-radio-inner {
        border-color: ${mainColor};
      }
      .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: 0 0 3px ${mainColor}a3;
      }
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px!important;
    `}
  ${props =>
    props.shadow &&
    css`
      box-shadow: ${props.shadow};
    `}
  ${props =>
    props.direction &&
    css`
      display: flex;
      flex-direction: ${props.direction}!important;
    `}
`;

export default Radio;
