import Icon from '@ant-design/icons';
import { mainColor } from '../../../constants/colors';

const DocsSvg = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0)'>
      <path
        d='M20.5 24H8.5C7.122 24 6 22.879 6 21.5V6.5C6 5.121 7.122 4 8.5 4H20.5C21.878 4 23 5.121 23 6.5V21.5C23 22.879 21.878 24 20.5 24ZM8.5 5C7.673 5 7 5.673 7 6.5V21.5C7 22.327 7.673 23 8.5 23H20.5C21.327 23 22 22.327 22 21.5V6.5C22 5.673 21.327 5 20.5 5H8.5Z'
        fill={mainColor}
      />
      <path
        d='M4.5 21H3.5C2.122 21 1 19.879 1 18.5V2.5C1 1.121 2.122 0 3.5 0H15.5C16.878 0 18 1.121 18 2.5C18 2.776 17.776 3 17.5 3C17.224 3 17 2.776 17 2.5C17 1.673 16.327 1 15.5 1H3.5C2.673 1 2 1.673 2 2.5V18.5C2 19.327 2.673 20 3.5 20H4.5C4.776 20 5 20.224 5 20.5C5 20.776 4.776 21 4.5 21Z'
        fill={mainColor}
      />
      <path
        d='M18.5 17H10.5C10.224 17 10 16.776 10 16.5C10 16.224 10.224 16 10.5 16H18.5C18.776 16 19 16.224 19 16.5C19 16.776 18.776 17 18.5 17Z'
        fill={mainColor}
      />
      <path
        d='M18.5 21H10.5C10.224 21 10 20.776 10 20.5C10 20.224 10.224 20 10.5 20H18.5C18.776 20 19 20.224 19 20.5C19 20.776 18.776 21 18.5 21Z'
        fill={mainColor}
      />
      <path
        d='M18.5 13H10.5C10.224 13 10 12.776 10 12.5C10 12.224 10.224 12 10.5 12H18.5C18.776 12 19 12.224 19 12.5C19 12.776 18.776 13 18.5 13Z'
        fill={mainColor}
      />
      <path
        d='M18.5 9H10.5C10.224 9 10 8.776 10 8.5C10 8.224 10.224 8 10.5 8H18.5C18.776 8 19 8.224 19 8.5C19 8.776 18.776 9 18.5 9Z'
        fill={mainColor}
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

const DocsIcon = props => <Icon component={DocsSvg} {...props} />;

export default DocsIcon;
