import styled, { css } from 'styled-components';
import { Layout as layout } from 'antd';

const Layout = styled(layout)`
  min-height: 100vh;
  ${props =>
    props.border &&
    css`
      border: ${props.border} !important;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
`;

export default Layout;
