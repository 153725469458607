import styled, { css } from 'styled-components';
import { Layout as layout } from 'antd';

const Content = styled(layout.Content)`
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px!important;
    `}
  ${props =>
    props.back_color &&
    css`
      background-color: ${props.back_color};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
    ${props =>
    props.display &&
    css`
      display: ${props.display};
    `}
    ${props =>
    props.direction &&
    css`
      flex-direction: ${props.direction};
    `}
    ${props =>
    props.lg_padding &&
    css`
      @media screen and (max-width: 992px) {
        padding: ${props.lg_padding}!important;
      }
    `}
    ${props =>
    props.xs_position &&
    css`
      @media screen and (max-width: 575px) {
        position: ${props.xs_position};
      }
    `}
`;

export default Content;
