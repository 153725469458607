import { useRef } from 'react';
import { CloseCircleOutlined, SendOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import TextEditor from 'components/molecules/TextEditor';
import useGetMainColor from 'Utils/hooks/useGetMainColor';
import ModalContent from '../molecules/ModalContent';
import { Button, Col, Form, FormItem, Input, MaskedInput, ModalWrapper, Row } from '../atoms';
import { defFormLayout, phoneMask, phonePattern } from '../../constants/etc';
import useApiCall from '../../Utils/hooks/useApiCall';
import { sendEmailApi } from '../../services/email';
import { useGetAppSettingsQuery } from '../../app/services/app-settings';
import { getUser } from '../../app/services/selector-helpers';

const CustomModalWrapper = styled.div`
  @media screen and (max-width: 992px) {
    & {
      position: fixed !important;
      top: 50% !important;
      right: 50% !important;
      transform: translate(50%, -50%);
    }
  }
  & {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0 auto;
    z-index: 1;
    width: fit-content;
  }

  & .footer {
    padding: 7.5px 15px;
  }
  ${props =>
    props.top &&
    css`
      top: ${props.top} !important;
    `}
`;

const EmailModal = ({
  closeModal,
  title,
  subTitle,
  to,
  from,
  subject,
  topPosition,
  withDefWrapper,
  onSave = () => {},
}) => {
  const [form] = Form.useForm();
  const [activeColor] = useGetMainColor();
  const { data: settings } = useGetAppSettingsQuery();
  const [, sendEmailLoading, sendEmailCall] = useApiCall(sendEmailApi);
  const user = useSelector(getUser);
  const messageRef = useRef(null);

  const onFinish = values => {
    const message = messageRef?.current?.getContent();
    sendEmailCall({ subject, ...values, message, to, from, userId: user?.id }, () => {
      onSave();
      closeModal();
      notification.success({
        message: 'Your message sent successfully.',
        duration: 3,
      });
    });
  };

  const footer = (
    <Row justify='space-between' align='middle'>
      <Button
        onClick={closeModal}
        type='light_blue'
        color={activeColor}
        icon={<CloseCircleOutlined />}
        fz='12px'
        fw={500}
      >
        CANCEL
      </Button>
      <Button
        type='light_blue'
        color={activeColor}
        icon={<SendOutlined />}
        onClick={form.submit}
        loading={sendEmailLoading}
        fz='12px'
        fw={500}
      >
        SEND
      </Button>
    </Row>
  );

  const Wrapper = withDefWrapper ? ModalWrapper : CustomModalWrapper;

  return (
    <Wrapper top={topPosition} closeModal={closeModal} showConfirm={true}>
      <ModalContent
        customWidth='400px'
        type='blue'
        title={title}
        subTitle={subTitle}
        closeModal={closeModal}
        footer={footer}
        titlePadding='5px 15px'
        modalProps={{
          overflow: withDefWrapper ? '' : 'visible',
          shadow: withDefWrapper ? '' : '0 0 4px 1px #bbb',
          margin: withDefWrapper ? '' : '0',
          title_fz: withDefWrapper ? '' : '16px',
          subtitle_fz: withDefWrapper ? '' : '14px',
          footer_padding: withDefWrapper ? '7.5px 15px' : '',
        }}
      >
        <Row padding='15px'>
          <Form
            {...defFormLayout}
            form={form}
            width='100%'
            onFinish={onFinish}
            type='black_popup'
            activeColor={activeColor}
            label_height='18px'
            label_max_height='22px'
            active_color={activeColor}
            initialValues={{
              name: user?.broker?.FullName || '',
              phone: user?.broker?.Phone || user?.broker?.Mobile || '',
              email: user?.broker?.Email || '',
              subject: withDefWrapper ? settings?.APP_COMPANY_DBA_NAME || '' : subject || '',
            }}
          >
            <Row gutter={24}>
              <Col span={24}>
                <FormItem
                  name='name'
                  label='Name'
                  rules={[
                    {
                      required: true,
                      message: 'Name is required!',
                    },
                  ]}
                >
                  <Input type='blue' />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  name='phone'
                  label='Phone'
                  rules={[
                    {
                      required: true,
                      message: 'Phone is required!',
                    },
                    {
                      pattern: phonePattern,
                      message: 'Input valid Phone Number',
                    },
                  ]}
                >
                  <MaskedInput mask={phoneMask} type='blue' />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  name='email'
                  label='Email'
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      required: true,
                      message: 'Email is required!!',
                    },
                  ]}
                >
                  <Input type='blue' />
                </FormItem>
              </Col>
              {withDefWrapper && (
                <Col span={24}>
                  <FormItem
                    name='subject'
                    label='Subject'
                    rules={[
                      {
                        required: true,
                        message: 'Subject is required!!',
                      },
                    ]}
                  >
                    <Input type='blue' />
                  </FormItem>
                </Col>
              )}
              <Col span={24}>
                <FormItem
                  name='message'
                  label='Message'
                  rules={[
                    {
                      validator: () => {
                        if (messageRef.current?.getContent()?.length) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Message is required!'));
                      },
                      message: 'Message is required!',
                    },
                  ]}
                >
                  <TextEditor
                    data={`
                        ${
                          user?.broker?.FullName
                            ? `<p style="margin:0px;">${user?.broker?.FullName}</p>`
                            : ''
                        }${
                      user?.broker?.BrokerAgency_id?.AgencyName
                        ? `<p style="margin:0px;">${user?.broker?.BrokerAgency_id?.AgencyName}</p>`
                        : ''
                    }${
                      user?.broker?.Email
                        ? `<p style="margin:0px;">E: ${user?.broker?.Email}</p>`
                        : ''
                    }${
                      user?.broker?.Phone
                        ? `<p style="margin:0px;">P: ${user?.broker?.Phone}</p>`
                        : ''
                    }
                      `}
                    editorRef={messageRef}
                    extraProps={{ content_style: 'body {font-size:14px } p { margin: 0; }' }}
                  />
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Row>
      </ModalContent>
    </Wrapper>
  );
};

export default EmailModal;
