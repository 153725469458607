import client from './main-client';

export const fetchTeamUsersApi = params => client().get('/sa-user-teams', { params });
export const fetchTeamUsersCountApi = params => client().get('/sa-user-teams/count', { params });
export const fetchOneTeamUserApi = ({ id, ...params }) =>
  client().get(`/sa-user-teams/${id}`, { params });
export const updateTeamUserApi = params =>
  client().put(`/sa-user-teams/${params.id}`, params.values);
export const addTeamUserApi = data => client().post('/sa-user-teams', data);
export const deleteTeamUserApi = params =>
  client().delete(`/sa-user-teams/${params.id}`, { params });
