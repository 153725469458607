/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const salesDataSlice = createSlice({
  name: 'salesData',
  initialState: {
    data: null,
    loading: false,
    Project_id: null,
  },
  reducers: {
    saveSalesData(state, { payload }) {
      state.data = payload;
      state.Project_id = payload?.Project_id?.id;
      localStorage.setItem('sales_data', payload?.id);
    },
    changeSalesData(state, { payload }) {
      state.data = { ...state.data, ...payload };
      state.Project_id = { ...state.data, ...payload }?.Project_id?.id;
    },
    changeSalesProject(state, { payload }) {
      state.Project_id = payload;
    },
    changeSalesLoading(state, { payload }) {
      state.loading = payload;
    },
  },
});

export const { saveSalesData, changeSalesData, changeSalesLoading, changeSalesProject } =
  salesDataSlice.actions;
export default salesDataSlice.reducer;
