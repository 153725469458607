import styled, { css } from 'styled-components';
import { Input as input } from 'antd';
import { darkBlue, mainColor } from '../../../constants/colors';

const Input = styled(input)`
  background: #fff;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  //border-radius: 50px;
  //height: 44px;
  height: 35px;
  padding: 0 15px;
  font-weight: 300;

  &:hover {
    border-color: ${mainColor};
  }
  &:focus,
  &.ant-input-focused {
    border-color: ${mainColor};
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px ${mainColor}2e;
  }

  &[disabled] {
    color: #00000096;
    background-color: #fff;
    border: 1px solid #eeeeee;
  }
  ${props =>
    props.type === 'blue' &&
    css`
      border: 1px solid #c9c9c9 !important;
      &:hover {
        border-color: ${mainColor} !important;
      }
      &:focus,
      &.ant-input-focused {
        border-color: ${mainColor} !important;
        box-shadow: none;
      }
      ${props.addonBefore &&
      css`
        padding: 0;
        border: none !important;

        .ant-input {
          height: 35px;
          &:hover {
            border-color: ${mainColor} !important;
          }
          &:focus,
          &.ant-input-focused {
            border-color: ${mainColor} !important;
            box-shadow: none;
          }
        }
      `}
    `}
  ${props =>
    props.custom_suffix === 'dark_blue' &&
    css`
      &:focus-within,
      &:hover {
        & .ant-input-affix-wrapper {
          border: 1px solid ${darkBlue} !important;
        }

        & .ant-input-suffix {
          border-left: 1px solid ${darkBlue} !important;
        }
        & .ant-input-wrapper > .ant-input-group-addon {
          border: 1px solid ${darkBlue} !important;
          border-right: none !important;
        }

        &:has(> input) {
          border: 1px solid ${darkBlue} !important;
        }
        & input {
          border-top: 1px solid ${darkBlue} !important;
          border-bottom: 1px solid ${darkBlue} !important;
        }
      }

      &.ant-input-affix-wrapper,
      & .ant-input-affix-wrapper {
        padding: 0 !important;
        height: 25px !important;

        & .ant-input-suffix {
          padding: 0 !important;
          width: 30px;
          margin-left: 0;
          height: 100%;

          & .ant-input-group-addon {
            border: none;
            padding: 0 !important;
            width: 100%;
            height: 100%;
          }
          & .ant-input-group-addon > span {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        & input {
          padding: 0 8px !important;
          font-weight: 500 !important;
          font-size: 12px !important;
          align-items: center;
          height: 23px !important;
          border: 1px solid #d9d9d9;
          border-right: none;
          border-left: none;
        }
      }

      & .ant-input-group-addon > span {
        width: 20px;
        height: 19px;

        & svg {
          width: 14px;
          height: 14px;
        }
      }

      & .ant-input-suffix {
        color: rgba(89, 89, 89, 1) !important;
        font-size: 13px;
        padding: 0 10px !important;
        border-left: 1px solid #eeeeee;

        & .ant-input-group-addon:after {
          border: none !important;
        }
      }
    `}

  ${props =>
    props.custom_suffix === 'true' &&
    css`
      &:focus-within,
      &:hover {
        & .ant-input-affix-wrapper {
          border: 1px solid rgb(0, 75, 220) !important;
        }

        & .ant-input-suffix {
          border-left: 1px solid rgb(0, 75, 220) !important;
        }
        & .ant-input-wrapper > .ant-input-group-addon {
          border: 1px solid rgb(0, 75, 220) !important;
          border-right: none !important;
        }

        &:has(> input) {
          border: 1px solid rgb(0, 75, 220) !important;
        }
      }

      &.ant-input-group-wrapper-status-error .ant-input-group-addon,
      &.ant-input-group-wrapper-status-error .ant-input-suffix {
        border-color: #ff4d4f !important;
      }

      &.ant-input-affix-wrapper,
      & .ant-input-affix-wrapper {
        padding: 0 !important;
        height: 25px !important;

        & .ant-input-suffix {
          padding: 0 !important;
          width: 30px;
          margin-left: 0;
          height: 100%;

          & .ant-input-group-addon {
            border: none;
            padding: 0 !important;
            width: 100%;
            height: 100%;
          }
          & .ant-input-group-addon > span {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        & input {
          padding: 0 8px !important;
          font-weight: 500 !important;
          font-size: 12px !important;
          align-items: center;
          height: 23px !important;
        }
      }

      & .ant-input-group-addon > span {
        width: 20px;
        height: 19px;

        & svg {
          width: 14px;
          height: 14px;
        }
      }

      & .ant-input-suffix {
        color: rgba(89, 89, 89, 1) !important;
        font-size: 13px;
        padding: 0 10px !important;
        border-left: 1px solid #eeeeee;

        & .ant-input-group-addon:after {
          border: none !important;
        }
      }
    `}

  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px!important;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}

    ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${props =>
    props.padding &&
    css`
      padding: ${props.padding}!important;
    `}
  ${props =>
    props.input_padding &&
    css`
      input {
        padding: ${props.input_padding}!important;
      }
    `}
  ${props =>
    props.input_height &&
    css`
      input {
        height: ${props.input_height}!important;
      }
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width}!important;
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height}!important;
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  
  ${props =>
    props.disabled_cursor &&
    css`
      &[disabled] {
        cursor: ${props.disabled_cursor};
      }
    `}
  ${props =>
    props.disabled_color &&
    css`
      & input[disabled],
      &[disabled] {
        color: ${props.disabled_color}!important;
      }
    `}
  ${props =>
    props.input_disabled_color &&
    css`
      & input[disabled] {
        color: ${props.input_disabled_color}!important;
      }
    `}
  
  ${props =>
    props.fz &&
    css`
      input {
        font-size: ${props.fz};
      }
    `}
  ${props =>
    props.fw &&
    css`
      input {
        font-weight: ${props.fw};
      }
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color}!important;
    `}
  ${props =>
    props.placeholder_color &&
    css`
      input {
        &::placeholder {
          color: ${props.placeholder_color};
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: ${props.placeholder_color};
        }

        &::-ms-input-placeholder {
          color: ${props.placeholder_color};
        }
      }
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
  ${props =>
    props.text_align &&
    css`
      text-align: ${props.text_align};
    `}
`;

export default Input;
