import { useSelector } from 'react-redux';
import { useGetAppSettingsQuery } from 'app/services/app-settings';
import { liteMainColor, mainColor } from 'constants/colors';
import { rgba } from 'Utils/Helpers';
import { getUserRole } from '../../app/services/selector-helpers';

const types = {
  admin: 'APP_ADMIN_COLOR_THEME',
  brokers: 'APP_BROKER_COLOR_THEME',
  decorselection: 'APP_DECOR_COLOR_THEME',
};

const checkValidColor = color => {
  const e = document.createElement('div');
  e.style.borderColor = '';
  e.style.borderColor = color;

  return !(e.style.borderColor.length === 0) && color;
};

const useGetMainColor = type => {
  const role = useSelector(getUserRole);
  const { data: settings } = useGetAppSettingsQuery();

  const colorNames = {
    aqua: '#00ffff',
    black: '#000000',
    blue: '#0000ff',
    fuchsia: '#ff00ff',
    gray: '#808080',
    green: '#008000',
    lime: '#00ff00',
    maroon: '#800000',
    navy: '#000080',
    olive: '#808000',
    purple: '#800080',
    red: '#ff0000',
    silver: '#c0c0c0',
    teal: '#008080',
    white: '#ffffff',
    yellow: '#ffff00',
  };

  const activeColor = checkValidColor(
    colorNames[settings?.[types?.[role]]] || settings?.[types?.[role]]
  );

  return activeColor ? [activeColor, rgba(activeColor, 0.1)] : [mainColor, liteMainColor];
};

export default useGetMainColor;
