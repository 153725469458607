/* eslint-disable import/no-cycle */
import { forwardRef } from 'react';
import { MailOutlined } from '@ant-design/icons';
import { Input } from '../index';

const InputEmail = forwardRef((props, ref) => (
  <Input
    addonBefore={'@'}
    suffix={
      <a className='ant-input-group-addon' href={`mailto:${props?.value}`}>
        <MailOutlined />
      </a>
    }
    custom_suffix='true'
    ref={ref}
    {...props}
  />
));
export default InputEmail;
