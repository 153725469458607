import {
  BellFilled,
  CalendarOutlined,
  HomeFilled,
  MailFilled,
  SettingFilled,
} from '@ant-design/icons';
import CaseIcon from '../assets/images/custom-icons/CaseIcon';
import ProjectsIcon from '../assets/images/custom-icons/ProjectsIcon';
import CRMIcon from '../assets/images/custom-icons/CRMIcon';
import DocsIcon from '../assets/images/custom-icons/DocsIcon';

export const protectedRouts = {
  brokers: {
    dashboard: {
      pageTitle: 'Dashboard',
      key: 'dashboard',
      pathname: '/',
      icon: <HomeFilled />,
      component: 'pages/dashboard-pages/dashboard.page',
    },
    allocation: {
      pageTitle: 'Worksheets',
      menuTitle: 'Worksheets',
      key: 'allocation',
      pathname: '/allocations',
      icon: <CaseIcon className='case_icon' />,
      component: 'pages/dashboard-pages/brokers/allocation-requests.page',
    },
    // documents: {
    //   pageTitle: 'Documents',
    //   key: 'documents',
    //   pathname: '/documents',
    //   icon: <DocsIcon style={{ color: 'black' }} />,
    //   component: 'pages/dashboard-pages/brokers/documents.page',
    // },
    // reminders: {
    //   pageTitle: 'Reminders',
    //   key: 'reminders',
    //   pathname: '/reminders',
    //   icon: <BellFilled />,
    //   component: 'pages/dashboard-pages/brokers/reminders.page',
    // },
    // messages: {
    //   pageTitle: 'Messages',
    //   key: 'messages',
    //   pathname: '/messages',
    //   icon: <MailFilled />,
    //   component: 'pages/dashboard-pages/brokers/messages.page',
    // },
    // settings: {
    //   key: 'settings',
    //   pageTitle: 'Settings',
    //   icon: <SettingFilled />,
    //   pathname: '/settings',
    //   component: 'pages/dashboard-pages/brokers/profile.page',
    //   items: {
    //     profile: {
    //       subKey: 'settings',
    //       pageTitle: 'Profile',
    //       key: 'profile',
    //       pathname: '/profile',
    //       component: 'pages/dashboard-pages/brokers/profile.page',
    //     },
    //   },
    // },
    // projects: {
    //   pageTitle: 'Projects',
    //   key: 'projects',
    //   icon: <CaseIcon className='case_icon' />,
    //   pathname: '/projects',
    //   component: 'pages/dashboard-pages/brokers/projects.page',
    // },
    projectsSingle: {
      pageTitle: 'Projects',
      key: 'projects_single',
      pathname: '/projects/:projectId',
      hideInList: true,
      model: 'units',
      component: 'pages/dashboard-pages/brokers/projects-single.page',
    },
    // my_sales: {
    //   pageTitle: 'My sales',
    //   key: 'my_sales',
    //   icon: <CaseIcon className='case_icon' />,
    //   pathname: '/my-sales',
    //   component: 'pages/dashboard-pages/brokers/my-sales.page',
    // },
    redirect: {
      pageTitle: 'Redirecting',
      key: 'redirect',
      pathname: '/redirect',
      component: 'pages/dashboard-pages/redirect.page',
      hideInSidebar: true,
      hideInList: true,
    },
  },
  client: {
    dashboard: {
      pageTitle: 'Dashboard',
      key: 'dashboard',
      icon: <HomeFilled />,
      pathname: '/',
      component: 'pages/dashboard-pages/dashboard.page',
    },
    messages: {
      pageTitle: 'Messages',
      key: 'messages',
      icon: <MailFilled />,
      pathname: '/messages',
      component: 'pages/dashboard-pages/client/messages.page',
    },
    messagesChat: {
      pageTitle: 'Message Chat',
      key: 'message_chat',
      hideInSidebar: true,
      pathname: '/messages/:type',
      component: 'pages/dashboard-pages/client/message-chat.page',
    },
    purchases: {
      pageTitle: 'My purchases',
      key: 'purchases',
      icon: <CaseIcon className='case_icon' />,
      pathname: '/my-purchases',
      component: 'pages/dashboard-pages/client/my-purchases.page',
    },
    settings: {
      key: 'settings',
      pageTitle: 'Settings',
      icon: <SettingFilled />,
      pathname: '/settings',
      component: 'pages/dashboard-pages/client/profile.page',
      items: {
        profile: {
          subKey: 'settings',
          pageTitle: 'Profile',
          key: 'profile',
          pathname: '/profile',
          component: 'pages/dashboard-pages/client/profile.page',
        },
      },
    },
    investment_opportunities: {
      pageTitle: 'Investment Opportunities',
      key: 'investment_opportunities',
      icon: <CaseIcon className='case_icon' />,
      pathname: '/investment-opportunities',
      component: 'pages/dashboard-pages/client/investment-opportunities.page',
    },
    opportunitiesSingle: {
      pageTitle: 'Investment Opportunities',
      key: 'opportunities_single',
      icon: <CaseIcon className='case_icon' />,
      pathname: '/investment-opportunities/:projectId',
      hideInSidebar: true,
      component: 'pages/dashboard-pages/client/opportunities-single.page',
    },
    redirect: {
      pageTitle: 'Redirecting',
      key: 'redirect',
      pathname: '/redirect',
      component: 'pages/dashboard-pages/redirect.page',
      hideInSidebar: true,
      hideInList: true,
    },
  },
  sale_office: {
    unitsDashboard: {
      key: 'unitsDashboard',
      pathname: '/',
      component: 'pages/dashboard-pages/sales-office/units-dashboard.page',
    },
    selectUnits: {
      key: 'selectUnits',
      pathname: '/select-units',
      component: 'pages/dashboard-pages/sales-office/select-units.page',
    },
    purchasers: {
      key: 'purchasers',
      pathname: '/purchasers',
      component: 'pages/dashboard-pages/sales-office/purchasers.page',
    },
    addOns: {
      key: 'add-ons',
      pathname: '/add-ons',
      component: 'pages/dashboard-pages/sales-office/add-ons.page',
    },
    adjustments: {
      key: 'adjustments',
      pathname: '/adjustments',
      component: 'pages/dashboard-pages/sales-office/adjustments.page',
    },
    deposits: {
      key: 'deposits',
      pathname: '/deposits',
      component: 'pages/dashboard-pages/sales-office/deposits.page',
    },
    criticalDates: {
      key: 'criticalDates',
      pathname: '/critical-dates',
      component: 'pages/dashboard-pages/sales-office/critical-dates.page',
    },
    coBroker: {
      key: 'coBroker',
      pathname: '/co-broker',
      component: 'pages/dashboard-pages/sales-office/co-broker.page',
    },
    financing: {
      key: 'financing',
      pathname: '/financing',
      component: 'pages/dashboard-pages/sales-office/financing.page',
    },
    documents: {
      key: 'documents',
      pathname: '/documents',
      component: 'pages/dashboard-pages/sales-office/documents.page',
    },
    amendments: {
      key: 'amendments',
      pathname: '/amendments',
      component: 'pages/dashboard-pages/sales-office/amendments.page',
    },
    redirect: {
      pageTitle: 'Redirecting',
      key: 'redirect',
      pathname: '/redirect',
      component: 'pages/dashboard-pages/redirect.page',
      hideInSidebar: true,
      hideInList: true,
    },
  },
  admin_sale: {
    unitsDashboard: {
      key: 'unitsDashboard',
      pathname: '/',
      component: 'pages/dashboard-pages/sales-office/units-dashboard.page',
    },
    selectUnits: {
      key: 'selectUnits',
      pathname: '/select-units',
      component: 'pages/dashboard-pages/sales-office/select-units.page',
    },
    purchasers: {
      key: 'purchasers',
      pathname: '/purchasers',
      component: 'pages/dashboard-pages/sales-office/purchasers.page',
    },
    addOns: {
      key: 'add-ons',
      pathname: '/add-ons',
      component: 'pages/dashboard-pages/sales-office/add-ons.page',
    },
    adjustments: {
      key: 'adjustments',
      pathname: '/adjustments',
      component: 'pages/dashboard-pages/sales-office/adjustments.page',
    },
    deposits: {
      key: 'deposits',
      pathname: '/deposits',
      component: 'pages/dashboard-pages/sales-office/deposits.page',
    },
    criticalDates: {
      key: 'criticalDates',
      pathname: '/critical-dates',
      component: 'pages/dashboard-pages/sales-office/critical-dates.page',
    },
    coBroker: {
      key: 'coBroker',
      pathname: '/co-broker',
      component: 'pages/dashboard-pages/sales-office/co-broker.page',
    },
    financing: {
      key: 'financing',
      pathname: '/financing',
      component: 'pages/dashboard-pages/sales-office/financing.page',
    },
    documents: {
      key: 'documents',
      pathname: '/documents',
      component: 'pages/dashboard-pages/sales-office/documents.page',
    },
    amendments: {
      key: 'amendments',
      pathname: '/amendments',
      component: 'pages/dashboard-pages/sales-office/amendments.page',
    },
    redirect: {
      pageTitle: 'Redirecting',
      key: 'redirect',
      pathname: '/redirect',
      component: 'pages/dashboard-pages/redirect.page',
      hideInSidebar: true,
      hideInList: true,
    },
  },
  admin: {
    dashboard: {
      pageTitle: 'Dashboard',
      key: 'dashboard',
      icon: <HomeFilled />,
      pathname: '/',
      component: 'pages/dashboard-pages/admin/dashboard.page',
    },
    brokerRelations: {
      key: 'brokerRelations',
      pageTitle: 'Broker Relations',
      icon: <CaseIcon />,
      pathname: '/broker-relations',
      component: 'pages/dashboard-pages/admin/project-settings/brokers-worksheets.page',
      items: {
        projectWebAssets: {
          subKey: 'projectSettings',
          pageTitle: 'Project Web Assets',
          menuTitle: 'Project Web Assets',
          key: 'projectWebAssets',
          pathname: '/web-assets',
          component: 'pages/dashboard-pages/admin/project-settings/web-assets.page',
        },
        projectWorksheets: {
          subKey: 'brokerRelations',
          pageTitle: 'Project Worksheets',
          menuTitle: 'Project Worksheets',
          key: 'projectWorksheets',
          pathname: '/project-worksheets',
          component: 'pages/dashboard-pages/admin/project-settings/project-worksheets.page',
        },
        brokerWorksheets: {
          subKey: 'brokerRelations',
          pageTitle: 'Brokers Worksheets',
          key: 'brokerWorksheets',
          pathname: '/brokers-worksheets',
          component: 'pages/dashboard-pages/admin/project-settings/brokers-worksheets.page',
          exportKey: 'Broker Worksheets',
        },
        brokerManager: {
          subKey: 'brokerRelations',
          pageTitle: 'Project Broker Manager',
          menuTitle: 'Broker Management',
          key: 'brokerManager',
          pathname: '/broker-manager',
          component: 'pages/dashboard-pages/admin/project-settings/project-broker-manager.page',
        },
      },
    },
    projectSettings: {
      key: 'projectSettings',
      pageTitle: 'Project Settings',
      icon: <ProjectsIcon />,
      pathname: '/project-settings',
      component: 'pages/dashboard-pages/admin/project-settings/project-setup.page',
      items: {
        projectSetup: {
          subKey: 'projectSettings',
          pageTitle: 'Project Setup',
          key: 'projectSetup',
          pathname: '/project-setup',
          component: 'pages/dashboard-pages/admin/project-settings/project-setup.page',
        },
        projectSales: {
          subKey: 'projectSettings',
          pageTitle: 'Project Sales',
          key: 'projectSales',
          pathname: '/project-sales',
          component: 'pages/dashboard-pages/admin/project-settings/project-sales.page',
        },
        decorModelOptions: {
          subKey: 'projectSettings',
          pageTitle: 'Model Options',
          key: 'decorModelOptions',
          pathname: '/decor-model-options',
          component: 'pages/dashboard-pages/admin/project-settings/decor-model-options.page',
        },
        projectFinishes: {
          subKey: 'projectSettings',
          pageTitle: 'Project Finishes',
          key: 'projectFinishes',
          pathname: '/project-finishes',
          component: 'pages/dashboard-pages/admin/project-settings/project-finishes.page',
        },
        // floorPremiums: {
        //   subKey: 'projectSettings',
        //   pageTitle: 'Floor Premiums',
        //   key: 'floorPremiums',
        //   pathname: '/floor-premiums',
        //   component: 'pages/dashboard-pages/admin/project-settings/floor-premiums.page',
        // },
        modelsPlans: {
          subKey: 'projectSettings',
          pageTitle: 'Models & Plans',
          key: 'modelsPlans',
          pathname: '/models-plans',
          component: 'pages/dashboard-pages/admin/project-settings/models-plans.page',
        },
        unitsSuites: {
          subKey: 'projectSettings',
          pageTitle: 'Units & Suites',
          key: 'unitsSuites',
          pathname: '/units-suites',
          component: 'pages/dashboard-pages/admin/project-settings/units-suites.page',
        },
      },
    },
    crm: {
      key: 'crm',
      pageTitle: 'CRM Accounts',
      icon: <CRMIcon className='case_icon' />,
      pathname: '/crm',
      component: 'pages/dashboard-pages/admin/crm/builder-clients.page',
      items: {
        users: {
          subKey: 'crm',
          pageTitle: 'User Accounts',
          key: 'users',
          pathname: '/users',
          component: 'pages/dashboard-pages/admin/crm/users.page',
        },
        builderClients: {
          subKey: 'crm',
          pageTitle: 'Builder Clients',
          key: 'builderClients',
          pathname: '/builder-clients',
          component: 'pages/dashboard-pages/admin/crm/builder-clients.page',
        },
        brokerAgencies: {
          subKey: 'crm',
          pageTitle: 'Broker Agencies',
          key: 'brokerAgencyList',
          pathname: '/broker-agencies',
          component: 'pages/dashboard-pages/admin/crm/broker-agencies.page',
        },
        BrokerAgents: {
          subKey: 'crm',
          pageTitle: 'Broker Agents',
          key: 'BrokerAgents',
          pathname: '/broker-agents',
          component: 'pages/dashboard-pages/admin/crm/broker-agents.page',
        },
        leadsClients: {
          subKey: 'crm',
          pageTitle: 'Leads & Clients',
          key: 'leadsClients',
          pathname: '/leads-clients',
          component: 'pages/dashboard-pages/admin/crm/leads-clients.page',
        },
        client_contacts: {
          subKey: 'crm',
          pageTitle: 'Client Contacts',
          key: 'client_contacts',
          pathname: '/client-contacts',
          component: 'pages/dashboard-pages/admin/crm/client-contacts.page',
        },
        purchasers: {
          subKey: 'crm',
          pageTitle: 'Purchasers',
          key: 'purchasers',
          pathname: '/purchasers',
          component: 'pages/dashboard-pages/admin/crm/purchasers.page',
        },
        // professionals: {
        //   subKey: 'crm',
        //   pageTitle: 'Professionals',
        //   key: 'professionals',
        //   pathname: '/professionals',
        //   component: 'pages/dashboard-pages/admin/crm/professionals.page',
        // },
      },
    },
    optionsLibrary: {
      key: 'optionsLibrary',
      pageTitle: 'Options Library',
      icon: <CaseIcon />,
      pathname: '/options-library',
      component: 'pages/dashboard-pages/admin/options-library/decor-categories.page',
      items: {
        decorCategories: {
          subKey: 'optionsLibrary',
          pageTitle: 'Decor Categories',
          key: 'decorCategories',
          pathname: '/decor-categories',
          component: 'pages/dashboard-pages/admin/options-library/decor-categories.page',
        },
        selectionGroups: {
          subKey: 'optionsLibrary',
          pageTitle: 'Selection Groups',
          key: 'selectionGroups',
          pathname: '/selection-groups',
          component: 'pages/dashboard-pages/admin/options-library/selection-groups.page',
        },
        supplierFinishes: {
          subKey: 'optionsLibrary',
          pageTitle: 'Supplier Finishes',
          key: 'supplierFinishes',
          pathname: '/supplier-finishes',
          component: 'pages/dashboard-pages/admin/options-library/supplier-finishes.page',
        },
        optionsMaster: {
          subKey: 'optionsLibrary',
          pageTitle: 'Decor Products',
          key: 'decorProducts',
          pathname: '/decor-products',
          component: 'pages/dashboard-pages/admin/options-library/decor-products.page',
        },
        // parkingLocker: {
        //   subKey: 'optionsLibrary',
        //   pageTitle: 'Amenity',
        //   key: 'parkingLocker',
        //   pathname: '/parking-locker',
        //   component: 'pages/dashboard-pages/admin/options-library/parking-locker.page',
        // },
      },
    },
    systemMasters: {
      key: 'systemMasters',
      pageTitle: 'System Masters',
      icon: <CaseIcon />,
      pathname: '/system-masters',
      component: 'pages/dashboard-pages/admin/system-masters/room-areas.page',
      items: {
        roomAreas: {
          subKey: 'systemMasters',
          pageTitle: 'Room Areas',
          key: 'roomAreas',
          pathname: '/room-areas',
          component: 'pages/dashboard-pages/admin/system-masters/room-areas.page',
        },
        deposits: {
          subKey: 'systemMasters',
          pageTitle: 'Deposits',
          key: 'deposits',
          pathname: '/deposit-plans',
          component: 'pages/dashboard-pages/admin/system-masters/deposit-plans.page',
        },
        amenities: {
          subKey: 'systemMasters',
          pageTitle: 'Amenities',
          key: 'amenities',
          pathname: '/amenities',
          component: 'pages/dashboard-pages/admin/system-masters/amenities.page',
        },
        incentives: {
          subKey: 'systemMasters',
          pageTitle: 'Incentives',
          key: 'incentives',
          pathname: '/incentives',
          component: 'pages/dashboard-pages/admin/system-masters/incentives.page',
        },
        premiums: {
          subKey: 'systemMasters',
          pageTitle: 'Premiums',
          key: 'premiums',
          pathname: '/premiums',
          component: 'pages/dashboard-pages/admin/system-masters/premiums.page',
        },
        suppliers: {
          subKey: 'systemMasters',
          pageTitle: 'Suppliers',
          key: 'suppliers',
          pathname: '/suppliers',
          component: 'pages/dashboard-pages/admin/system-masters/suppliers.page',
        },
        picklistSets: {
          subKey: 'systemMasters',
          pageTitle: 'Picklists',
          key: 'picklistSets',
          pathname: '/picklist-sets',
          component: 'pages/dashboard-pages/admin/system-masters/picklist-sets.page',
        },
        websiteSettings: {
          subKey: 'brokerRelations',
          pageTitle: 'Portal Settings',
          menuTitle: 'Settings',
          key: 'Portal Settings',
          pathname: '/portal-settings',
          component: 'pages/dashboard-pages/admin/system-masters/website-settings.page',
        },
      },
    },
    redirect: {
      pageTitle: 'Redirecting',
      key: 'redirect',
      pathname: '/redirect',
      component: 'pages/dashboard-pages/redirect.page',
      hideInSidebar: true,
      hideInList: true,
    },
  },
  decorselection: {
    dashboard: {
      pageTitle: 'Dashboard',
      key: 'dashboard',
      icon: <HomeFilled />,
      pathname: '/',
      component: 'pages/dashboard-pages/decor/dashboard.page',
    },
    appointments: {
      pageTitle: 'Appointments',
      key: 'appointments',
      icon: <CalendarOutlined />,
      pathname: '/appointments',
      component: 'pages/dashboard-pages/decor/appointments.page',
    },
    documents: {
      pageTitle: 'Documents',
      key: 'documents',
      icon: <DocsIcon style={{ color: 'black' }} />,
      pathname: '/documents',
      component: 'pages/dashboard-pages/decor/documents.page',
    },
    documents_single: {
      pageTitle: 'Documents',
      key: 'documents_single',
      icon: <DocsIcon style={{ color: 'black' }} />,
      pathname: '/documents/:offerId/:docId',
      hideInList: true,
      component: 'pages/dashboard-pages/decor/documents.page',
    },
    documents_review: {
      pageTitle: 'Documents',
      key: 'documents_review',
      icon: <DocsIcon style={{ color: 'black' }} />,
      pathname: '/documents-review/:offerId/:docId',
      hideInList: true,
      component: 'pages/dashboard-pages/decor/documents-review.page',
    },
    reminders: {
      pageTitle: 'Reminders',
      key: 'reminders',
      icon: <BellFilled />,
      pathname: '/reminders',
      component: 'pages/dashboard-pages/decor/reminders.page',
    },
    messages: {
      pageTitle: 'Messages',
      key: 'messages',
      icon: <MailFilled />,
      pathname: '/messages',
      component: 'pages/dashboard-pages/decor/messages.page',
    },
  },
  redirect: {
    pageTitle: 'Redirecting',
    key: 'redirect',
    pathname: '/redirect',
    component: 'pages/dashboard-pages/redirect.page',
    hideInSidebar: true,
    hideInList: true,
  },
};

const routes = {
  notFound: {
    pathname: '/404',
    key: 'not_found',
    pageTitle: '404',
  },
  home: {
    pathname: '/',
    key: 'home',
    component: 'pages/home.page',
    pageTitle: 'Home',
  },
  signIn: {
    pathname: '/sign-in',
    key: 'sign-in',
    component: 'pages/auth-pages/auth-tabs.page',
    pageTitle: 'Sign In',
  },
  signUp: {
    pathname: '/sign-up',
    key: 'sign-up',
    component: 'pages/auth-pages/auth-tabs.page',
    pageTitle: 'Sign Up',
  },
  // thank: {
  //   pathname: '/thank-you',
  //   key: 'thank-you',
  //   component: 'pages/auth-pages/thank-you.page',
  //   pageTitle: 'Thank You',
  // },
  emailVerification: {
    pathname: '/email-verification',
    key: 'email-verification',
    component: 'pages/auth-pages/email-verification.page',
    pageTitle: 'Email Verification',
  },
  forgotPassword: {
    pathname: '/forgot-password',
    key: 'forgotPassword',
    component: 'pages/auth-pages/forgot-password.page',
    pageTitle: 'Forgot Password',
  },
  ResetPassword: {
    pathname: '/reset-password',
    key: 'resetPassword',
    component: 'pages/auth-pages/reset-password.page',
    pageTitle: 'Reset Password',
  },
};

export default routes;
