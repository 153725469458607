import styled, { css } from 'styled-components';
import { Divider as divider } from 'antd';

const Divider = styled(divider)`
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
      min-width: ${props.width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.b_width &&
    css`
      border-width: ${props.b_width};
    `}
  ${props =>
    props.back_color &&
    css`
      border-color: ${props.back_color};
    `}
`;

/** @component */
export default Divider;
