import { createCustomApi } from '../../configs/rtk-query';

export const basicInfoApi = createCustomApi({
  reducerPath: 'basicInfo',
  endpoints: builder => ({
    getBasicInfo: builder.query({
      query: params => ({
        url: '/basic-informations',
        params,
      }),
    }),
  }),
});

export const { useGetBasicInfoQuery } = basicInfoApi;
