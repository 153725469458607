/* eslint-disable import/no-cycle */
import { forwardRef } from 'react';
import ReactInputMask from 'react-input-mask';
import { Input } from '../index';

const InputMask = forwardRef((props, ref) => {
  return (
    <ReactInputMask {...props} value={props?.value ?? ''}>
      {inputProps => (
        <Input {...inputProps} ref={ref} disabled={props.disabled ? props.disabled : null} />
      )}
    </ReactInputMask>
  );
});

export default InputMask;
