const convertToOneLevel = (routes = {}) => {
  const value = Object.entries(routes);
  return value.reduce((prev, cur) => {
    if ('items' in cur[1]) {
      return {
        ...prev,
        ...(convertToOneLevel(cur[1]?.items) || {}),
      };
    }

    return {
      ...prev,
      [cur?.[0]]: {
        key: cur?.[1]?.key,
        pathname: cur?.[1]?.pathname,
        pageTitle: cur?.[1]?.pageTitle,
        menuTitle: cur?.[1]?.menuTitle,
        icon: cur?.[1]?.icon,
        component: cur?.[1]?.component,
        hideInSidebar: cur?.[1]?.hideInSidebar,
        subKey: cur?.[1]?.subKey,
      },
    };
  }, {});
};

export const getPageComponents = routes => {
  return convertToOneLevel(routes);
};
