import { useLocation } from 'react-router-dom';
import { protectedRouts } from 'constants/routes';

const convertToOneLevel = (routes = {}) => {
  const value = Object.entries(routes);
  return value.reduce((prev, cur) => {
    if ('items' in cur[1]) {
      return {
        ...prev,
        ...(convertToOneLevel(cur[1]?.items) || {}),
      };
    }
    return {
      ...prev,
      [cur?.[0]]: cur?.[1],
    };
  }, {});
};

const exportRouteModels = [
  { model: 'projects', pathname: '/project-setup' },
  { model: 'models', pathname: '/models-plans' },
  { model: 'units', pathname: '/units-suites' },
  { model: 'units', pathname: '/projects' },
  { model: 'project-decor-options', pathname: '/decor-model-options' },
  { model: 'project-decor-selects', pathname: '/project-finishes' },
  { model: 'pub-projects', pathname: '/web-assets' },
  { model: 're-broker-agency', pathname: '/broker-agencies' },
  { model: 're-broker-agents', pathname: '/broker-agents' },
  { model: 'rq-buyer-clients', pathname: '/leads-clients' },
  { model: 'sm-contacts', pathname: '/client-contacts' },
  { model: 'user', pathname: '/users' },
  { model: 'cx-sales-txn-ref', pathname: '/purchasers' },
  { model: 'rq-allocations', pathname: '/brokers-worksheets' },
  { model: 'rq-allocations', pathname: '/allocations' },
  { model: 'rq-allocations', pathname: '/project-worksheets' },
  { model: 'units', pathname: '/project-worksheets' },
  { model: 'project-broker-agents', pathname: '/broker-manager' },
  { model: 'dx-supp-selects', pathname: '/supplier-finishes' },
  { model: 'dc-products', pathname: '/decor-products' },
  { model: 'dc-categories', pathname: '/decor-categories' },
  { model: 'aps-sales-offer', pathname: '/project-sales' },
];

const useGetExportModelByUrl = modelKey => {
  const location = useLocation();

  const findRoute = Object.values(
    protectedRouts?.admin
      ? convertToOneLevel({ ...protectedRouts?.admin, ...protectedRouts?.brokers })
      : {}
  ).find(value => location.pathname.split('/')[1] === value?.pathname.split('/')[1]);
  const currentData = exportRouteModels.find(
    el =>
      location.pathname.split('/')[1] === el?.pathname.split('/')[1] &&
      el?.model &&
      (modelKey ? el?.model === modelKey : true)
  );

  return currentData ? { model: currentData?.model, ...findRoute } : null;
};

export default useGetExportModelByUrl;
