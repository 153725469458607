import { memo, useEffect, useState } from 'react';
import { Row, Col, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getUser } from '../app/services/selector-helpers';

const { Paragraph, Title } = Typography;

const NotFoundWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const NotFoundPage = ({ fallback }) => {
  const user = useSelector(getUser);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setShowLoading(false), 1000);
  }, []);

  if (showLoading) return fallback;

  return (
    <NotFoundWrapper>
      <Row>
        <Col>
          <div>
            <Title level={1}>404</Title>
            <Paragraph>{''}</Paragraph>
            <Paragraph>Requested page not Found</Paragraph>
            {!user?.id && 'You May need to login to view this page'}
            <Paragraph>
              <Link to='/'>Go to main page</Link>
            </Paragraph>
          </div>
        </Col>
      </Row>
    </NotFoundWrapper>
  );
};

export default memo(NotFoundPage);
