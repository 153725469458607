import Icon from '@ant-design/icons';

const ProjectsSVG = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.0547 5.94141C14.0547 6.77066 14.7293 7.44531 15.5586 7.44531H20.7578V20.6367C20.7578 21.9398 19.6976 23 18.3945 23H6.36328C5.06017 23 4 21.9398 4 20.6367V3.36328C4 2.06017 5.06017 1 6.36328 1H14.0547V5.94141ZM10.6481 16.4688H7.52344C7.16748 16.4688 6.87891 16.7573 6.87891 17.1133C6.87891 17.4692 7.16748 17.7578 7.52344 17.7578H10.6481C11.0041 17.7578 11.2927 17.4692 11.2927 17.1133C11.2927 16.7573 11.0041 16.4688 10.6481 16.4688ZM7.52344 13.0312C7.16748 13.0312 6.87891 13.3198 6.87891 13.6758C6.87891 14.0317 7.16748 14.3203 7.52344 14.3203H16.9766C17.3325 14.3203 17.6211 14.0317 17.6211 13.6758C17.6211 13.3198 17.3325 13.0312 16.9766 13.0312H7.52344ZM17.6211 10.2383C17.6211 9.88233 17.3325 9.59375 16.9766 9.59375H7.52344C7.16748 9.59375 6.87891 9.88233 6.87891 10.2383C6.87891 10.5942 7.16748 10.8828 7.52344 10.8828H16.9766C17.3325 10.8828 17.6211 10.5942 17.6211 10.2383ZM15.5586 6.15629C15.4401 6.15629 15.3438 6.05991 15.3438 5.94145H15.3438V1.26735C15.5356 1.36755 15.7147 1.49444 15.8755 1.64655L20.0187 5.56625C20.2009 5.73868 20.3526 5.93861 20.4705 6.15629H15.5586Z'
      fill='#717579'
    />
  </svg>
);

const ProjectsIcon = props => <Icon component={ProjectsSVG} {...props} />;

export default ProjectsIcon;
