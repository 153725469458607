import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CloseCircleOutlined,
  EditOutlined,
  LockOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Button, Col, Modal, ModalWrapper, Row, Switch, Tooltip } from 'components/atoms/index';
import { checkActiveIcon, checkHeaderIcon, defText } from 'Utils/Helpers';
import TableWrapper from 'components/organisms/table-wrapper';
import useGetMainColor from 'Utils/hooks/useGetMainColor';
import { mainColor } from 'constants/colors';
import {
  fetchTeamUsersApi,
  fetchTeamUsersCountApi,
  updateTeamUserApi,
} from 'services/sa-user-teams';
import useApiCall from 'Utils/hooks/useApiCall';
import { getUser } from 'app/services/selector-helpers';
import ModalContent from './ModalContent';
import TeamsPopup from './teams-popup';

const { confirm } = Modal;

const TeamsListPopup = ({ title, closeTableModal, subTitle }) => {
  const user = useSelector(getUser);
  const [activeColor] = useGetMainColor();
  const filterData = useRef({ _limit: 25, _start: 0 });
  const [editData, setEditData] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [triggerRequest, setTriggerRequest] = useState(false);
  const [teamUsers, teamUsersLoading, teamUsersCall, teamUsersCount] = useApiCall(
    fetchTeamUsersApi,
    fetchTeamUsersCountApi
  );
  const [, updateTeamUserLoading, updateTeamUserCall] = useApiCall(updateTeamUserApi);

  const getTeamUsersData = params => {
    teamUsersCall(
      {
        _sort: 'TeamUser_id.email',
        LeadUser_id: user?.id,
        ...params,
      },
      () => setTriggerRequest(false)
    );
  };

  const changeUserData = (id, values, text) => {
    confirm({
      title: `Are you sure you want to ${text} this user?`,
      icon: <ExclamationCircleOutlined />,
      cancelText: 'Cancel',
      okText: 'OK',
      maskClosable: true,
      okButtonProps: {
        style: { backgroundColor: activeColor },
      },
      onOk: () => updateTeamUserCall({ id, values }, () => setTriggerRequest(true)),
      onCancel() {},
    });
  };

  const closeModal = () => {
    setModalVisible(false);
    setEditData(null);
    setTriggerRequest(true);
  };

  const columns = [
    {
      title: 'User Name',
      dataIndex: ['TeamUser_id', 'username'],
      key: 'TeamUser_id.username',
      sorter: true,
      align: 'center',
      width: 200,
      render: item => defText(item, 'left'),
    },
    {
      title: 'Log-In Email',
      dataIndex: ['TeamUser_id', 'email'],
      key: 'TeamUser_id.email',
      sorter: true,
      align: 'center',
      render: item => defText(item, 'left'),
    },
    {
      title: 'Mobile',
      dataIndex: ['TeamUser_id', 'mobile'],
      key: 'TeamUser_id.mobile',
      sorter: true,
      width: 120,
      align: 'center',
      render: item => defText(item, 'left'),
    },
    {
      title: 'Phone',
      dataIndex: ['TeamUser_id', 'phone'],
      key: 'TeamUser_id.phone',
      sorter: true,
      width: 120,
      align: 'center',
      render: item => defText(item, 'left'),
    },
    {
      title: (
        <Tooltip title='Locked'>
          <LockOutlined />
        </Tooltip>
      ),
      dataIndex: ['TeamUser_id', 'blocked'],
      key: 'TeamUser_id.blocked',
      width: 80,
      align: 'center',
      render: (locked, data) => (
        <Switch
          size='small'
          checked={locked}
          switched_color={activeColor}
          onChange={() =>
            changeUserData(
              data?.id,
              {
                userParams: { id: data?.TeamUser_id?.id, blocked: !locked },
              },
              !locked ? 'lock' : 'unlock'
            )
          }
        />
      ),
    },
    {
      title: <Tooltip title='Active'>{checkActiveIcon(true)}</Tooltip>,
      dataIndex: ['TeamUser_id', 'confirmed'],
      key: 'TeamUser_id.confirmed',
      width: 60,
      align: 'center',
      render: (inactive, values) => (
        <Col
          width='20px'
          height='20px'
          border='1px solid #eeeeee'
          cursor='pointer'
          align='center'
          justify='center'
          margin='0 auto'
          onClick={() =>
            changeUserData(
              values?.id,
              {
                userParams: { id: values?.TeamUser_id?.id, confirmed: !inactive },
              },
              inactive ? 'active' : 'deactivate'
            )
          }
        >
          {checkActiveIcon(!inactive)}
        </Col>
      ),
    },
    {
      title: () => checkHeaderIcon('Action'),
      dataIndex: '',
      key: 'Action',
      width: 60,
      align: 'center',
      render: (_, data) => (
        <EditOutlined
          style={{ color: mainColor }}
          onClick={() => {
            setEditData({
              ...(data?.TeamUser_id || {}),
              team_id: data?.TeamUser_id?.id,
              id: data?.id,
              LeadUserName: data?.LeadUser_id?.LegalFullName,
              ActiveEffDate: data?.ActiveEffDate,
            });
            setModalVisible(true);
          }}
        />
      ),
    },
  ];

  const filterContent = (
    <Row gutter={0} justify='end' mb={10}>
      <Button
        type='primary'
        padding='0 25px'
        active_color={activeColor}
        onClick={() => setModalVisible(true)}
      >
        + Team User
      </Button>
    </Row>
  );

  const footer = (
    <Row justify='end' align='middle'>
      <Button
        type='light_blue'
        onClick={closeTableModal}
        icon={<CloseCircleOutlined />}
        ml='10px'
        color={activeColor}
      >
        CANCEL
      </Button>
    </Row>
  );

  return (
    <>
      {(modalVisible || editData) && (
        <TeamsPopup
          closeModal={closeModal}
          editData={editData}
          setEditData={setEditData}
          subTitle={editData?.LeadUserName}
          title='My Team User'
          setTriggerRequest={setTriggerRequest}
        />
      )}
      <ModalWrapper closeModal={closeTableModal}>
        <ModalContent
          customMinWidth='500px'
          customMaxWidth='1000px'
          type='blue'
          title={title}
          subTitle={subTitle}
          closeModal={closeTableModal}
          footer={footer}
          modalProps={{ title_back_color: activeColor }}
        >
          <div style={{ padding: '10px 20px 20px' }}>
            <TableWrapper
              dataSource={teamUsers}
              columns={columns}
              loading={updateTeamUserLoading || teamUsersLoading}
              total={teamUsersCount}
              filterContent={filterContent}
              filterData={filterData.current}
              getTableData={getTeamUsersData}
              triggerRequest={triggerRequest}
              footer={true}
              tableExtraProps={{ pagination: false, scroll: { y: 250 } }}
              selectable={false}
            />
          </div>
        </ModalContent>
      </ModalWrapper>
    </>
  );
};

export default TeamsListPopup;
