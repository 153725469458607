import styled, { css } from 'styled-components';
// eslint-disable-next-line import/no-cycle
import { mainColor, mainTextColor } from 'constants/colors';

const PaginationWrapper = styled.div`
  height: 40px;
  background: #e9e9e9;
  align-items: center;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;

  ul {
    display: flex;
    align-items: center;
    margin: 0;
  }

  .ant-pagination-total-text {
    order: 2;
    margin-left: 20px;
  }
  .ant-table-pagination-right .ant-pagination-total-text {
    margin-right: 0px;
  }
  .ant-pagination-item-link {
    border-radius: 2px !important;
  }
  .ant-pagination-next,
  .ant-pagination-prev {
    width: 28px;
    min-width: 28px;
    max-width: 28px;
    height: 28px;
  }
  .ant-pagination-item {
    background-color: #0050ff1a !important;
    border: 1px solid ${mainColor} !important;
    width: 28px;
    min-width: 28px;
    max-width: 28px;
    height: 28px;

    a {
      color: ${mainTextColor};
      font-size: 12px;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover,
    &.ant-pagination-item-active {
      background-color: ${mainColor} !important;

      a {
        color: #fff;
      }
    }
  }

  .ant-pagination-item-ellipsis,
  .ant-pagination-item-link-icon {
    color: ${mainTextColor} !important;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    button {
      width: 28px;
      min-width: 28px;
      max-width: 28px;
      height: 28px;
      background-color: transparent;
      border-radius: 50%;
      border: 1px solid ${mainColor};
      color: ${mainTextColor};
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.ant-pagination-disabled,
    &.ant-pagination-disabled:hover {
      button {
        color: rgba(0, 0, 0, 0.25);
        border-color: #d9d9d9;
        background-color: transparent;
      }
    }

    &:hover {
      button {
        background-color: ${mainColor};
        border-radius: 50%;
        border-color: ${mainColor};
        color: #fff;
      }
    }
  }
  .ant-pagination-options {
    .ant-select-selector {
      height: 28px;
      font-size: 12px;

      .ant-select-selection-item {
        line-height: 25px;
      }
    }
  }
  ${props =>
    props.active_color &&
    css`
      .ant-pagination-item.ant-pagination-item-active,
      .ant-pagination-item:hover,
      .ant-pagination-next:not(.ant-pagination-disabled):hover button,
      .ant-pagination-prev:not(.ant-pagination-disabled):hover button {
        background-color: ${props.active_color} !important;
        border: 1px solid ${props.active_color};
        &,
        a,
        path {
          color: white !important;
        }
      }
      .ant-pagination-item,
      .ant-pagination-next:not(.ant-pagination-disabled),
      .ant-pagination-prev:not(.ant-pagination-disabled) {
        background-color: transparent !important;
        border: 1px solid ${props.active_color} !important;
        &,
        a,
        path {
          color: ${props.active_color} !important;
        }
        .ant-pagination-item-link {
          border: none !important;
        }
      }
      .ant-pagination-item-ellipsis {
        color: ${props.active_color} !important;
      }
    `}
  ${props =>
    props.pagination_margin &&
    css`
      margin: ${props.pagination_margin};
    `}
  ${props =>
    props.pagination_padding &&
    css`
      padding: ${props.pagination_padding};
    `}
    ${props =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}
`;
export default PaginationWrapper;
