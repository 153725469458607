import Icon from '@ant-design/icons';

const FacebookSvg = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 0C3.13401 0 0 3.13401 0 7V17C0 20.866 3.13401 24 7 24H17C20.866 24 24 20.866 24 17V7C24 3.13401 20.866 0 17 0H7ZM13.8467 13.3473V22H10.6539V13.341H8V9.95316H10.6481V7.46694C10.6481 4.57531 12.2657 3.00018 14.6218 3.00018C15.4162 2.99679 16.2101 3.04155 17 3.13426V6.15467H15.3766C14.0954 6.15467 13.8467 6.824 13.8467 7.8016V9.95949H16.9084L16.5122 13.3473H13.8467Z'
      fill='currentColor'
    />
  </svg>
);

const FacebookIcon = props => <Icon component={FacebookSvg} {...props} />;

export default FacebookIcon;
