import { useSelector } from 'react-redux';
import { getUserRole } from '../../app/services/selector-helpers';
import { useGetBasicInfoQuery } from '../../app/services/basic-information';

const useGetBasicInfosByType = type => {
  const role = useSelector(getUserRole);
  const {
    data = [],
    refetch,
    isFetching,
  } = useGetBasicInfoQuery({
    _sort: ['sale_office', 'admin_sale'].includes(role) ? 'value' : 'SeqNo,value',
    _limit: 1000,
  });

  const selectedInfos = type ? data.filter(el => el?.type === type) : data;

  return [selectedInfos, refetch, isFetching];
};

export default useGetBasicInfosByType;
