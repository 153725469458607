import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CloseCircleOutlined,
  SaveOutlined,
  MenuOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';
import { notification, Spin } from 'antd';
import {
  Button,
  Col,
  Form,
  FormItem,
  Input,
  InputEmail,
  InputPassword,
  MaskedInput,
  ModalWrapper,
  Row,
  Select,
} from 'components/atoms/index';
import {
  defFormLayout,
  phoneMask,
  phonePattern,
  postalMask,
  postalPattern,
  provinceCityList,
} from 'constants/etc';
import { changePasswordApi, updateUsersApi } from 'services/users';
import ModalContent from 'components/molecules/ModalContent';
import { fetchBrokerApi } from 'services/re-broker-agents';
import useApiCall from 'Utils/hooks/useApiCall';
import ModalContentUpload from 'components/molecules/ModalContentUpload';
import {
  fetchAllocBuyersClientApi,
  updateAllocBuyersClientApi,
} from 'services/allocation-requests';
import useGetMainColor from 'Utils/hooks/useGetMainColor';
import { filterOption, setAllOnClear } from '../../Utils/Helpers';
import allCities from '../../constants/city-province.json';
import { changeUserData, removeUser } from '../../app/slices/user';
import { getUser } from '../../app/services/selector-helpers';

const ProfilePopup = ({ closeModal }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const user = useSelector(getUser);
  const [activeColor] = useGetMainColor();
  const clickedSave = useRef(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [changeLoading, setChangeLoading] = useState(false);
  const [currentProvince, setCurrentProvince] = useState(null);
  const [passwordDisabled, setPasswordDisabled] = useState(true);
  const [allocBuyersClient, allocBuyersClientLoading, allocBuyersClientCall] =
    useApiCall(fetchAllocBuyersClientApi);
  const [agencies, agenciesLoading, agenciesCall] = useApiCall(fetchBrokerApi);
  const [, updateUserLoading, updateUserCall] = useApiCall(updateUsersApi);

  const cityList = currentProvince ? provinceCityList[currentProvince] || [] : allCities;

  useEffect(() => {
    form.setFieldsValue({
      ...(user?.additionalQuestions || {}),
      ...user,
      postalCode: user?.postalCode ?? '',
      mobile: user?.mobile ?? '',
      phone: user?.phone ?? '',
      BrokerAgency_id: user?.broker?.BrokerAgency_id?.id,
    });
    setUploadedFile(user?.avatar);
    user?.role?.type === 'brokers' && agenciesCall({ _sort: 'AgencyName', _limit: 1000 });

    user?.role?.type === 'client' &&
      allocBuyersClientCall(
        {
          PortalUser_id: user?.id,
        },
        values => form.setFieldValue('CompanyName', values?.[0]?.CompanyName)
      );
  }, []);

  const onFinish = async () => {
    const values = await form.validateFields([
      'currentPassword',
      'newPassword',
      'confirmNewPassword',
    ]);
    setChangeLoading(true);
    changePasswordApi({
      ...values,
    })
      .then(() => {
        setChangeLoading(false);
        notification.success({
          message: 'Password successfully changed!',
          duration: 3,
        });
        form.resetFields(['currentPassword', 'newPassword', 'confirmNewPassword']);
        dispatch(removeUser());
      })
      .catch(({ response }) => {
        setChangeLoading(false);
        notification.error({
          message: response?.data?.message,
          duration: 3,
        });
        form.resetFields(['currentPassword', 'newPassword', 'confirmNewPassword']);
      });
  };

  const updateProfile = async ({
    currentPassword,
    newPassword,
    confirmNewPassword,
    email,
    CompanyName,
    ...signUpData
  } = {}) => {
    const values = {
      ...signUpData,
      username: `${signUpData?.firstName ? signUpData?.firstName.trim() : ''}.${
        signUpData?.lastName ? signUpData?.lastName.trim() : ''
      }`.toLowerCase(),
      email: email.toLowerCase(),
      avatar: uploadedFile,
      FullName: `${signUpData?.firstName ? signUpData?.firstName.trim() : ''} ${
        signUpData?.lastName ? signUpData?.lastName.trim() : ''
      }`,
      LegalFullName: `${signUpData?.firstName ? signUpData?.firstName.trim() : ''} ${
        signUpData?.lastName ? signUpData?.lastName.trim() : ''
      }`,
      ...(user?.role?.type === 'brokers'
        ? {
            reBrokerAgents: {
              BrokerAgency_id: signUpData?.BrokerAgency_id,
            },
          }
        : {}),
    };
    clickedSave.current = true;

    if (user?.role?.type === 'client') {
      await updateAllocBuyersClientApi({
        id: allocBuyersClient?.[0]?.id,
        values: { CompanyName },
      });
    }
    updateUserCall({ id: user?.id, values }, res => {
      dispatch(changeUserData({ ...user, ...res }));
      notification.success({
        message: 'Profile updated',
        duration: 3,
      });
      closeModal();
    });
  };

  const handleCityChange = val => {
    const cityData = allCities.find(el => el.city === val);
    form.setFieldsValue({ provinceState: cityData?.province_id });
    setTimeout(() => {
      handleProvinceChange(cityData?.province_id);
    }, 10);
  };

  const handleProvinceChange = val => {
    setCurrentProvince(val);
    if (!provinceCityList[val]?.find(el => el.city === form.getFieldValue('city'))) {
      form.setFieldsValue({ city: null });
    }
  };

  const submitForm = () => {
    form.submit();
    !passwordDisabled && onFinish();
  };

  const footer = (
    <Spin spinning={updateUserLoading || changeLoading}>
      <Row justify='space-between' align='middle'>
        <Button
          type='light_blue'
          onClick={closeModal}
          icon={<CloseCircleOutlined />}
          ml='10px'
          color={activeColor}
        >
          CANCEL
        </Button>
        <Button
          fw={500}
          type='light_blue'
          onClick={submitForm}
          icon={<SaveOutlined />}
          color={activeColor}
        >
          UPDATE
        </Button>
      </Row>
    </Spin>
  );

  return (
    <ModalWrapper closeModal={closeModal} showConfirm={true} isEdit={true}>
      <ModalContent
        customWidth='690px'
        type='blue'
        title='My Profile'
        subTitle={user?.username}
        closeModal={closeModal}
        showConfirm={true}
        isEdit={true}
        titlePadding='10px 30px'
        footer={footer}
      >
        <Row padding='15px'>
          <Spin spinning={updateUserLoading}>
            <Form
              form={form}
              mb={15}
              onFinish={updateProfile}
              type={user?.role?.type === 'brokers' ? 'black_popup' : 'blue'}
              field_height='30px'
              label_max_height='32px'
              label_height='32px'
              label_align_item='center'
              active_color={activeColor}
              {...defFormLayout}
            >
              <Row gutter={[20, 5]} justify='space-between'>
                <Col sm={16} span={24}>
                  <Row gutter={[20, 5]} margin='0 0 5px !important'>
                    <Col span={12}>
                      <FormItem
                        name='firstName'
                        label='First Name'
                        rules={[
                          {
                            required: true,
                            message: 'Please input your First Name!',
                          },
                        ]}
                        mb={0}
                      >
                        <Input />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem
                        name='lastName'
                        label='Last Name'
                        rules={[
                          {
                            required: true,
                            message: 'Please input your Last Name!',
                          },
                        ]}
                        mb={0}
                      >
                        <Input />
                      </FormItem>
                    </Col>
                    <Col span={24}>
                      <FormItem name='email' label='Email address' mb={0}>
                        <InputEmail disabled={true} disabled_color='#000000d9' />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem
                        name='mobile'
                        label='Mobile number'
                        rules={[
                          {
                            pattern: phonePattern,
                            message: 'Please input valid Phone Number',
                          },
                          {
                            required: true,
                            message: 'Please input your Mobile Number!',
                          },
                        ]}
                        mb={0}
                      >
                        <MaskedInput mask={phoneMask} addonBefore={<MenuOutlined />} />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem
                        name='phone'
                        label='Phone number'
                        rules={[
                          {
                            pattern: phonePattern,
                            message: 'Please input valid Phone Number',
                          },
                        ]}
                        mb={0}
                      >
                        <MaskedInput mask={phoneMask} addonBefore={<MenuOutlined />} />
                      </FormItem>
                    </Col>
                    {user?.role?.type === 'brokers' && (
                      <Col span={24}>
                        <Spin spinning={agenciesLoading}>
                          <FormItem
                            name='BrokerAgency_id'
                            label='Brokerage'
                            rules={[
                              {
                                required: true,
                                message: 'Input Brokerage!',
                              },
                            ]}
                            mb={0}
                          >
                            <Select
                              showSearch={true}
                              allowClear={true}
                              type='sale'
                              onClear={() => setAllOnClear(form, 'BrokerAgency_id', null)}
                            >
                              {agencies.map(el => (
                                <Select.Option key={el.id} value={el.id}>
                                  {el?.AgencyName}
                                </Select.Option>
                              ))}
                            </Select>
                          </FormItem>
                        </Spin>
                      </Col>
                    )}
                    {['admin', 'decorselection', 'sales'].includes(
                      user?.role?.type.toLowerCase()
                    ) && (
                      <Col span={24}>
                        <FormItem name='JobTitle' label='Job Title' mb={0}>
                          <Input disabled={true} />
                        </FormItem>
                      </Col>
                    )}
                    {user?.role?.type.toLowerCase() === 'client' && (
                      <Col span={24}>
                        <Spin spinning={allocBuyersClientLoading}>
                          <FormItem name='CompanyName' label='Company Name' mb={0}>
                            <Input disabled={!allocBuyersClient?.length} />
                          </FormItem>
                        </Spin>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col sm={8} span={24}>
                  <Row gutter={[30, 5]} margin='10px 0 0 5px !important'>
                    <ModalContentUpload
                      uploadedFile={uploadedFile}
                      setUploadedFile={setUploadedFile}
                      icons={<></>}
                      mainSpan={24}
                      title=''
                      desc='Add a logo picture of the User'
                      imageSize={100}
                      uploadProps={
                        user?.role?.type === 'brokers'
                          ? { type: 'light_blue', color: activeColor }
                          : {}
                      }
                    />
                    {user?.role?.type === 'brokers' && (
                      <Col span={24}>
                        <FormItem
                          name='recoNumber'
                          label='RECO Number'
                          rules={[
                            {
                              required: true,
                              message: 'RECO Number is required!',
                            },
                          ]}
                          mb={0}
                        >
                          <Input />
                        </FormItem>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col span={24} order={6}>
                  <Row gutter={[20, 5]}>
                    <Col sm={16} span={24}>
                      <Row
                        gutter={[20, 5]}
                        border='1px solid #eeeeee'
                        radius='3px'
                        padding='0 0 5px'
                        margin='0 0 5px !important'
                      >
                        <Col span={24}>
                          <FormItem name='address1' label='Address (No, Street, Avenue)' mb={0}>
                            <Input prefix={<EnvironmentOutlined />} />
                          </FormItem>
                        </Col>
                        <Col span={24}>
                          <FormItem name='address2' label='Address (Apt, Unit, Suite)' mb={0}>
                            <Input prefix={<EnvironmentOutlined />} />
                          </FormItem>
                        </Col>
                        <Col span={24} sm={12}>
                          <FormItem name='city' label='City' mb={0}>
                            <Select
                              showSearch={true}
                              filterOption={filterOption}
                              allowClear={true}
                              onChange={handleCityChange}
                              type='sale'
                              onClear={() => setAllOnClear(form, 'city', null)}
                            >
                              {cityList.map(el => (
                                <Select.Option value={el.city} key={el.city}>
                                  {el.city}
                                </Select.Option>
                              ))}
                            </Select>
                          </FormItem>
                        </Col>
                        <Col span={24} sm={12}>
                          <FormItem name='provinceState' label='Province | State' mb={0}>
                            <Select
                              showSearch={true}
                              filterOption={filterOption}
                              allowClear={true}
                              onChange={handleProvinceChange}
                              type='sale'
                              onClear={() => setAllOnClear(form, 'provinceState', null)}
                            >
                              {Object.keys(provinceCityList).map(el => (
                                <Select.Option value={el} key={el}>
                                  {el}
                                </Select.Option>
                              ))}
                            </Select>
                          </FormItem>
                        </Col>
                        <Col span={24} sm={12}>
                          <FormItem
                            name='postalCode'
                            label='Postal code | Zip'
                            rules={[
                              {
                                pattern: postalPattern,
                                message: 'Please input valid Postal Code',
                              },
                            ]}
                            mb={0}
                          >
                            <MaskedInput mask={postalMask} />
                          </FormItem>
                        </Col>
                        <Col span={24} sm={12}>
                          <FormItem name='country' label='Country' mb={0}>
                            <Select
                              allowClear={true}
                              type='sale'
                              onClear={() => setAllOnClear(form, 'Country', null)}
                            >
                              {['Canada'].map(el => (
                                <Select.Option value={el} key={el}>
                                  {el}
                                </Select.Option>
                              ))}
                            </Select>
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={8} span={24}>
                      <Row
                        gutter={[20, 5]}
                        border='1px solid #eeeeee'
                        radius='3px'
                        padding='30px 0 5px'
                        margin='0 0 0 5px !important'
                      >
                        <Col span={24}>
                          <Button
                            width='100%'
                            fz='12px'
                            height='30px'
                            {...(user?.role?.type === 'brokers'
                              ? { type: 'light_blue', color: activeColor }
                              : { type: 'blue', mb: 3 })}
                            decoration='underline'
                            onClick={() => setPasswordDisabled(false)}
                          >
                            CHANGE PASSWORD
                          </Button>
                        </Col>
                        <Col span={24}>
                          <FormItem
                            name='currentPassword'
                            label='Current password'
                            rules={[
                              {
                                required: !passwordDisabled,
                                message: 'Fill Password!',
                              },
                            ]}
                            mb={0}
                          >
                            <InputPassword disabled={passwordDisabled} />
                          </FormItem>
                        </Col>
                        <Col span={24}>
                          <FormItem
                            name='newPassword'
                            label='New password'
                            rules={[{ required: !passwordDisabled, message: 'Fill new Password!' }]}
                            mb={0}
                          >
                            <InputPassword disabled={passwordDisabled} />
                          </FormItem>
                        </Col>
                        <Col span={24}>
                          <FormItem
                            name='confirmNewPassword'
                            label='Confirm password'
                            dependencies={['newPassword']}
                            rules={[
                              {
                                required: !passwordDisabled,
                                message: 'Confirm password!',
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(new Error('Password Mismatch!'));
                                },
                              }),
                            ]}
                            mb={0}
                          >
                            <InputPassword disabled={passwordDisabled} />
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Row>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ProfilePopup;
