import Icon from '@ant-design/icons';

const TwitterSvg = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 0C3.13401 0 0 3.13401 0 7V17C0 20.866 3.13401 24 7 24H17C20.866 24 24 20.866 24 17V7C24 3.13401 20.866 0 17 0H7ZM18.9972 7.2166C19.7033 7.1309 20.3748 6.94128 21 6.66066L20.9985 6.66293C20.5305 7.37201 19.9416 7.99097 19.2593 8.49081C19.266 8.6425 19.2698 8.79571 19.2698 8.9474C19.2698 13.6165 15.7621 19 9.34572 19C7.45027 19.0021 5.59434 18.451 4 17.4125C4.27633 17.4455 4.55432 17.4619 4.83256 17.4618C6.40324 17.4642 7.92906 16.9312 9.16454 15.9487C8.43674 15.9352 7.73133 15.6915 7.14706 15.2516C6.5628 14.8118 6.12893 14.1979 5.90619 13.4959C6.42904 13.5968 6.96769 13.5761 7.48146 13.4352C6.69201 13.2736 5.98206 12.8403 5.47208 12.2088C4.9621 11.5773 4.68351 10.7865 4.68356 9.97056V9.92505C5.16788 10.1978 5.70949 10.3494 6.26332 10.3672C5.78577 10.0442 5.39432 9.60667 5.12371 9.09358C4.85309 8.58049 4.71168 8.00767 4.71201 7.42594C4.71201 6.77973 4.88422 6.17297 5.1837 5.64963C6.05956 6.74117 7.1522 7.63395 8.3907 8.27003C9.6292 8.90611 10.9859 9.27126 12.3727 9.3418C12.1972 8.58391 12.2735 7.78852 12.59 7.0791C12.9064 6.36968 13.4451 5.78594 14.1226 5.41849C14.8001 5.05105 15.5784 4.92047 16.3366 5.04702C17.0948 5.17357 17.7906 5.55017 18.3159 6.11836C19.0971 5.96302 19.8461 5.67314 20.5306 5.2613C20.2707 6.08003 19.7257 6.77503 18.9972 7.2166Z'
      fill='currentColor'
    />
  </svg>
);

const TwitterIcon = props => <Icon component={TwitterSvg} {...props} />;

export default TwitterIcon;
