import styled, { css } from 'styled-components';
import { InputNumber as inputNumber } from 'antd';
import { mainColor } from '../../../constants/colors';

const InputNumber = styled(inputNumber)`
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  //border-radius: 50px;
  //height: 44px;
  height: 35px;
  padding: 0 15px;
  font-weight: 300;

  &:hover {
    border-color: ${mainColor};
  }
  &:focus,
  &.ant-input-focused {
    border-color: ${mainColor};
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px ${mainColor}2e;
  }

  .ant-input-number-input-wrap {
    height: 100%;

    .ant-input-number-input {
      height: 100%;
      padding: 0;
    }
  }

  &.ant-input-number-affix-wrapper {
    width: 100%;
  }

  &[disabled] {
    color: #00000096;
    background-color: #fff;
    border: 1px solid #eeeeee;
  }

  &.ant-input-number-affix-wrapper-disabled {
    .ant-input-number-prefix {
      color: #00000096;
    }
  }

  & input[disabled] {
    color: #00000096;
  }

  ${props =>
    props.color_type === 'blue' &&
    css`
      border: 1px solid #c9c9c9 !important;
      &:hover {
        border-color: ${mainColor} !important;
      }
      &:focus,
      &.ant-input-focused {
        border-color: ${mainColor} !important;
        box-shadow: none;
      }
      //border: 1px solid ${mainColor} !important;
      //&.ant-input-number-disabled {
      //  color: ${mainColor};
      //  background-color: #fff;
      //  border: 1px solid ${mainColor} !important;
      //}
    `}
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px!important;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding}!important;
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width}!important;
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height}!important;
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
  ${props =>
    props.fz &&
    css`
      font-size: ${props.fz};
    `}
  ${props =>
    props.text_align &&
    css`
      text-align: ${props.text_align};

      input {
        text-align: ${props.text_align};
      }
    `}
  ${props =>
    props.prefix_color &&
    css`
      .ant-input-number-prefix {
        color: ${props.prefix_color};
      }
    `}
  ${props =>
    props.text_align &&
    css`
      input {
        text-align: ${props.text_align};
      }
    `}
   ${props =>
    props.show_controls &&
    css`
      & {
        padding-right: 0px !important;
      }
      & input {
        padding-right: 25px !important;
      }
      .ant-input-number-handler-wrap {
        opacity: 1;
      }
    `}
  ${props =>
    props.disabled_color &&
    css`
      input[disabled] {
        color: ${props.disabled_color};
      }
      &.ant-input-number-affix-wrapper-disabled {
        .ant-input-number-prefix {
          color: ${props.disabled_color};
        }
      }
    `}
  ${props =>
    props.custom_prefix &&
    css`
      padding-left: 0px !important;

      & .ant-input-number-prefix {
        height: 100%;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white !important;
        background: #989898;
      }
    `}
  ${props =>
    props.input_height &&
    css`
      .ant-input-number {
        height: ${props.input_height} !important;
      }
    `}
  ${props =>
    props.input_border &&
    css`
      .ant-input-number {
        border: ${props.input_border}!important;
      }
    `}
  ${props =>
    props.input_padding &&
    css`
      &.ant-input-number {
        padding: ${props.input_padding}!important;
      }
    `}
  ${props =>
    props.hide === 'controls' &&
    css`
      padding: 0 10px;
      .ant-input-number-handler-wrap {
        display: none;
      }
    `}
`;

export default InputNumber;
