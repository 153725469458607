import axios from 'axios';
import { notification } from 'antd';
import { getAccessToken, emptyState, loadUserData } from '../configs/local-storage';
import { API_ROOT } from '../configs/env-vars';

// eslint-disable-next-line import/no-anonymous-default-export
export default (headers = {}) => {
  const user = loadUserData();
  const service = axios.create({
    baseURL: API_ROOT, // url of the api
    headers: {
      Authorization: getAccessToken() ? 'Bearer '.concat(getAccessToken()) : '',
      ...headers,
    },
  });
  service.interceptors.response.use(
    response => response,
    error => {
      const errorResponse = error.response;
      if (errorResponse.status === 401) {
        emptyState();
        window.location.pathname = '/sign-in';
      }
      if (
        errorResponse.status === 400 &&
        ['sale_office', 'admin_sale', 'admin'].includes(user?.role?.type)
      ) {
        const errors = errorResponse?.data?.data?.errors || {};
        const description =
          Object.values(errors)
            ?.flatMap(val => val)
            ?.join(', ') ||
          errorResponse?.data?.data?.[0]?.messages?.[0]?.message ||
          errorResponse?.data?.message ||
          'Something went wrong!';
        notification.error({ description });
      }
      if (errorResponse.status === 500 && user?.role?.type === 'admin') {
        notification.error({ description: 'Something went wrong!' });
      }
      throw error;
    }
  );
  return service;
};
