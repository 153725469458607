/* eslint-disable import/no-cycle */
import { useState } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
// import useGetMainColor from 'Utils/hooks/useGetMainColor';
import { Form } from '../index';

const ModalWrapperComp = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(8, 26, 51, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;
const modalRoot = document.getElementById('modal-root');

const ModalWrapper = ({ children, closeModal, showConfirm, isEdit }) => {
  // const [activeColor] = useGetMainColor();
  const [formChanged, setFormChanged] = useState(false);

  const onFormChange = () => {
    if (!formChanged) {
      setFormChanged(true);
    }
  };

  // const handleWrapperClick = () => {
  //   if (showConfirm && formChanged) {
  //     confirm({
  //       title: 'Are you sure you want to leave this form?',
  //       icon: <ExclamationCircleOutlined />,
  //       content: 'All changes will be lost!',
  //       cancelText: 'Cancel',
  //       okText: 'OK',
  //       maskClosable: true,
  //       okButtonProps: { style: { backgroundColor: activeColor || mainColor } },
  //       onOk() {
  //         closeModal();
  //       },
  //       onCancel() {},
  //     });
  //   } else {
  //     closeModal();
  //   }
  // };

  // const handleClick = event => {
  //   if (event.target === event.currentTarget) {
  //     event.stopPropagation();
  //     handleWrapperClick();
  //   }
  // };

  return ReactDOM.createPortal(
    <ModalWrapperComp
    // onMouseDownCapture={handleClick}
    // id='modal-wrapper'
    // onClick={e => e.target.id === 'modal-wrapper' && handleWrapperClick()}
    >
      <Form.Provider onFormChange={onFormChange}>{children}</Form.Provider>
    </ModalWrapperComp>,
    modalRoot
  );
};

export default ModalWrapper;
