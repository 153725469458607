import styled, { css } from 'styled-components';
import { Layout as layout } from 'antd';

const Header = styled(layout.Header)`
  height: auto;
  line-height: initial;
  ${props =>
    props.back_color &&
    css`
      background-color: ${props.back_color};
    `}
  ${props =>
    props.border_bottom &&
    css`
      border-bottom: ${props.border_bottom} !important;
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.shadow &&
    css`
      box-shadow: ${props.shadow};
    `}
    ${props =>
    props.overflow &&
    css`
      overflow: ${props.overflow};
    `}
  ${props =>
    props.type === 'top_sticky' &&
    css`
      position: sticky;
      top: 0;
      left: 0;
      z-index: 999;
    `}
`;

export default Header;
