import 'antd/dist/antd.min.css';
import './assets/global.scss';
import 'react-phone-input-2/lib/style.css';
import RouteList from './RouteList';

const App = () => {
  return <RouteList />;
};

export default App;
