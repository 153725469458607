import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Pagination } from 'antd';
import { checkMultipleText } from 'Utils/Helpers';
import { Paragraph, Table, Row, PaginationWrapper } from 'components/atoms/index';
import useGetMainColor from 'Utils/hooks/useGetMainColor';
import { mainColor } from 'constants/colors';
import useGetExportModelByUrl from 'Utils/hooks/useGetExportModelByUrl';
import { sortDirectionKeys } from '../../constants/etc';

export const FilterContentWrapper = styled.div`
  width: 100%;
  margin-bottom: 3px;

  & .ant-form-item-required {
    font-size: 12px !important;
  }
  & .ant-input,
  & .ant-select-selector,
  & .ant-picker {
    height: 30px !important;
  }
  & .ant-select-selection-item,
  & .ant-input,
  & input {
    font-size: 12px !important;
    text-align: initial;
  }
  & .ant-form-item-label {
    height: 22px;
    & label {
      height: 12px;
      margin-bottom: 0;
    }
  }
  & .ant-btn {
    height: 25px !important;
  }
`;

const Footer = styled.div`
  height: 55px;
  width: 100%;
  border: 1px solid #c8c8c8;
  background-color: #e9e9e9;
  margin-top: 5px;
`;

export const convertSortList = list =>
  list
    .map(item =>
      item?.order
        ? `${(item?.column?.sortKey || item?.column?.key)
            .split(',')
            .map(el => `${el}:${sortDirectionKeys[item?.order] || ''}`)
            .join()}`
        : ''
    )
    .join();

const checkSorter = (cols = [], data = []) =>
  cols.map(el => ({
    ...el,
    sorter: data.length ? el?.sorter : false,
  }));

const TableWrapper = ({
  columns = [],
  loading = false,
  total = 0,
  dataSource = [],
  tableExtraProps = {},
  filterContent = '',
  filterData = {},
  getTableData = () => {},
  triggerRequest = false,
  isLocalSort = false,
  footer,
  footerContent,
  restHeight,
  paginationPosition,
  extraComponent,
  selectable = true,
  selectedRows = [],
  setSelectedRows = () => {},
  selectWidth = 40,
  forceShowCheckbox = false,
  modelKey,
  showFooter = true,
  selectFullItem = false,
}) => {
  const exportModel = useGetExportModelByUrl(modelKey);
  const defPaginationData = { size: tableExtraProps?.pagination === false ? 1000 : 25, number: 1 };
  const [activeColor, liteActiveColor] = useGetMainColor();
  const [sortData, setSortData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [paginationData, setPaginationData] = useState(defPaginationData);

  useEffect(() => {
    checkSorter();
  }, [dataSource]);

  useEffect(() => {
    convertedRequest(paginationData);
  }, [sortData, filterData]);

  useEffect(() => {
    if (triggerRequest) {
      convertedRequest(paginationData);
    }
  }, [triggerRequest]);

  useEffect(() => {
    if (paginationData?.number !== 1) {
      setPaginationData({ ...paginationData, number: 1 });
    }
    if (selectable && !selectable?.required) setSelectedRows([]);
  }, [filterData]);

  const convertedRequest = ({ size, number }) => {
    let params = { _start: 0, _limit: 0 };

    if (Object.keys(filterData)[0]) {
      params = {
        _limit: size,
        _start: number * size - size,
      };
      if (convertSortList(sortData)) {
        // eslint-disable-next-line no-underscore-dangle
        params._sort = convertSortList(sortData);
      }
      Object.keys(filterData).forEach(key => {
        if (filterData[key]) {
          params[key] = filterData[key];
        }
      });
    }
    getTableData(params);
  };

  const handleSortChange = data => {
    !isLocalSort && setSortData(data.length ? data : [data]);
  };

  const onRow = (_, index) => {
    return {
      onClick: e => {
        setActiveIndex(index);
      },
    };
  };

  const rowClassName = (_, index) => (activeIndex === index ? 'active_units_row' : '');

  return (
    <>
      <FilterContentWrapper className='filter_wrapper'>{filterContent}</FilterContentWrapper>
      <Table
        columns={checkSorter(columns, dataSource)}
        extra='pagination'
        onChange={(a, b, data) => handleSortChange(data)}
        expand_back='#fff'
        rowKey={record => record?.[selectable?.key || 'id']}
        loading={loading}
        bordered={true}
        cell_padding='5px 10px'
        footer={() =>
          footer && (
            <Row padding='4px 15px' justify='end' align='middle' back_color='#f5f5f5' height='40px'>
              <Paragraph
                mb={0}
                type='primary'
                fz={14}
                color={activeColor || tableExtraProps?.footer_color}
              >
                {`${dataSource.length ? total : 0} ${checkMultipleText(
                  dataSource.length ? total : 0,
                  'item'
                )}`}
              </Paragraph>
            </Row>
          )
        }
        rowSelection={
          (exportModel || selectable?.required) &&
          selectable && {
            type: 'checkbox',
            preserveSelectedRowKeys: true,
            columnWidth: selectWidth || 40,
            selectedRowKeys:
              // eslint-disable-next-line no-nested-ternary
              selectedRows[0] === 'All'
                ? dataSource?.map(el => el?.[selectable?.key || 'id'])
                : selectFullItem
                ? selectedRows?.map(el => el?.[selectable?.key || 'id'])
                : selectedRows,
            onSelectAll: checked => {
              setSelectedRows(checked ? ['All'] : [], 'isAll');
            },
            onSelect: (object, selected, selectedItems) => {
              if (selectFullItem) {
                setSelectedRows(selectedItems);
              } else {
                setSelectedRows(
                  selected
                    ? [...selectedRows, object?.[selectable?.key || 'id']]
                    : selectedRows.filter(item => item !== object?.[selectable?.key || 'id']),
                  object
                );
              }
            },
            getCheckboxProps: record => ({
              name: record?.[selectable?.key || 'id'],
              disabled: selectable?.disabled?.(record),
            }),
          }
        }
        onRow={onRow}
        rowClassName={rowClassName}
        scroll={restHeight && { y: `calc(100vh - ${restHeight})` }}
        height={tableExtraProps?.scroll?.y}
        active_color={activeColor}
        active_lite_color={liteActiveColor}
        {...tableExtraProps}
        pagination={false}
        dataSource={dataSource}
      />
      {!(tableExtraProps.pagination === false) && (
        <div className='pagination-wrapper'>
          <PaginationWrapper
            justify={paginationPosition}
            active_color={activeColor}
            lite_active_color={liteActiveColor}
            {...tableExtraProps}
          >
            {extraComponent}
            <Pagination
              defaultPageSize={25}
              showSizeChanger={true}
              pageSizeOptions={[15, 25, 50, 100]}
              pageSize={paginationData.size}
              hideOnSinglePage={!dataSource.length}
              current={paginationData?.number}
              onChange={(number, size) => {
                convertedRequest({ size, number });
                setPaginationData({ size, number });
              }}
              total={dataSource.length ? total : 0}
              showTotal={(totals, current) => (
                <>
                  <Paragraph
                    fw={400}
                    fz={16}
                    mb={0}
                    white_space='nowrap'
                    color={activeColor || mainColor}
                  >
                    {total || 0} items
                  </Paragraph>
                </>
              )}
              {...tableExtraProps}
            />
          </PaginationWrapper>
          {showFooter && <Footer>{footerContent}</Footer>}
        </div>
      )}
    </>
  );
};

export default TableWrapper;
