import styled, { css } from 'styled-components';

const SubTableWrapper = styled.div`
  ${props =>
    props.position === 'fixed' &&
    css`
      height: 100%;
      position: relative;
      & .ant-spin-nested-loading,
      & .ant-spin-container,
      & .ant-table {
        position: unset;
      }
      & .pagination-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    `}
  ${props =>
    props.active_color &&
    css`
      & .ant-checkbox-checked .ant-checkbox-inner,
      & .ant-checkbox-indeterminate .ant-checkbox-inner::after,
      & .ant-switch-checked {
        background-color: ${props.active_color} !important;
      }

      .ant-radio-checked .ant-radio-inner {
        border-color: ${props.active_color} !important;
        &::after {
          background-color: ${props.active_color} !important;
        }
      }
      & input:focus,
      & input:hover,
      & textarea:focus,
      & textarea:hover,
      & .ant-radio:hover .ant-radio-inner,
      & .ant-input-affix-wrapper:focus,
      & .ant-input-affix-wrapper:hover,
      & .ant-picker:focus,
      & .ant-picker:hover,
      & .ant-input-number:focus,
      & .ant-input-number:hover,
      & .ant-select-selector:focus,
      & .ant-input-number-affix-wrapper:hover,
      & .ant-input-number-affix-wrapper:focus,
      &
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector,
      & .ant-select-selector:hover {
        border-color: ${props.active_color} !important;
      }
      .ant-form-item:focus-within .ant-form-item-label > label {
        color: ${props.active_color} !important;

        &:before {
          color: ${props.active_color} !important;
        }
      }
      .ant-btn:not(.select-action):not([disabled]) {
        background-color: ${props.active_color} !important;
        color: white !important;
      }
    `}
`;

export default SubTableWrapper;
