import styled, { css } from 'styled-components';
import { DatePicker as datepicker } from 'antd';
import { mainColor } from '../../../constants/colors';

const DatePicker = styled(datepicker)`
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  //border-radius: 50px;
  //height: 44px;
  height: 35px;
  padding: 0 15px;
  font-weight: 300;

  &:hover {
    border-color: ${mainColor};
  }
  &:focus,
  &.ant-input-focused {
    border-color: ${mainColor};
    border-right-width: 1px !important;
    outline: none;
    box-shadow: 0 0 0 2px ${mainColor}2e;
  }
  &.ant-picker-status-error.ant-picker:not([disabled]) {
    border-color: #ff4d4f !important;

    & .ant-picker-suffix {
      border-right-color: #ff4d4f !important;
    }
  }
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px!important;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}

    ${props =>
    props.icon_left &&
    css`
      display: flex;
      height: 30px;
      width: 100%;
      padding: 0 10px 0 0;
      & .ant-picker-input {
        height: 100%;
        & .ant-picker-suffix {
          display: flex;
          background: #f2f2f2;
          border-right: 1px solid #c0bfbf;
          margin-left: 0;
          margin-right: 10px;
          order: -1;
          height: 100%;
          align-items: center;
          justify-content: center;
        }
      }
    `}
    ${props =>
    props.icon_right &&
    css`
      height: 30px;
      width: 100%;
      padding: 0 0 0 10px;
      & .ant-picker-input {
        height: 100%;
        display: grid;
        grid-template-columns: 8fr 1fr;
        & .ant-picker-suffix {
          display: flex;
          background: #f2f2f2;
          border-left: 1px solid #c0bfbf;
          margin-right: 0 !important;
          order: 1;
          height: 100%;
          align-items: center;
          justify-content: center;
        }
      }
      & .ant-picker-clear {
        right: 10px !important;
        background: #f2f2f2 !important;
      }
    `}
  ${props =>
    props.text_align &&
    css`
      input {
        text-align: ${props.text_align};
      }
    `}
   ${props =>
    props.clear_opacity &&
    css`
      & .ant-picker-clear {
        opacity: ${props.clear_opacity} !important;
      }
    `}
   ${props =>
    props.clear_right &&
    css`
      & .ant-picker-clear {
        right: ${props.clear_right} !important;
      }
    `}
   ${props =>
    props.sales_clear &&
    css`
      & .ant-picker-clear {
        background: transparent !important;
        color: rgba(0, 0, 0, 0.25) !important;
      }
      & .ant-picker-input {
        display: flex !important;
        & .ant-picker-suffix {
          width: 23px !important;
        }
      }
    `}
  ${props =>
    props.input_pr &&
    css`
      input {
        padding-right: ${props.input_pr};
      }
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding}!important;
    `}
    ${props =>
    props.fz &&
    css`
      &.ant-picker .ant-picker-input input {
        font-size: ${props.fz}!important;
      }
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width}!important;
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height}!important;
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
  ${props =>
    props.disabled_color &&
    css`
      & input[disabled] {
        color: ${props.disabled_color}!important;
      }
    `}
  ${props =>
    props.type === 'blue' &&
    css`
      border: 1px solid #c9c9c9 !important;
      &:hover {
        border-color: ${mainColor} !important;
      }
      &:focus,
      &.ant-input-focused {
        border-color: ${mainColor} !important;
        box-shadow: none;
      }
    `}
`;

export default DatePicker;
