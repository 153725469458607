import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Spin } from 'antd';
import { HomeFilled } from '@ant-design/icons';
import { salesMainColor } from 'constants/colors';
import SalesInfoWrapper, { tabOptions } from 'components/organisms/sales-office/sales-info-wrapper';
import { protectedRouts } from 'constants/routes';
import { getSalesInfo, getSalesLoading, getUserRole } from '../../app/services/selector-helpers';
import { Content, Header, Layout, Steps, Col } from '../atoms';

const { Step } = Steps;

const SalesSubWrapper = styled.div`
  flex: auto;
  max-width: 1300px;
  width: -webkit-fill-available;
  margin: 0 auto;
  & .ant-layout {
    min-height: fit-content !important;
    height: 100%;
  }

  & .ant-layout-content {
    flex: auto;
  }

  & .content_spin,
  & .content_spin .ant-spin-container {
    display: flex;
    flex-direction: column;
    flex: auto;
  }
`;

const Footer = styled.div`
  height: fit-content;
  padding: 5px 15px;
  width: 100%;
  background: white;
  border-top: 3px solid #5a5a5a;
  display: flex;
  justify-content: space-between;

  .ant-col + .ant-col {
    margin-left: 10px;
  }

  & .ant-col {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    border-top: 1px solid #a4a4a4;
    border-bottom: 2px solid ${salesMainColor};
    color: #a5a5a5;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    padding: 5px 35px;
    &::before {
      display: none;
    }
  }

  & .ant-radio-button-wrapper-checked {
    border-top: 1px solid #0033cc !important;
    color: #0033cc !important;
    background: #d8d8d8;
  }
`;

const SalesWrapper = ({ children }) => {
  const role = useSelector(getUserRole);
  const navigate = useNavigate();
  const location = useLocation();
  const salesLoading = useSelector(getSalesLoading);
  const salesInfo = useSelector(getSalesInfo);
  const isDashboard = location.pathname === '/';

  const currentStep = Object.values(protectedRouts?.sale_office).findIndex(
    el => el?.pathname === location.pathname,
    0
  );

  const stepsItems = useMemo(
    () => [
      {
        title: 'HOME',
        icon: <HomeFilled style={{ fontSize: '14px' }} />,
        onClick: () => navigate('/'),
        style: { cursor: 'pointer' },
        disabled: role === 'admin_sale',
      },
      { title: 'SELECT UNIT', disabled: !salesInfo || isDashboard },
      {
        title: 'PURCHASERS',
        onClick: () => navigate('/purchasers'),
        style: { cursor: 'pointer' },
        disabled: !salesInfo || isDashboard,
      },
      {
        title: 'ADD-ONS',
        onClick: () => navigate('/add-ons'),
        style: { cursor: 'pointer' },
        disabled: !salesInfo || isDashboard,
      },
      {
        title: 'ADJUSTMENTS',
        onClick: () => navigate('/adjustments'),
        style: { cursor: 'pointer' },
        disabled: !salesInfo || isDashboard,
      },
      {
        title: 'DEPOSITS',
        onClick: () => navigate('/deposits'),
        style: { cursor: 'pointer' },
        disabled: !salesInfo || isDashboard,
      },
      {
        title: 'CRITICAL DATES',
        onClick: () => navigate('/critical-dates'),
        style: { cursor: 'pointer' },
        disabled: !salesInfo || isDashboard,
      },
      {
        title: 'FINANCIALS',
        onClick: () => navigate('/financing'),
        style: { cursor: 'pointer' },
        disabled: !salesInfo || isDashboard,
      },
      {
        title: 'DOCUMENTS',
        onClick: () => navigate('/documents'),
        style: { cursor: 'pointer' },
        disabled: !salesInfo || isDashboard,
      },
      {
        title: 'AMENDMENTS',
        onClick: () => navigate('/amendments'),
        style: { cursor: 'pointer' },
        disabled: !salesInfo || isDashboard,
      },
    ],
    [salesInfo, location.pathname]
  );

  return (
    <SalesSubWrapper>
      <Layout border='3px solid #5A5A5A' margin='3px'>
        <Header
          padding='5px'
          shadow='0 1px 3px #d9d9d9'
          border_bottom='3px solid #5A5A5A'
          back_color='white'
        >
          <Steps labelPlacement='vertical' current={currentStep} type='sales' overflow='scroll'>
            {stepsItems.map(item => (
              <Step
                {...(item?.disabled ? { disabled: true, title: item?.title } : item)}
                key={item.title}
              />
            ))}
          </Steps>
        </Header>
        <Content back_color='white' padding='10px' direction='column' display='flex'>
          {Object.values(tabOptions).includes(location.pathname) ? (
            <Spin spinning={salesLoading} wrapperClassName='content_spin'>
              <SalesInfoWrapper>{children}</SalesInfoWrapper>
            </Spin>
          ) : (
            <Spin spinning={salesLoading} wrapperClassName='content_spin'>
              {children}
            </Spin>
          )}
        </Content>
        <Footer>
          <Col>Projects</Col>
          <Col>Units</Col>
          <Col>Models</Col>
          <Col>Contacts</Col>
          <Col>Admin</Col>
        </Footer>
      </Layout>
    </SalesSubWrapper>
  );
};

export default memo(SalesWrapper);
