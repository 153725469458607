/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const decorSlice = createSlice({
  name: 'decor',
  initialState: {
    filters: null,
  },
  reducers: {
    setDecorFilters(state, { payload }) {
      state.filters = payload;
    },
  },
});

export const { setDecorFilters } = decorSlice.actions;
export default decorSlice.reducer;
