import { createCustomApi } from '../../configs/rtk-query';

export const appSettingsApi = createCustomApi({
  reducerPath: 'appSettings',
  endpoints: builder => ({
    getAppSettings: builder.query({
      query: () => ({
        url: '/custom/settings',
        params: { _limit: 1000 },
      }),
    }),
  }),
});

export const { useGetAppSettingsQuery } = appSettingsApi;
