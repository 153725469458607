import styled, { css } from 'styled-components';
import { Empty as empty } from 'antd';

const Empty = styled(empty)`
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.img_height &&
    css`
      .ant-empty-image {
        height: ${props.img_height};
      }
    `}
`;

export default Empty;
