import styled, { css } from 'styled-components';
import { Form } from 'antd';
import { mainTextColor } from '../../../constants/colors';

const FormItem = styled(Form.Item)`
  max-width: 100% !important;
  font-size: 14px !important;
  line-height: 18px !important;
  font-weight: 500 !important;
  padding: 0 !important;
  margin-bottom: 10px;

  .ant-form-item-label {
    padding: 0 0 3px 0 !important;
    max-height: 30px;
  }

  .ant-form-item-control-input {
    min-height: 20px;
  }

  .ant-form-item-label > label {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: #717579;
    margin-bottom: 8px;

    &:before {
      color: #717579 !important;
    }
  }

  &:focus-within .ant-form-item-label > label {
    color: ${mainTextColor};

    &:before {
      color: ${mainTextColor} !important;
    }
  }
  .ant-form-item-explain {
    position: absolute;
    bottom: 0px;
    height: 4px !important;
    min-height: 4px !important;
    text-align: right;
    right: 0;
  }

  ${props =>
    (props.label_mb || props.label_mb === 0) &&
    css`
      .ant-form-item-label > label {
        margin-bottom: ${props.label_mb}px;
      }
    `}
  ${props =>
    props.label_height &&
    css`
      label {
        height: ${props.label_height}!important;
      }
    `}
    ${props =>
    props.label_overflow &&
    css`
      .ant-form-item-label {
        overflow: ${props.label_overflow}!important;
      }
    `}
  ${props =>
    props.label_align &&
    css`
      .ant-form-item-label {
        text-align: ${props.label_align}!important;
      }
    `}
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px!important;
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.margin_right &&
    css`
      margin-right: ${props.margin_right}!important;
    `}
  ${props =>
    props.justify &&
    css`
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: ${props.justify}!important;
      }
    `}
    ${props =>
    props.flex &&
    css`
      .ant-form-item-control-input-content {
        flex: ${props.flex}!important;
      }
    `}
    ${props =>
    props.self_flex &&
    css`
      flex: ${props.self_flex}!important;
    `}
  ${props =>
    props.layout === 'vertical' &&
    css`
      div {
        width: 100%;
        text-align: start;
      }
    `}
  ${props =>
    props.labelcolor &&
    css`
      .ant-form-item-label > label {
        color: ${props.labelcolor};
      }
    `}
  ${props =>
    props.label_align &&
    css`
      .ant-form-item-label {
        text-align: ${props.label_align};
      }
      .ant-form-item-label label {
        justify-content: ${props.label_align};
      }
    `}
    ${props =>
    props.align &&
    css`
      align-items: ${props.align};
    `}
    ${props =>
    props.row_align &&
    css`
      & .ant-form-item-row {
        align-items: ${props.row_align} !important;
      }
    `}
  ${props =>
    props.content_just &&
    css`
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: ${props.content_just};
      }
    `}
  ${props =>
    props.labelheight &&
    css`
      .ant-form-item-label {
        height: ${props.labelheight};
      }
    `}
  ${props =>
    props.error_display &&
    css`
      .ant-form-item-explain {
        display: ${props.error_display};
      }
    `}
    ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor} !important;
    `}
  ${props =>
    props.hide === 'star' &&
    css`
      & label::before {
        display: none !important;
      }
    `}
  ${props =>
    props.error_padding &&
    css`
      .ant-form-item-explain {
        padding: ${props.error_padding};
      }
    `}
  ${props =>
    props.display_item === 'none' &&
    css`
      .ant-form-item-control-input {
        display: ${props.display_item};
      }
    `}
  ${props =>
    props.show_only === 'validate' &&
    css`
      .ant-form-item-control-input,
      .ant-form-item-label {
        display: none;
      }
    `}
  ${props =>
    props.checkbox_label_height &&
    css`
      & .ant-form-item-control-input .ant-checkbox-wrapper {
        height: ${props.checkbox_label_height}!important;
      }
    `}
  ${props =>
    props.error_height &&
    css`
      .ant-form-item-explain-error {
        height: ${props.error_height}!important;
      }
    `}
  ${props =>
    props.max_width &&
    css`
      max-width: ${props.max_width}!important;
    `}
`;

FormItem.defaultProps = {};

/** @component */
export default FormItem;
