import { useState } from 'react';
import styled from 'styled-components';
import { InstagramFilled, LinkedinFilled, MailFilled } from '@ant-design/icons';
import { brokerGray, mainColor } from 'constants/colors';
import { Col, FooterWrapper, Paragraph, Row } from '../atoms';
import TwitterIcon from '../../assets/images/custom-icons/twitter';
import FacebookIcon from '../../assets/images/custom-icons/Facebook';
import EmailModal from '../organisms/email-modal';
import { useGetAppSettingsQuery } from '../../app/services/app-settings';

const FooterSubWrapper = styled(Row)`
  @media screen and (min-width: 1600px) {
    & .content_info,
    & .content_wrapper,
    & .icons_wrapper {
      flex: unset !important;
      max-width: fit-content !important;
    }
    & .content_wrapper {
      padding-right: 5px !important;
    }
  }
  @media screen and (min-width: 992px) {
    & .br_lg {
      border-right: 1px solid #c5c5c5;
    }
    & .icons_wrapper {
      grid-template-columns: 1fr 1fr 1fr 1fr !important;
    }
    & .content_wrapper > .ant-row {
      flex-wrap: nowrap;
    }
    & .content_info {
      height: 30px;
    }
  }
  @media screen and (max-width: 768px) {
    & {
      padding: 5px 20px !important;
    }
  }
  @media screen and (max-width: 576px) {
    & {
      padding: 5px 10px !important;
    }
    & .icons_wrapper {
      display: flex !important;
    }
    & .content_info {
      max-width: 50% !important;
    }
  }
  & {
    padding: 15px 30px;
  }

  & .icons_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  & .icons_wrapper a {
    padding: 0 5px !important;
  }
`;

const BrokerFooter = ({ extraTopContent = '', wrapperProps = {} }) => {
  const { data: settings } = useGetAppSettingsQuery();
  const [emailData, setEmailData] = useState(null);

  return (
    <>
      {emailData && (
        <EmailModal
          closeModal={() => setEmailData(null)}
          title={emailData?.title}
          to={emailData?.to}
          withDefWrapper={true}
        />
      )}
      <FooterWrapper
        height='auto'
        back_color={brokerGray}
        {...wrapperProps}
        className='page_footer'
      >
        {extraTopContent}
        <FooterSubWrapper justify='start' height='100%' gutter={[10, 5]}>
          <Col span={18} sm={20} xs={24} className='br_lg content_wrapper'>
            <Row gutter={[10, 5]} width='100%'>
              <Col
                align='center'
                cursor='pointer'
                onClick={() =>
                  setEmailData({
                    title: 'Book a Broker Presentation',
                    to: settings?.WEB_BROKER_INQUIRY_EMAIL,
                    from: settings?.FROM_EMAILID_BROKER,
                  })
                }
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className='content_info'
              >
                <MailFilled style={{ marginRight: '5px', color: mainColor }} />
                <Paragraph ellipsis={{ tooltip: true }} mb={0}>
                  Book a Broker Presentation
                </Paragraph>
              </Col>
              <Col
                align='center'
                cursor='pointer'
                onClick={() =>
                  setEmailData({
                    title: 'Connect with Sales Team',
                    to: settings?.WEB_SALES_INQUIRY_EMAIL,
                    from: settings?.FROM_EMAILID_SALES,
                  })
                }
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className='content_info'
              >
                <MailFilled style={{ marginRight: '5px', color: mainColor }} />
                <Paragraph ellipsis={{ tooltip: true }} mb={0}>
                  Connect with Sales Team
                </Paragraph>
              </Col>
              <Col
                align='center'
                cursor='pointer'
                onClick={() =>
                  setEmailData({
                    title: 'Connect with Design Team',
                    to: settings?.WEB_DECOR_INQUIRY_EMAIL,
                    from: settings?.FROM_EMAILID_DECOR,
                  })
                }
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className='content_info'
              >
                <MailFilled style={{ marginRight: '5px', color: mainColor }} />
                <Paragraph ellipsis={{ tooltip: true }} mb={0}>
                  Connect with Design Team
                </Paragraph>
              </Col>
              <Col
                align='center'
                cursor='pointer'
                onClick={() =>
                  setEmailData({
                    title: 'Connect with Leasing Team',
                    to: settings?.WEB_LEASE_CONTACT_EMAIL,
                    from: settings?.FROM_EMAILID_LEASES,
                  })
                }
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className='content_info'
              >
                <MailFilled style={{ marginRight: '5px', color: mainColor }} />
                <Paragraph ellipsis={{ tooltip: true }} mb={0}>
                  Connect with Leasing Team
                </Paragraph>
              </Col>
            </Row>
          </Col>
          <Col align='center' span={6} sm={4} xs={24} justify='center' className='icons_wrapper'>
            <a href={settings?.PROFILE_URL_INSTAGRAM} target='_blank' rel='noreferrer'>
              <InstagramFilled style={{ fontSize: '27px', color: mainColor }} />
            </a>
            <a href={settings?.PROFILE_URL_TWITTER} target='_blank' rel='noreferrer'>
              <TwitterIcon style={{ color: mainColor }} />
            </a>
            <a href={settings?.PROFILE_URL_FACEBOOK} target='_blank' rel='noreferrer'>
              <FacebookIcon style={{ color: mainColor }} />
            </a>
            <a href={settings?.PROFILE_URL_LINKEDIN} target='_blank' rel='noreferrer'>
              <LinkedinFilled style={{ fontSize: '26px', color: mainColor }} />
            </a>
          </Col>
        </FooterSubWrapper>
      </FooterWrapper>
    </>
  );
};

export default BrokerFooter;
