/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import { emptyState, loadState, saveState } from '../../configs/local-storage';

const initialState = loadState() || { user: null, jwt: null };

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, { payload }) {
      state.user = payload?.user;
      state.jwt = payload?.jwt;
      saveState(payload);
    },
    changeUserData(state, { payload }) {
      state.user = payload;
      state.jwt = state?.jwt;
      saveState({ user: payload, jwt: state?.jwt });
    },
    removeUser(state, payload) {
      state.user = null;
      state.jwt = null;
      emptyState();
    },
  },
});

export const { setUser, changeUserData, removeUser } = userSlice.actions;
export default userSlice.reducer;
