/* eslint-disable import/no-cycle */
import ExternalLinkIcon from 'assets/images/custom-icons/ExternalLinkIcon';
import { Input } from '../index';

const InputUrl = ({ onClick, ...props }) => (
  <Input
    {...props}
    suffix={
      <a className='ant-input-group-addon' href={props?.value} target='_blank' rel='noreferrer'>
        <ExternalLinkIcon width='17px' />
      </a>
    }
    custom_suffix='true'
  />
);

export default InputUrl;
