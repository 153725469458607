import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { notification } from 'antd';
import { API_ROOT } from './env-vars';
import { getAccessToken, loadUserData } from './local-storage';
import { removeUser } from '../app/slices/user';

const baseQuery = fetchBaseQuery({
  baseUrl: API_ROOT,
  prepareHeaders: headers => {
    const token = getAccessToken();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});
const baseQueryWithAuth = () => async (args, api, extraOptions) => {
  const user = loadUserData();
  const result = await baseQuery(args, api, extraOptions);
  if (result?.error && result?.error?.status === 401) {
    api.dispatch(removeUser());
    window.location.href = '/sign-in';
  }
  if (result?.error?.status === 400 && user?.role?.type === 'admin') {
    const errors = result?.error?.data?.data?.errors || {};
    const description =
      Object.values(errors)
        ?.flatMap(val => val)
        ?.join(', ') ||
      result?.error?.data?.data?.[0]?.messages?.[0]?.message ||
      'Something went wrong!';
    notification.error({ description });
  }
  return result;
};

export const createCustomApi = params =>
  createApi({
    baseQuery: baseQueryWithAuth(),
    ...params,
  });
