import styled, { css } from 'styled-components';
import { Menu as menu } from 'antd';
// eslint-disable-next-line import/no-cycle

const Menu = styled(menu)`
  ${props =>
    props.type === 'sidebar' &&
    css`
      border-right: 0;
      padding-bottom: 30px;

      .ant-menu-submenu-title {
        height: 35px;
      }
      .ant-menu-vertical .ant-menu-item:not(:last-child),
      .ant-menu-vertical-left .ant-menu-item:not(:last-child),
      .ant-menu-vertical-right .ant-menu-item:not(:last-child),
      .ant-menu-inline .ant-menu-item:not(:last-child) {
        margin-bottom: 0;
        margin-top: 0;
      }

      .ant-menu-item-active {
        background-color: ${props.active_lite_color} !important;
        & span {
          color: ${props.item_color} !important;
        }
      }

      &.ant-menu-inline {
        & .ant-menu-submenu-title,
        & .ant-menu-item {
          height: 30px !important;
        }
      }

      .ant-menu-item {
        color: #717579;
        background-color: #fff !important;
        max-width: 100%;

        &:hover {
          color: ${props.item_color};
          path {
            fill: ${props.item_color};
          }
        }

        .case_icon svg {
          width: 18px;
        }
        .ant-menu-item-icon {
          width: 24px;
        }

        &.ant-menu-item-selected,
        &:active,
        .ant-menu-submenu-title:active {
          border-radius: 0 10px 50px 0;
          width: calc(100% - 40px);
          color: ${props.item_color};
          font-weight: 700;
          background-color: ${props.active_lite_color}!important;

          :after {
            left: 0;
            right: unset;
            border-radius: 0 57px 57px 0;
            border-right: 9px solid ${props.item_color};
          }

          path {
            fill: ${props.item_color};
          }
        }
      }

      .ant-menu-sub {
        background-color: #fff;

        .ant-menu-item {
          padding-left: 65px !important;
          height: 30px;
        }
      }
      .ant-menu-item,
      .ant-menu-submenu {
        font-weight: 500;
        font-size: 16px;
        svg {
          font-size: 18px;
        }
      }
      .ant-menu-submenu {
        color: #717579 !important;
        font-weight: 500 !important;
      }
      .ant-menu-submenu-title:hover {
        color: ${props.item_color} !important;

        path {
          fill: ${props.item_color};
        }
      }
      .ant-menu-submenu {
        .ant-menu-submenu-arrow {
          color: #717579 !important;
        }
        &:hover {
          .ant-menu-submenu-title > .ant-menu-submenu-arrow {
            color: ${props.item_color} !important;
            path {
              fill: ${props.item_color};
            }
          }
        }
      }
    `}
  ${props =>
    props.active_color &&
    css`
      & .ant-menu-item.ant-menu-item-selected,
      & .ant-menu-item:active {
        & 
        background-color: ${props.active_lite_color} !important;
        & a{
          color:white !important;
        }
      }
      .ant-menu-submenu:not(.ant-menu-item-disabled):hover
        .ant-menu-submenu-title
        .ant-menu-submenu-arrow {
        color: ${props.active_color} !important;
      }
      .ant-menu-submenu-selected{
        color: ${props.active_color} !important;
      }
      .ant-menu-item:not(.ant-menu-item-disabled):hover {
        &,
        & a {
          color: ${props.active_color} !important;
        }

        &,
        path {
          color: ${props.active_color} !important;
          fill: ${props.active_color} !important;
        }
      }
      .ant-menu-item-selected::after {
        border-right: ${props.active_color} !important;
      }
      .ant-menu-item {
        &.ant-menu-item-selected,
        &:active,
        .ant-menu-submenu-title:active {
          background-color: ${props.active_lite_color} !important;
          border-right: 9px solid ${props.active_color};
        }
      }

      & .ant-menu-item:active, .ant-menu-submenu-title:active{
          background-color: ${props.active_lite_color} !important;
      }
      .ant-menu-submenu-active:not(.ant-menu-item-disabled):not(.ant-menu-item-selected),
      .ant-menu-submenu-title:hover {
        &,
        & .ant-typography ,
        & span {
          color: ${props.active_color} !important;
        }

        path {
          fill: ${props.active_color};
        }
      }
      &.ant-menu-vertical {
        & .ant-menu-item-active {
          background-color: ${props.active_lite_color} !important;
          color: ${props.active_color} !important;
        }
        & .ant-menu-item:not(.ant-menu-item-disabled):hover {
          color: ${props.active_color} !important;
        }
      }
      &.ant-menu-inline-collapsed{
        & .ant-menu-item.ant-menu-item-selected {
          border-right:none !important;
          border-radius: 0;
          &,
          & a
          & svg,
          & path{
            color: ${props.active_color} !important;
            fill ${props.active_color}!important;
          }
        }
      }
      &.ant-menu-inline {
        & .ant-menu-item-selected:not(.ant-menu-item-active) {
          & span,
          &,
          & a {
            color: ${props.active_color} !important;
          }

          & .anticon {
          svg,
          path{
            fill:${props.active_color};
          }
        }
      }

      }
    `}
  ${props =>
    props.type === 'sidebar' &&
    props.sectype === 'collapsed' &&
    css`
      .ant-menu-item {
        &.ant-menu-item-selected,
        &:active,
        .ant-menu-submenu-title:active {
          width: 100%;
          border-radius: 0;
        }
      }
    `}
  ${props =>
    props.type === 'project_act' &&
    css`
      border-right: 0;

      .ant-menu-item {
        margin: 0 !important;
        color: #717579;
        max-width: 100%;
        padding: 0 10px !important;
        border-top: 1px solid #d5d5d5;

        .case_icon svg {
          width: 18px;
        }
        .ant-menu-item-icon {
          width: 24px;
        }

        &.ant-menu-item-selected,
        &:active,
        .ant-menu-submenu-title:active {
          :after {
            display: none;
          }
        }
      }

      .ant-menu-submenu {
        .ant-menu-sub {
          padding: 0 25px;
          background-color: #fff;
        }
        .ant-menu-item {
          padding: 0 !important;
        }
      }

      .ant-menu-submenu-title {
        padding: 0 10px !important;
        border-top: 1px solid #d5d5d5;
        margin: 0 !important;

        .ant-menu-item-icon {
          width: 24px;
        }
      }
    `}
`;

export default Menu;
