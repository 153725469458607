import { useState } from 'react';
import { CameraOutlined } from '@ant-design/icons';
import { FormItem, Row, Col, Title, Button, Switch, Upload, Avatar } from 'components/atoms/index';
import { convertFileUrl } from 'Utils/Helpers';
import { addUploadApi } from 'services/uploads';

const ModalContentUpload = ({
  uploadedFile,
  setUploadedFile,
  icons,
  mainSpan,
  title,
  desc,
  secDesc,
  imageSize,
  imageMargin,
  hideArchive,
  disableUpload,
  uploadProps,
  onGenerateClick = () => {},
}) => {
  const [uploadLoading, setUploadLoading] = useState(false);

  const uploadFile = async file => {
    setUploadLoading(true);
    const callback = await addUploadApi(file);
    setUploadLoading(false);
    if (callback) {
      const uploadedData = callback?.data ? callback?.data[0] : null;
      setUploadedFile({ ...uploadedData, url: convertFileUrl(uploadedData?.url) });
    }
    return false;
  };
  return (
    <Col span={mainSpan || 7} align='center' direction='column' justify='end' height='fit-content'>
      <Col span={24} justify='center'>
        <Title
          fz='12px'
          fw='600'
          lh='18px'
          color='#1232C4'
          text_decoration='underline'
          mb={2}
          onClick={onGenerateClick}
        >
          {title ?? '+Generate Portal User'}
        </Title>
      </Col>
      <Col
        span={24}
        border='1px solid #C4C4C4'
        justify='center'
        direction='column'
        align='center'
        padding='5px 0'
        width='100%'
      >
        <Title fz='8px' fw='400' align='center' mb='0'>
          {desc || 'Add a logo picture of the Broker.'}
        </Title>
        <Title fz='8px' lh='12px' fw='400' align='center' mb='2' mt='2'>
          {secDesc || 'The logo should be JPG or PNG.'}
        </Title>
        <Avatar
          margin={imageMargin || '10px 0'}
          back_color='white'
          border='#C9C9C9'
          size={imageSize || 102}
          fz='22px'
          fit='cover'
          src={uploadedFile?.formats?.small?.url || uploadedFile?.url}
          icon={<CameraOutlined style={{ color: '#C9C9C9', fontSize: '22px' }} />}
        />
        <Upload
          beforeUpload={file => uploadFile(file)}
          maxCount={1}
          onRemove={() => setUploadedFile(null)}
          showUploadList={false}
          fileList={uploadedFile ? [uploadedFile] : []}
          customRequest={() => {}}
          accept='.jpeg,.jpg,.png'
          listType='text'
          display='flex'
          justify='space-between'
          wrapper_width='90%'
          width='100%'
        >
          <Button
            width='100%'
            fz='12px'
            height='25px'
            type={disableUpload ? '' : 'blue'}
            loading={uploadLoading}
            disabled={disableUpload}
            {...uploadProps}
          >
            Upload
          </Button>
        </Upload>
      </Col>
      <Col padding='0' span={24} justify='center'>
        {icons || (
          <Row align='middle' justify='center'>
            <Col align='center' direction='column'>
              <Title fz='12px' fw='600' lh='18px' color='#717579' mb='0'>
                Active
              </Title>
              <FormItem name='Inactive' valuePropName='checked'>
                <Switch size='small' />
              </FormItem>
            </Col>
            {!hideArchive && (
              <Col align='center' direction='column' ml='16px'>
                <Title fz='12px' fw='600' lh='18px' color='#717579' mb='0'>
                  Archive
                </Title>
                <FormItem name='Archive' valuePropName='checked'>
                  <Switch size='small' />
                </FormItem>
              </Col>
            )}
          </Row>
        )}
      </Col>
    </Col>
  );
};

export default ModalContentUpload;
