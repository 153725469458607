import styled, { css } from 'styled-components';
import { Switch as toggle } from 'antd';
import { mainColor } from 'constants/colors';

const Switch = styled(toggle)`
  &.ant-switch-checked {
    background-color: ${mainColor};
  }
  background-color: #c0bfbf;
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px!important;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.border &&
    css`
      border: ${props.border};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding}!important;
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width}!important;
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height}!important;
    `}
  ${props =>
    props.back_color &&
    css`
      background: ${props.back_color}!important;
    `}

  ${props =>
    props.switched_color &&
    css`
      &.ant-switch-checked {
        background-color: ${props.switched_color};
      }
    `}
`;

export default Switch;
