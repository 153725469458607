/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    data: null,
  },
  reducers: {
    saveSettingsData(state, { payload }) {
      state.data = payload;
    },
  },
});

export const { saveSettingsData } = settingsSlice.actions;
export default settingsSlice.reducer;
