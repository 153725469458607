import styled, { css } from 'styled-components';
import { Table as table } from 'antd';
import { darkBlue, mainColor } from '../../../constants/colors';

const Table = styled(table)`
  //* {
  //  border-color: #969696 !important;
  //}
  .expanded-table-last {
    z-index: 2;
  }

  .ant-table-footer {
    padding: 0px !important;
  }

  .ant-table-tbody .ant-table-cell {
    padding: 5px 10px !important;
  }

  .ant-table-tbody .full-expanded > td {
    z-index: 1;
    padding: 2px 10px 7px 10px !important;
  }
  .ant-table {
    box-sizing: border-box;
    border-radius: 4px 4px 0 0;

    .docs_table_header {
      display: flex;
      justify-content: space-between;
    }

    .documents_status {
      .anticon {
        margin-right: 10px;

        &.anticon-check-circle {
          color: #00dace;
        }

        &.anticon-exclamation-circle {
          color: #fc5e7a;
        }

        &.anticon-close-circle {
          color: #fc5e7a;
        }

        &.anticon-clock-circle {
          color: #35a5e4;
        }
      }
    }

    .ant-table-cell {
      padding: 5px;
    }
  }

  .ant-table-title {
    border-bottom: 1px solid #e8eef4;
  }

  .ant-table-thead {
    .ant-table-cell {
      padding: 7px 10px !important;
      background-color: #e0e0e0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    > tr > th {
      background-color: #fff;
      font-weight: 500;
      font-size: 16px;
      color: #171b1e;
    }
  }

  .ant-table-tbody {
    .ant-table-row {
      &:nth-child(odd) {
        background-color: #fafafb;
      }
    }

    .ant-table-cell {
      padding: 5px 10px !important;
    }
    .ant-table-column-title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    > tr {
      td {
        font-size: 14px;
        color: #717579;
        font-weight: 350;
      }

      //&:last-child {
      //  > td {
      //    border-bottom: 0;
      //  }
      //}
    }
    > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: unset;
    }
  }

  .ant-table-row {
    &.active {
      background-color: rgba(25, 103, 210, 0.08) !important;
    }
  }

  .ant-table-thead > tr > th {
    font-size: 14px;
  }

  .ant-table-tbody > tr td {
    font-size: 12px;
  }

  .active_row {
    background: #ffc00042 !important;
  }

  .row_pointer {
    td {
      cursor: pointer;
    }
  }

  .active_units_row {
    td {
      background: ${props => props.active_lite_color} !important;
    }
    &.orange {
      td {
        background-color: #ffc00047 !important;
      }
    }
  }

  .ant-table-expanded-row-fixed {
    display: inherit;
    margin: 0px !important;
    &::after {
      display: none;
    }
  }

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.colswidth &&
    css`
      th {
        width: ${props.colswidth};
      }
    `}
  ${props =>
    props.mb &&
    css`
      margin-bottom: ${props.mb};
    `}
  ${props =>
    props.row &&
    css`
      .ant-table-cell {
        width: ${props.row};
      }
    `}
  ${props =>
    props.head_color &&
    css`
      .ant-table-thead .ant-table-cell {
        color: ${props.head_color};

        svg path {
          fill: ${props.head_color};
        }
      }
    `}
  ${props =>
    props.head_back &&
    css`
      .ant-table-thead .ant-table-cell {
        background-color: ${props.head_back}!important;
      }
    `}
  ${props =>
    props.head_padding &&
    css`
      .ant-table-thead .ant-table-cell {
        padding: ${props.head_padding}!important;
      }
    `}
    ${props =>
    props.last_col_back &&
    css`
      & .ant-table-row td:last-child {
        background: ${props.last_col_back} !important;
      }
    `}
  ${props =>
    props.footerbackcolor &&
    css`
      .ant-table-footer {
        background-color: ${props.footerbackcolor};
      }
    `}
  ${props =>
    props.expand_back &&
    css`
      .ant-table-expanded-row .ant-table-cell {
        background-color: ${props.expand_back};
      }
    `}
  ${props =>
    props.footertopborder &&
    css`
      .ant-table-footer {
        border-top: ${props.footertopborder} !important;
      }
    `}
  ${props =>
    props.footerpadding &&
    css`
      .ant-table-footer {
        padding: ${props.footerpadding} !important;
      }
    `}
  ${props =>
    props.cell_padding &&
    css`
      .ant-table-cell {
        padding: ${props.cell_padding} !important;
      }
    `}
  ${props =>
    props.body_cell_padding &&
    css`
      .ant-table-tbody .ant-table-cell {
        padding: ${props.body_cell_padding} !important;
      }
    `}
  ${props =>
    props.body_color &&
    css`
      .ant-table-tbody > tr td {
        color: ${props.body_color}!important;
        div {
          color: ${props.body_color}!important;
        }
      }
    `}
  ${props =>
    props.body_fw &&
    css`
      .ant-table-tbody > tr td {
        font-weight: ${props.body_fw}!important;
        div {
          font-weight: ${props.body_fw}!important;
        }
      }
    `}
  ${props =>
    props.head_fz &&
    css`
      .ant-table-thead > tr > th {
        font-size: ${props.head_fz};
      }
    `}
  ${props =>
    props.body_fz &&
    css`
      .ant-table-tbody > tr td {
        font-size: ${props.body_fz};
      }
    `}
    ${props =>
    props.sec_type === 'adjustment' &&
    css`
      th.ant-table-cell:not(.ant-table-cell-scrollbar) {
        border: 1px solid ${mainColor};
      }
      & .ant-table-thead > tr th:nth-last-child(3) {
        border-bottom: 1px solid ${darkBlue} !important;
      }
      & th.ant-table-cell:not(.ant-table-cell-scrollbar) {
        border-color: ${darkBlue};
      }
      & .ant-table-thead > tr th:nth-last-child(3)::before {
        width: 10px !important;
        border-left: 1px solid ${darkBlue} !important;
        height: calc(100% + 3px) !important;
        bottom: -1px !important;
        right: -10px !important;
        z-index: 2;
        right: -10px !important;
        width: 10px !important;
      }
    `}
    ${props =>
    props.type === 'separated' &&
    css`
      & .editable-cell-value-wrap {
        padding: 0px !important;
        height: 19px;
      }
      & input,
      & .ant-input-number {
        height: 25px !important;
        width: 100%;
      }
      & .ant-table-thead .ant-table-cell {
        padding: 5px !important;
      }
      table,
      .ant-table-footer {
        border: none !important;
      }

      & .ant-table-thead > tr th:nth-last-child(3) {
        border-right: 10px solid white !important;
        border-bottom: none;
        overflow: unset !important;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          right: -10px;
          bottom: -2px;
          width: 10px;
          z-index: 1;
          height: calc(100% + 5px);
          background: white;
        }
      }
      & .ant-table-tbody tr td:nth-last-child(2) {
        border-right: 10px solid white !important;
        overflow: unset !important;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          right: -10px;
          bottom: -2px;
          width: 10px;
          z-index: 1;
          height: calc(100% + 5px);
          background: white;
        }
        &::before {
          content: '';
          position: absolute;
          z-index: 2;
          right: -10px !important;
          bottom: 0 !important;
          width: 10px !important;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.06);
          top: 0;
          transform: inherit !important;
          transition: inherit !important;
          border-right: 1px solid #f0f0f0;
          border-left: 1px solid #f0f0f0;
          background: white;
        }
      }
    `}
  ${props =>
    props.hide === 'body' &&
    css`
      .ant-table-tbody {
        display: none;
      }

      .ant-table,
      .ant-table-container {
        border-bottom: none;
      }

      .ant-table-thead > tr > th {
        border-bottom: none;
      }
    `}

  ${props =>
    props.rowHeight &&
    css`
      .ant-table-thead > tr {
        height: ${props.rowHeight}px;
      }
    `}
    ${props =>
    props.cell_height &&
    css`
      & tbody tr:not(:first-child) td,
      & tbody tr:not(:first-child),
      & th {
        line-height: ${props.cell_height}px !important;
      }
    `}
  ${props =>
    props.b_rowHeight &&
    css`
      .ant-table-tbody > tr {
        height: ${props.b_rowHeight};
      }
    `}
  ${props =>
    props.row_border_bottom &&
    css`
      .ant-table-tbody > tr > td {
        border-bottom: ${props.row_border_bottom};
      }
    `}
  ${props =>
    props.flex === 'contents' &&
    css`
      .ant-table-thead > tr {
        display: contents;
      }
    `}
  ${props =>
    props.odd_row_color &&
    css`
      .ant-table-tbody .ant-table-row {
        &:nth-child(odd) {
          background-color: ${props.odd_row_color};
        }
      }
    `}
  ${props =>
    props.active_row_back_color &&
    css`
      .ant-table-row {
        &.active {
          background-color: ${props.active_row_back_color}!important;
        }
      }
    `}
  ${props =>
    props.highlight_row_back_color &&
    css`
      .ant-table-row {
        &.highlight {
          background-color: ${props.highlight_row_back_color}!important;
        }
      }
    `}
  ${props =>
    props.scrollbar === 'thin' &&
    css`
      .ant-table-body {
        &::-webkit-scrollbar {
          width: 12px;
        }

        &::-webkit-scrollbar-track {
          background: #f3f3f3;
        }

        &::-webkit-scrollbar-thumb {
          background: #c6c6c6;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #d8d8d8;
        }
        /* Works on Firefox */
        scrollbar-width: thin;
        scrollbar-color: #c6c6c6 #f3f3f3;
      }
    `}

  ${props =>
    props.double_exp_row_color &&
    css`
      .ant-table-tbody {
        .ant-table-row {
          &:nth-child(4n),
          &:nth-child(4n-1) {
            background: #fff;
          }

          &:nth-child(4n-2),
          &:nth-child(4n-3) {
            background: ${props.double_exp_row_color};
          }
        }
        .ant-table-expanded-row {
          &:nth-child(4n),
          &:nth-child(4n-1) {
            td {
              background: ${props.double_exp_row_color};
            }
          }

          &:nth-child(4n-2),
          &:nth-child(4n-3) {
            td {
              background: #fff;
            }
          }
        }
      }
    `}
  ${props =>
    props.flex === 'contents-none' &&
    css`
      .ant-table-thead > tr > .ant-table-cell {
        padding: 11px 16px;
      }
    `}
  ${props =>
    props.expand_pos &&
    css`
      .ant-table-row-expand-icon {
        float: ${props.expand_pos};
      }
    `}
  ${props =>
    props.expand_icon_size === 'big' &&
    css`
      .ant-table-row-expand-icon {
        width: 20px;
        height: 20px;
        margin-top: 0;

        &:after {
          top: 2px;
          left: 8px;
        }
      }
    `}
  ${props =>
    props.append_cell_back &&
    css`
      .ant-table-row-level-0 > .ant-table-cell-with-append {
        background-color: ${props.append_cell_back};
      }
    `}
  ${props =>
    props.zero_hover_back &&
    css`
      .ant-table-row-level-0:hover > .ant-table-cell-with-append {
        background-color: ${props.zero_hover_back}!important;
      }
    `}
  ${props =>
    props.expanded_row_extras_display &&
    css`
      .ant-table-row-level-1 > .ant-table-cell-with-append {
        .ant-table-row-expand-icon,
        .ant-table-row-indent {
          display: ${props.expanded_row_extras_display};
        }
      }
    `}
  ${props =>
    props.head_fw &&
    css`
      .ant-table-thead > tr > th {
        font-weight: ${props.head_fw};
      }
    `}
  ${props =>
    props.x_overflow &&
    css`
      .ant-table-body {
        overflow-x: ${props.x_overflow};
      }
    `}
  ${props =>
    props.y_overflow &&
    css`
      .ant-table-body {
        overflow-y: ${props.y_overflow}!important;
      }
    `}
  ${props =>
    props.height &&
    css`
      .ant-table-body {
        height: ${props.height}px;
      }
    `}
  ${props =>
    props.body_min_height &&
    css`
      .ant-table-body {
        min-height: ${props.body_min_height};
      }
    `}
  ${props =>
    props.tb_height &&
    css`
      .ant-table-tbody {
        height: ${props.tb_height}px;
      }
    `}

  ${props =>
    props.sort_col_back &&
    css`
      & td.ant-table-column-sort {
        background-color: ${props.sort_col_back};
      }
    `}
  ${props =>
    props.empty_margin &&
    css`
      .ant-empty {
        margin: ${props.empty_margin};
      }
    `}
  ${props =>
    props.head_text_overflow &&
    css`
      .ant-table-thead .ant-table-cell {
        text-overflow: ${props.head_text_overflow}!important;
        overflow: hidden;
        white-space: nowrap;
      }
    `}
   ${props =>
    props.active_color &&
    css`
      & .ant-checkbox-checked .ant-checkbox-inner,
      & .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        background-color: ${props.active_color} !important;
        border-color: ${props.active_color} !important;
        box-shadow: none !important;
      }

      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: ${props.active_color} !important;
      }

      .ant-table-cell-row-hover .ant-table-row-expand-icon {
        border-color: ${props.active_color} !important;

        &::before,
        &::after {
          background-color: ${props.active_color} !important;
        }
      }

      & .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover {
        & .ant-checkbox::after {
          border-color: ${props.active_color} !important;
        }
        & .ant-checkbox-inner {
          border-color: ${props.active_color} !important;
          box-shadow: none !important;
        }
      }

      & .ant-table-tbody > tr.ant-table-row-selected:not(.disabled):not(.active_units_row) > td {
        background-color: ${props.active_lite_color} !important;
      }
      & .ant-table-tbody > tr.ant-table-row-selected.disabled > td {
        background-color: white !important;
        border-color: #f0f0f0 !important;
      }
    `}
  ${props =>
    props.scrollbar === 'custom' &&
    css`
      .ant-table-body {
        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background: #fff;
        }

        &::-webkit-scrollbar-thumb {
          background: #b5aad2;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #a28bd2;
        }
      }
    `}
`;

export default Table;
