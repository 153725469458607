import client from './main-client';

export const fetchRQAllocationRequestsApi = params => client().get('/rq-allocations', { params });
export const fetchRQAllocationRequestsCountApi = params =>
  client().get('/rq-allocations/count', { params });

export const fetchAllAllocationRequestsApi = params => client().get('/rq-allocations', { params });
export const fetchAllAllocationRequestsCountApi = params =>
  client().get('/rq-allocations/count', { params });

export const fetchOneAllocationRequestApi = ({ id, ...params }) =>
  client().get(`/rq-allocations/${id}`, { params });
export const updateAllocationRequestApi = params =>
  client().put(`/rq-allocations/${params.id}`, params.values);
export const addAllocationRequestApi = data => client().post('/rq-allocations', data);
export const deleteAllocationRequestApi = params =>
  client().delete(`/rq-allocations/${params.id}`, { params });

export const fetchAllocationBuyersApi = params => client().get('/rq-alloc-buyers', { params });
export const addAllocationBuyersApi = data => client().post('/rq-alloc-buyers', data);
export const updateAllocationBuyersApi = params =>
  client().put(`/rq-alloc-buyers/${params.id}`, params.values);
export const deleteAllocationBuyersApi = params =>
  client().delete(`/rq-alloc-buyers/${params.id}`, { params });

export const fetchAllocBuyersClientApi = params => client().get('/rq-buyer-clients', { params });
export const fetchAllocBuyersClientCountApi = params =>
  client().get('/rq-buyer-clients/count', { params });
export const addAllocBuyersClientApi = data => client().post('/rq-buyer-clients', data);
export const updateAllocBuyersClientApi = params =>
  client().put(`/rq-buyer-clients/${params.id}`, params.values);
