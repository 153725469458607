/* eslint-disable import/no-cycle */
import { memo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CloseOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import ProfilePopup from 'components/organisms/profile-popup';
import HeadUserDropdown from 'components/molecules/HeadUserDropdown';
import useGetMainColor from 'Utils/hooks/useGetMainColor';
import {
  Col,
  Content,
  Header,
  Layout,
  Menu,
  Paragraph,
  Row,
  Sider,
  GlobalStyles,
} from './components/atoms';
import { protectedRouts } from './constants/routes';
import { mainColor, mainTextColor } from './constants/colors';
import BrokerFooter from './components/molecules/broker-footer';
import SalesWrapper from './components/templates/sales-wrapper';
import { useGetAppSettingsQuery } from './app/services/app-settings';
import { getUser, getUserRole } from './app/services/selector-helpers';
import { backToAdminFromSale } from './Utils/Helpers';

const { SubMenu } = Menu;

const HeaderContent = styled.div`
  @media screen and (max-width: 1075px) {
    .broker_welcome {
      display: none;
    }
  }
  @media screen and (max-width: 875px) {
    .broker_links {
      display: none;
    }
  }
  @media screen and (max-width: 576px) {
    .logo {
      width: 150px;
      background-size: contain !important;
    }
  }

  width: 100%;
  padding: 15px 30px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    font-size: 22px;
    margin-right: 30px;
    margin-top: 7px;
    cursor: pointer;
    color: #717579;
  }
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding} !important;
    `}
`;
const LogoWrapper = styled.div`
  width: 100%;
  padding: 30px 30px 20px;

  img {
    width: 100%;
    display: block;
  }

  ${props =>
    props.type === 'collapsed' &&
    css`
      padding: 50px 0;
      img {
        display: none;
      }
    `}
`;

export const DecorItemWrapper = styled(Col)`
  cursor: pointer;
  svg {
    margin: 0 10px 0 0;
    path {
      fill: #717579;
    }
  }
  .ant-typography {
    color: #717579;
  }
  &:hover {
    svg path {
      fill: ${mainTextColor};
    }
    .ant-typography {
      color: ${mainTextColor};
    }
  }
  ${props =>
    props.type === 'active' &&
    css`
      svg,
      svg path {
        fill: ${props.active_color || mainTextColor} !important;
      }
      .ant-typography {
        color: ${props.active_color || mainTextColor} !important;
      }
    `}
  ${props =>
    props.type === 'broker_active' &&
    css`
      position: relative;
      .ant-typography {
        color: ${mainColor} !important;
      }

      &:after {
        content: '';
        font-size: 18px;
        font-weight: 500;
        position: absolute;
        left: 0;
        bottom: -29px;
        width: 100%;
        height: 3px;
        background-color: ${mainColor};
        margin: auto;
      }
    `}
   ${props =>
    props.active_color &&
    css`
      &:hover {
        svg,
        svg path {
          fill: ${props.active_color} !important;
        }
        .ant-typography {
          color: ${props.active_color} !important;
        }
      }
    `}
   ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor} !important;
    `}
`;

export const convertRoutes = routes => {
  let pushData = [...Object.values(routes)];
  for (const key in routes) {
    if (routes[key].items) {
      pushData = [...pushData, ...Object.values(routes[key].items)];
    }
  }
  return pushData;
};

// const profileRoute = {
//   profile: {
//     pageTitle: 'My Profile',
//     key: 'profile',
//     showComponent: false,
//     icon: <UserOutlined />,
//   },
// };

const CloseIcon = styled(CloseOutlined)`
  svg {
    font-size: 32px;
    margin-right: 15px;
    margin-top: 0;
    cursor: pointer;
    color: #171b1e;
  }
`;

const DashboardWrapper = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const role = useSelector(getUserRole);
  const user = useSelector(getUser);
  const [activeColor, liteActiveColor] = useGetMainColor();
  const { data: settings } = useGetAppSettingsQuery();
  const [collapsed, setCollapsed] = useState(role === 'brokers');
  const [modalOpen, setModalOpen] = useState(false);
  const convertedRoutes = convertRoutes(protectedRouts[role]);
  const pageData = Object.values(convertedRoutes).find(el => {
    return el.pathname === location.pathname;
  });

  const collapsedText = collapsed ? 'collapsed' : '';

  const headerContent = {
    decorselection: (
      <Row align='middle' justify='space-between' width='100%' flex='1' padding='0 30px 0 0'>
        {settings?.APP_PORTAL_MAIN_LOGO?.[0] && (
          <img
            src={settings?.APP_PORTAL_MAIN_LOGO?.[0]}
            alt='logo'
            style={{ height: '50px', cursor: 'pointer' }}
            className='logo'
            onClick={() => navigate('/')}
          />
        )}
        <Row>
          {Object.values(protectedRouts[role]).map(
            menuitem =>
              !menuitem?.hideInList && (
                <DecorItemWrapper
                  key={menuitem.key}
                  align='center'
                  margin='0 30px 0 0'
                  type={menuitem.pathname === location.pathname ? 'active' : ''}
                  onClick={() => {
                    menuitem?.pathname ? navigate(menuitem.pathname) : setModalOpen(true);
                  }}
                  active_color={activeColor}
                >
                  {menuitem.icon}
                  <Paragraph mb={0}>{menuitem?.pageTitle}</Paragraph>
                </DecorItemWrapper>
              )
          )}
        </Row>
      </Row>
    ),
    brokers: (
      <Row align='middle' justify='space-between' width='100%' flex='1' padding='0'>
        <Col align='center'>
          {settings?.APP_PORTAL_MAIN_LOGO?.[0] && (
            <img
              src={settings?.APP_PORTAL_MAIN_LOGO?.[0]}
              alt='logo'
              style={{ height: '50px', cursor: 'pointer' }}
              className='logo'
              onClick={() => navigate('/')}
            />
          )}
          <Paragraph mb={0} fz={18} className='broker_welcome' ml={10}>
            Welcome, {`${user?.firstName || ''} ${user?.lastName || ''}`}
          </Paragraph>
        </Col>
        <Row className='broker_links'>
          {Object.values(protectedRouts[role]).map(
            menuitem =>
              !menuitem?.hideInList && (
                <DecorItemWrapper
                  key={menuitem.key}
                  align='center'
                  margin='0 30px 0 0'
                  type={menuitem.pathname === location.pathname ? 'active' : ''}
                  onClick={e => {
                    menuitem?.pathname ? navigate(menuitem.pathname) : setModalOpen(true);
                  }}
                  active_color={activeColor}
                >
                  {menuitem.icon}
                  <Paragraph mb={0}>{menuitem?.pageTitle}</Paragraph>
                </DecorItemWrapper>
              )
          )}
        </Row>
      </Row>
    ),
    ...['admin', 'client'].reduce(
      (acc, key) => ({
        ...acc,
        [key]: (
          <>
            <Row align='middle'>
              <div onClick={() => setCollapsed(!collapsed)}>
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </div>
              <Paragraph mb={0} fz={24} fw={500}>
                {pageData?.pageTitle || 'Dashboard'}
              </Paragraph>
            </Row>
          </>
        ),
      }),
      {}
    ),
    sale_office: (
      <Row align='middle' justify='space-between' width='100%' flex='1' padding='0'>
        <Col align='center'>
          {settings?.APP_PORTAL_MAIN_LOGO?.[0] && (
            <img
              src={settings?.APP_PORTAL_MAIN_LOGO?.[0]}
              alt='logo'
              style={{ height: '50px', cursor: 'pointer', marginRight: '30px' }}
              onClick={() => navigate('/')}
            />
          )}
          <Paragraph mb={0} fz={18}>
            Welcome, {`${user?.firstName || ''} ${user?.lastName || ''}`}
          </Paragraph>
        </Col>
      </Row>
    ),
    admin_sale: (
      <Row align='middle' justify='space-between' width='100%' flex='1' padding='0'>
        <Col align='center'>
          {settings?.APP_PORTAL_MAIN_LOGO?.[0] && (
            <img
              src={settings?.APP_PORTAL_MAIN_LOGO?.[0]}
              alt='logo'
              style={{ height: '50px', cursor: 'pointer', marginRight: '30px' }}
              onClick={() => navigate('/')}
            />
          )}
          <Paragraph mb={0} fz={18}>
            Welcome, {`${user?.firstName || ''} ${user?.lastName || ''}`}
          </Paragraph>
        </Col>
      </Row>
    ),
  };

  const roleHeaderProps = {
    brokers: { type: 'top_sticky', shadow: '0 1px 3px #d9d9d9' },
    sale_office: { type: 'top_sticky', shadow: '0 1px 3px #d9d9d9' },
    admin_sale: { type: 'top_sticky', shadow: '0 1px 3px #d9d9d9' },
  };

  const roleContentProps = {
    brokers: {
      padding: '15px 30px 70px',
      lg_padding: '15px 20px 95px',
      xs_padding: '15px 10px 170px',
    },
    sale_office: { padding: '15px', display: 'flex' },
    admin_sale: { padding: '15px', display: 'flex' },
  };

  const roleContent = {
    sale_office: <SalesWrapper>{children}</SalesWrapper>,
    admin_sale: <SalesWrapper>{children}</SalesWrapper>,
  };

  const userRoleAvatar = {
    admin_sale: <CloseIcon onClick={backToAdminFromSale} />,
  };

  return (
    <>
      <GlobalStyles active_lite_color={liteActiveColor} active_color={activeColor} />
      {modalOpen && <ProfilePopup closeModal={() => setModalOpen(false)} />}
      <Layout>
        {!['decorselection', 'brokers', 'sale_office', 'admin_sale'].includes(role) && (
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            theme='light'
            width={300}
            collapsedWidth={56}
            shadow='7px 0 80px rgba(0, 0, 0, 0.05)'
          >
            {settings?.APP_PORTAL_MAIN_LOGO?.[0] && (
              <LogoWrapper type={collapsedText}>
                <img src={settings?.APP_PORTAL_MAIN_LOGO?.[0]} alt='logo' />
              </LogoWrapper>
            )}
            <Menu
              mode='inline'
              selectedKeys={location.pathname}
              defaultOpenKeys={[(!collapsed && pageData?.subKey) || '']}
              type='sidebar'
              sectype={collapsedText}
              item_color={activeColor}
              active_lite_color={liteActiveColor}
            >
              {Object.values(protectedRouts[role]).map(menuitem =>
                menuitem.items ? (
                  <SubMenu
                    key={menuitem.key}
                    icon={menuitem.icon}
                    title={menuitem?.menuTitle || menuitem?.pageTitle}
                  >
                    {Object.values(menuitem.items).map(subItem => (
                      <Menu.Item
                        key={subItem.pathname}
                        onClick={() => {
                          setCollapsed(true);
                          navigate(subItem.pathname);
                        }}
                        className='sidebar_subs'
                      >
                        {subItem.menuTitle || subItem.pageTitle}
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  !menuitem.hideInSidebar && (
                    <Menu.Item
                      key={menuitem.pathname}
                      icon={menuitem.icon}
                      onClick={() => {
                        setCollapsed(true);
                        navigate(menuitem.pathname);
                      }}
                    >
                      {menuitem?.menuTitle || menuitem?.pageTitle}
                    </Menu.Item>
                  )
                )
              )}
            </Menu>
          </Sider>
        )}
        <Layout>
          <Header
            padding='0'
            back_color='#fff'
            height={['brokers', 'admin', 'client'].includes(role) ? '70px' : '80px'}
            {...(roleHeaderProps[role] || {})}
          >
            <HeaderContent
              padding={['brokers', 'admin', 'client'].includes(role) ? '10px 30px' : ''}
            >
              {headerContent[role] || (
                <Row align='middle'>
                  <div onClick={() => setCollapsed(!collapsed)}>
                    {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                  </div>
                  <Paragraph mb={0} fz={24} fw={500}>
                    {pageData?.pageTitle || 'Dashboard'}
                  </Paragraph>
                </Row>
              )}
              {userRoleAvatar[role] || <HeadUserDropdown />}
            </HeaderContent>
          </Header>
          <Content
            back_color='#fbfbfb'
            {...roleContentProps[role]}
            padding={roleContentProps[role]?.padding || '5px 15px'}
          >
            {/* <Content back_color='blue' padding={role === 'decorselection' ? '15px' : '15px 30px'}> */}
            {roleContent[role] || children}
            {role === 'brokers' && <BrokerFooter />}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
export default memo(DashboardWrapper);
