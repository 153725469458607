import { createGlobalStyle, css } from 'styled-components';

const GlobalStyles = createGlobalStyle`
${props =>
  props.active_color &&
  css`
    .ant-select-item-option-active,
    .ant-select-item-option-selected {
      background-color: ${props.active_lite_color} !important;
    }

    .ant-select-item-option-selected .ant-select-item-option-state {
      color: ${props.active_color} !important;
    }
    .ant-menu-item.ant-menu-item-active {
      & span {
        color: ${props.active_color} !important;
      }
    }
    & .ant-menu-item.ant-menu-item-selected,
    & .ant-menu-item.ant-menu-item-active {
      background: ${props.active_lite_color} !important;

      & span {
        color: ${props.active_color} !important;
      }
    }
    .ant-picker-active-bar {
      background-color: ${props.active_color} !important;
    }
    .ant-picker-panel {
      .ant-picker-month-btn:hover,
      .ant-picker-year-btn:hover,
      .ant-picker-decade-btn:hover,
      .ant-picker-today-btn,
      .ant-picker-now-btn {
        color: ${props.active_color} !important;
      }
      .ant-picker-ok button:not([disabled]) {
        border: none !important;
        background-color: ${props.active_color} !important;
      }
      .ant-picker-cell-range-start div,
      .ant-picker-cell-range-end div,
      .ant-picker-cell-selected .ant-picker-cell-inner {
        border: none !important;
        background: ${props.active_color} !important;
      }
      .ant-picker-cell-range-hover::after,
      .ant-picker-cell-range-hover-end::after,
      .ant-picker-cell-range-hover-start::after,
      .ant-picker-cell-today .ant-picker-cell-inner::before {
        border-color: ${props.active_color} !important;
      }
      .ant-picker-cell-range-hover-end .ant-picker-cell-inner::after,
      .ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
      .ant-picker-cell-range-start::before,
      .ant-picker-cell-in-range::before,
      .ant-picker-cell-range-end::before {
        background-color: ${props.active_lite_color} !important;
      }
      .ant-picker-time-panel-cell-selected div {
        background-color: ${props.active_lite_color} !important;
        color: ${props.active_color} !important;
      }
    }

    .ant-table-container {
      .ant-table-thead {
        .ant-table-column-sorter-up.active,
        .ant-table-column-sorter-down.active {
          svg,
          path {
            fill: ${props.active_color} !important;
          }
        }
      }
    }

    .ant-modal-confirm-btns .ant-btn-primary,
    .ant-dropdown-trigger.ant-dropdown-open,
    .ant-select-focused .ant-select-selector,
    .ant-dropdown-trigger:focus,
    .ant-dropdown-trigger:active,
    .ant-dropdown-trigger:hover,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-selector:hover,
    .ant-select-open .ant-select-selector {
      border-color: ${props.active_color} !important;
      box-shadow: none !important;
    }
  `}
`;

export default GlobalStyles;
