import { useEffect, useState } from 'react';
import { Spin } from 'antd';
import {
  UpCircleOutlined,
  DeleteOutlined,
  SaveOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
  EnvironmentOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import ModalContent from 'components/molecules/ModalContent';
import ModalContentUpload from 'components/molecules/ModalContentUpload';
import {
  phoneMask,
  phonePattern,
  postalMask,
  postalPattern,
  provinceCityList,
} from 'constants/etc';
import useApiCall from 'Utils/hooks/useApiCall';
import { addAgencyApi, deleteAgencyApi, updateAgencyApi } from 'services/re-broker-agents';
import { fetchAllProjectsCountApi } from 'services/projects';
import { getUsersApi } from 'services/users';
import useGetBasicInfosByType from 'Utils/hooks/useGetBasicInfosByType';
import { defValueForMasked, filterOption, setAllOnClear } from 'Utils/Helpers';
import {
  ModalWrapper,
  Row,
  Title,
  Form,
  Input,
  InputEmail,
  Col,
  FormItem,
  Collapse,
  Select,
  Button,
  MaskedInput,
  Modal,
  InputUrl,
} from 'components/atoms/index';
import { darkBlue } from 'constants/colors';
import useGetMainColor from 'Utils/hooks/useGetMainColor';
import allCities from '../../constants/city-province.json';

const { Option } = Select;

const { Panel } = Collapse;
const { TextArea } = Input;
const { confirm } = Modal;

const CoBrokeAgentsClientsModal = ({
  closeModal,
  editData,
  setEditData,
  title,
  subTitle,
  uploadFile,
  brokerage,
  setTriggerRequest,
  afterSave = () => {},
  isSale,
  loading = false,
  onGenerateClick = () => {},
}) => {
  const [form] = Form.useForm();
  const [activeColor, liteActiveColor] = useGetMainColor();
  const [unitStatuses] = useGetBasicInfosByType('BrokerType');
  const [uploadedFile, setUploadedFile] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [, editAgencyLoading, editAgencyCall] = useApiCall(updateAgencyApi);
  const [, addAgencyLoading, addAgencyCall] = useApiCall(addAgencyApi);
  const [, DeleteLoading, deleteAgencyCall] = useApiCall(deleteAgencyApi);
  const [, , projectCall, projectCount] = useApiCall('', fetchAllProjectsCountApi);
  const [user, userLoading, userCall] = useApiCall(getUsersApi);
  const [currentProvince, setCurrentProvince] = useState(null);

  const cityList = currentProvince ? provinceCityList[currentProvince] || [] : allCities;

  useEffect(() => {
    if (editData?.id) {
      projectCall({ 'Builder_id.id': editData.id });
      userCall({ USource_id: editData.id, UserSource: 'reBrokerAgency' });
      setUploadedFile(editData?.Logo);
      form.setFieldsValue({
        ...editData,
        ...defValueForMasked([editData, ['Phone', 'ZipPostal']]),
        BrokerAgency_id: editData?.BrokerAgency_id?.id,
        BrokerType_id: editData?.BrokerType_id?.id,
        Inactive: !editData?.Inactive,
        Archive: !!editData?.Archive,
      });
    }
  }, [editData]);

  const onFinish = async isSave => {
    const values = await form.validateFields();
    const sendValues = { ...values, Logo: uploadedFile, Inactive: !values?.Inactive };

    if (editData?.id && isSave) {
      editAgencyCall({ id: editData.id, values: sendValues }, res => {
        setEditData(res);
        setShowConfirm(false);
        afterSave({ id: editData.id, ...res });
      });
    } else {
      addAgencyCall(sendValues, res => {
        setEditData(res);
        setTriggerRequest(true);
        setShowConfirm(false);
        afterSave({ id: editData.id, ...res });
      });
    }
  };

  const handleProvinceChange = val => {
    setCurrentProvince(val);
    if (!provinceCityList[val]?.find(el => el.city === form.getFieldValue('City'))) {
      form.setFieldsValue({ City: null });
    }
  };

  const handleCityChange = val => {
    const cityData = allCities.find(el => el.city === val);
    form.setFieldsValue({ ProvState: cityData?.province_id });
    setTimeout(() => {
      handleProvinceChange(cityData?.province_id);
    }, 10);
  };

  const handleClickDelete = () => {
    if (!projectCount && !user.length) {
      deleteAgencyCall({ id: editData?.id }, () => {
        closeModal();
        setTriggerRequest(true);
      });
    }
  };

  const footer = (
    <Row justify='space-between' align='middle'>
      <Col align='center'>
        <Spin spinning={addAgencyLoading || editAgencyLoading || loading}>
          <Button
            onClick={() => {
              onFinish();
            }}
            type='light_blue'
            icon={<PlusCircleOutlined />}
            {...(isSale
              ? {
                  type: 'sales_blue',
                  back_color: darkBlue,
                  height: '25px',
                  width: '130px',
                }
              : {})}
            disabled={!!editData?.id}
            color={!editData?.id && activeColor}
          >
            Broker Agent
          </Button>
        </Spin>
        {editData?.id && !isSale && (
          <Spin spinning={DeleteLoading || userLoading}>
            <Button
              type='light_blue'
              ml='11px'
              onClick={() => handleClickDelete()}
              icon={<DeleteOutlined />}
              color={activeColor}
            >
              DELETE
            </Button>
          </Spin>
        )}
      </Col>
      <Col align='center'>
        <Spin spinning={editAgencyLoading || addAgencyLoading || loading}>
          <Button
            icon={<SaveOutlined />}
            type='light_blue'
            color={activeColor}
            onClick={() => {
              onFinish(true);
            }}
            {...(isSale
              ? { type: 'sales_blue', back_color: darkBlue, height: '25px', width: '130px' }
              : {})}
          >
            SAVE
          </Button>
        </Spin>
        <Button
          type='light_blue'
          color={activeColor}
          onClick={() =>
            confirm({
              title: 'Are you sure you want to leave this form?',
              icon: <ExclamationCircleOutlined />,
              content: 'All changes will be lost!',
              cancelText: 'Cancel',
              okText: 'OK',
              maskClosable: true,
              okButtonProps: {
                style: { backgroundColor: activeColor },
              },
              onOk() {
                closeModal();
              },
              onCancel() {},
            })
          }
          icon={<CloseCircleOutlined />}
          ml='10px'
          {...(isSale
            ? { type: 'sales_blue', back_color: darkBlue, height: '25px', width: '130px' }
            : {})}
        >
          CANCEL
        </Button>
      </Col>
    </Row>
  );

  return (
    <ModalWrapper closeModal={closeModal} showConfirm={showConfirm} isEdit={!!editData?.id}>
      <ModalContent
        customWidth='700px'
        type='blue'
        title={title}
        subTitle={subTitle}
        closeModal={closeModal}
        footer={footer}
        showConfirm={showConfirm}
        isEdit={!!editData?.id}
        modalProps={{ title_back_color: isSale ? '#03215F' : activeColor }}
      >
        <Form
          type={isSale ? 'orange' : 'blue_popup'}
          form={form}
          layout='vertical'
          onFinish={onFinish}
          onValuesChange={() => setShowConfirm(true)}
          active_color={activeColor}
        >
          <Row padding='15px 30px'>
            <Collapse
              type='blue'
              defaultActiveKey={['Contact', 'Logo', 'Support']}
              expandIconPosition='right'
              ghost={false}
              head_back_color={liteActiveColor}
              arrow_color={activeColor}
              head_padding='5px 11px'
              expandIcon={rec => <UpCircleOutlined rotate={rec.isActive ? 0 : 180} />}
            >
              <Panel
                header={
                  <Title fz='12px' fw='600' lh='18px' color='#595859' mb='0'>
                    General | Contact
                  </Title>
                }
                key='Contact'
              >
                <Row gutter={[20, 5]} justify='space-between'>
                  <Col span={15} border_right='1px solid #C0BFBF'>
                    <Row gutter={[20, 5]} justify='space-between'>
                      <Col span={12}>
                        <FormItem
                          mb='0'
                          label='First Name'
                          name='FirstName'
                          rules={[{ required: true, message: 'First Name is required!' }]}
                        >
                          <Input />
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem
                          mb='0'
                          label='Last Name'
                          name='LastName'
                          rules={[{ required: true, message: 'Last Name is required!' }]}
                        >
                          <Input />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem mb='0' label='Brokerage' name='BrokerAgency_id'>
                          <Select
                            showSearch={true}
                            filterOption={filterOption}
                            allowClear={true}
                            {...(isSale
                              ? {
                                  type: 'sale',
                                  clear_right: '30px',
                                  clear_opacity: '1',
                                }
                              : {})}
                            onClear={() => setAllOnClear(form, 'BrokerAgency_id', null)}
                          >
                            {brokerage.map(el => (
                              <Select.Option value={el.id} key={el.id}>
                                {el?.AgencyName}
                              </Select.Option>
                            ))}
                          </Select>
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem
                          mb='0'
                          label='Phone'
                          name='Phone'
                          rules={[
                            {
                              pattern: phonePattern,
                              message: 'Phone is not valid!',
                            },
                          ]}
                        >
                          <MaskedInput mask={phoneMask} />
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem
                          mb='0'
                          label='Mobile'
                          name='Mobile'
                          rules={[
                            {
                              pattern: phonePattern,
                              message: 'Mobile is not valid!',
                            },
                          ]}
                        >
                          <MaskedInput mask={phoneMask} />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          mb='0'
                          label='Email'
                          name='Email'
                          rules={[{ type: 'email', message: 'Email is not valid!' }]}
                        >
                          <InputEmail />
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={9}>
                    <Row gutter={[20, 5]}>
                      <Col span={24}>
                        <FormItem mb='0' label='Designation'>
                          <Input disabled={true} />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          mb='0'
                          label='RECO Number'
                          name='RECONumber'
                          rules={[{ required: true, message: 'RECO Number is required!' }]}
                        >
                          <Input maxLength={10} />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem mb='0' label='Broker Class' name='BrokerType_id'>
                          <Select
                            showSearch={true}
                            filterOption={filterOption}
                            allowClear={true}
                            {...(isSale
                              ? {
                                  type: 'sale',
                                  clear_right: '30px',
                                  clear_opacity: '1',
                                }
                              : {})}
                            onClear={() => setAllOnClear(form, 'BrokerType_id', null)}
                          >
                            {unitStatuses.map(el => (
                              <Select.Option value={el.id} key={el.id}>
                                {el?.value}
                              </Select.Option>
                            ))}
                          </Select>
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          label='Website'
                          name='WebSiteURL'
                          rules={[{ type: 'url', message: 'URL is not valid!' }]}
                        >
                          <InputUrl />
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Panel>
              <Panel
                header={
                  <Title fz='12px' fw='600' lh='18px' color='#595859' mb='0'>
                    Address | Logo
                  </Title>
                }
                key='Logo'
              >
                <Row gutter={[20, 5]} justify='space-between'>
                  <Col span={12} border_right='1px solid #C0BFBF'>
                    <Row gutter={[20, 5]}>
                      <Col span={24}>
                        <FormItem label='Address Line 1' name='Address1'>
                          <Input addonBefore={<EnvironmentOutlined />} maxLength={50} />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem label='Address Line 2' name='Address2'>
                          <Input addonBefore={<EnvironmentOutlined />} maxLength={50} />
                        </FormItem>
                      </Col>
                      <Col span={13}>
                        <FormItem label='City' name='City'>
                          <Select
                            showSearch={true}
                            filterOption={filterOption}
                            allowClear={true}
                            onChange={handleCityChange}
                            {...(isSale
                              ? {
                                  type: 'sale',
                                  clear_right: '30px',
                                  clear_opacity: '1',
                                }
                              : {})}
                            onClear={() => setAllOnClear(form, 'City', null)}
                          >
                            {cityList.map(el => (
                              <Select.Option value={el.city} key={el.city}>
                                {el.city}
                              </Select.Option>
                            ))}
                          </Select>
                        </FormItem>
                      </Col>
                      <Col span={11}>
                        <FormItem label='Prov | State' name='ProvState'>
                          <Select
                            showSearch={true}
                            onChange={handleProvinceChange}
                            {...(isSale
                              ? {
                                  type: 'sale',
                                  clear_right: '30px',
                                  clear_opacity: '1',
                                  allowClear: true,
                                }
                              : {})}
                            onClear={() => setAllOnClear(form, 'ProvState', null)}
                          >
                            {Object.keys(provinceCityList).map(el => (
                              <Select.Option value={el} key={el}>
                                {el}
                              </Select.Option>
                            ))}
                          </Select>
                        </FormItem>
                      </Col>
                      <Col span={13}>
                        <FormItem label='Country' name='Country'>
                          <Select
                            {...(isSale
                              ? {
                                  type: 'sale',
                                  clear_right: '30px',
                                  clear_opacity: '1',
                                  allowClear: true,
                                }
                              : {})}
                            onClear={() => setAllOnClear(form, 'Country', null)}
                          >
                            <Option value='Canada'>Canada</Option>
                          </Select>
                        </FormItem>
                      </Col>
                      <Col span={11}>
                        <FormItem
                          label='Postal | Zip'
                          name='ZipPostal'
                          rules={[
                            {
                              pattern: postalPattern,
                              message: 'Postal is not valid!',
                            },
                          ]}
                        >
                          <MaskedInput mask={postalMask} />
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>
                  <ModalContentUpload
                    uploadedFile={uploadedFile}
                    uploadFile={uploadFile}
                    setUploadedFile={setUploadedFile}
                    imageSize={80}
                    disableUpload={true}
                    onGenerateClick={onGenerateClick}
                    uploadProps={
                      isSale ? { type: 'sales_blue', back_color: darkBlue, height: '25px' } : {}
                    }
                  />
                </Row>
              </Panel>
              <Panel
                header={
                  <Title fz='12px' fw='600' lh='18px' color='#595859' mb='0'>
                    Notes | Support
                  </Title>
                }
                key='Support'
                className='Support'
              >
                <FormItem name='Notes'>
                  <TextArea style={{ marginTop: '10px' }} autoSize={{ minRows: 2, maxRows: 3 }} />
                </FormItem>
              </Panel>
            </Collapse>
          </Row>
        </Form>
      </ModalContent>
    </ModalWrapper>
  );
};

export default CoBrokeAgentsClientsModal;
