import styled, { css } from 'styled-components';
import { Form as form } from 'antd';
import { darkBlue, mainTextColor, salesMainColor } from 'constants/colors';

const Form = styled(form)`
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless) input,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless)
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless)
    .ant-input-affix-wrapper,
  .ant-form-item-has-error
    :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless)
    :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless)
    .ant-input-number-affix-wrapper,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
  .ant-form-item-has-error:not(.ant-input-affix-wrapper-disabled):not(
      .ant-input-affix-wrapper-borderless
    ).ant-input-affix-wrapper:hover,
  .ant-form-item-has-error
    :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless)
    .ant-input-number-affix-wrapper:hover {
    border-color: #ff4d4f !important;
  }

  ${props =>
    props.type === 'decor_filters' &&
    css`
      & input,
      & .ant-input-number,
      & .ant-select-selector,
      & .ant-picker,
      & .ant-input-affix-wrapper,
      & textarea,
      & .ant-input-number {
        border-color: #d9d9d9;
        box-shadow: none !important;
      }
    `}
  ${props =>
    props.type === 'blue_popup' &&
    css`
      & .ant-form-item .ant-form-item-label {
        padding-bottom: 0 !important;

        label {
          font-size: 12px;
          margin-bottom: 0 !important;
        }
      }

      .ant-form-item:focus-within .ant-form-item-label > label {
        color: ${mainTextColor} !important;

        &:before {
          color: ${mainTextColor} !important;
        }
      }

      & input,
      & .ant-input-number,
      & .ant-select-selector,
      & .ant-picker,
      & .ant-input-affix-wrapper,
      & textarea,
      & .ant-input-number,
      & .ant-input-number-affix-wrapper {
        width: 100% !important;
        border-color: rgb(238, 238, 238) !important;
        box-shadow: none !important;
      }

      & input:focus,
      & input:hover,
      & textarea:focus,
      & textarea:hover,
      & .ant-input-affix-wrapper:focus,
      & .ant-input-affix-wrapper:hover,
      & .ant-picker:focus,
      & .ant-picker:hover,
      & .ant-input-number:focus,
      & .ant-input-number:hover,
      & .ant-select-selector:focus,
      & .ant-select-selector:hover {
        border-color: rgb(0, 75, 220) !important;
      }

      & .ant-input-group-addon,
      & .ant-picker-suffix {
        color: rgba(89, 89, 89, 1) !important;
        font-size: 13px;
        padding: 0 10px !important;
      }

      & .ant-input-group-addon,
      & .ant-picker-input .ant-picker-suffix {
        border-color: #eeeeee;
      }

      & .ant-form-item-explain-error {
        white-space: pre;
        padding-top: 2px;
      }

      & .ant-input-group:focus-within,
      & .ant-input-group:hover {
        & .ant-input-affix-wrapper,
        & > .ant-input {
          border: 1px solid rgb(0, 75, 220);
        }

        & > .ant-input-group-addon,
        & .ant-picker-suffix {
          border: 1px solid rgb(0, 75, 220) !important;
          border-right: none !important;
        }
      }

      & .ant-input-disabled {
        background: transparent !important;
      }

      & .ant-input-group-wrapper {
        padding: 0 !important;
        border: none !important;
      }

      & .ant-form-item-explain {
        font-size: 11px !important;
      }

      .ant-select-multiple .ant-select-selection-item {
        font-size: 12px;
        height: 22px;
        margin-top: 0;
        margin-bottom: 1px;
      }

      & .ant-form-item label {
        font-weight: 500 !important;
        font-size: 12px !important;
        height: 18px !important;
      }

      & .ant-form-item-label {
        max-height: 20px !important;
      }

      .ant-select-selection-overflow {
        top: -6px;

        .ant-select-selection-item {
          height: 18px;
          align-items: center;
        }
      }

      & .ant-form-item {
        margin-bottom: 0 !important;
      }

      & .ant-form-item-control-input-content > input:not(textarea),
      & .ant-input-wrapper,
      & .ant-input-number,
      & .ant-select-selector,
      & .ant-picker,
      & .ant-input-group-wrapper,
      & .ant-input-affix-wrapper,
      & .ant-picker .ant-picker-input .ant-picker-suffix,
      & .ant-input-number,
      & .ant-input-number-affix-wrapper {
        height: 25px !important;
        font-weight: 500 !important;
        font-size: 12px !important;
        align-items: center;
      }

      & .ant-input-wrapper input {
        font-weight: 500 !important;
        font-size: 12px !important;
        align-items: center;
        height: 25px;
      }

      & .ant-input-password input {
        height: 22px !important;
      }

      & .ant-input,
      & .ant-input-password,
      & .ant-select-selector {
        padding: 4px 8px !important;
      }

      & .ant-select-selector .ant-select-selection-search {
        left: 8px;
      }

      & .ant-picker-suffix {
        margin-right: 8px !important;
      }

      & .ant-input-number-affix-wrapper .ant-input-number {
        height: 22px !important;
      }

      & .ant-picker input,
      & textarea {
        font-weight: 500 !important;
        font-size: 12px !important;
        align-items: center;
      }

      .ant-picker .ant-picker-input .ant-picker-suffix {
        display: flex;
        height: 100% !important;
      }

      & .ant-form-item-explain {
        font-size: 10px !important;
      }
    `}
  ${props =>
    props.type === 'black_popup' &&
    css`
      & .ant-checkbox-checked .ant-checkbox-inner,
      & .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        background-color: ${props.active_color} !important;
      }

      & input:focus,
      & input:hover,
      & textarea:focus,
      & textarea:hover,
      & .ant-input-affix-wrapper:focus,
      & .ant-input-affix-wrapper:hover,
      & .ant-picker:focus,
      & .ant-picker:hover,
      & .ant-input-number:focus,
      & .ant-input-number:hover,
      & .ant-select-selector:focus,
      & .ant-input-number-affix-wrapper:hover,
      & .ant-input-number-affix-wrapper:focus,
      &
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector,
      & .ant-select-selector:hover {
        border-color: ${props.active_color} !important;
      }

      .ant-form-item:focus-within .ant-form-item-label > label {
        color: ${props.active_color} !important;

        &:before {
          color: ${props.active_color} !important;
        }
      }

      & .ant-select-clear {
        right: 8px;
      }

      & .ant-form-item,
      & .ant-form-item-row,
      & .ant-form-item-control-input {
        height: 100% !important;
      }

      & .ant-picker-suffix {
        display: flex;
        height: 100% !important;
        border-color: ${props.active_color} !important;
        color: rgba(89, 89, 89, 1) !important;
        font-size: 13px;
        padding: 0 5px;
      }

      & .ant-select-arrow .anticon::after {
        right: 0;
      }

      & .ant-select-arrow {
        right: 8px;
      }

      & .ant-form-item .ant-form-item-label {
        padding-bottom: 0 !important;

        label {
          font-size: 12px;
          margin-bottom: 0 !important;
        }
      }

      & input,
      & .ant-input-number,
      & .ant-select-selector,
      & .ant-picker,
      & .ant-input-affix-wrapper,
      & textarea,
      & .ant-input-number,
      & .ant-input-number-affix-wrapper {
        width: 100% !important;
        box-shadow: none !important;
      }

      & .ant-input-group-addon {
        color: rgba(89, 89, 89, 1) !important;
        font-size: 13px;
        padding: 0 10px !important;
      }

      & .ant-input-group-addon,
      & .ant-picker-input .ant-picker-suffix,
      & .ant-checkbox-inner {
        border-color: #eeeeee !important;
      }

      & .ant-form-item-explain-error {
        white-space: pre;
        padding-top: 2px;
      }

      & .ant-input-disabled {
        background: transparent !important;
      }

      & .ant-input-group-wrapper {
        padding: 0 !important;
        border: none !important;
      }

      & .ant-form-item-explain {
        font-size: 11px !important;
      }

      .ant-select-multiple .ant-select-selection-item {
        font-size: 12px;
        height: 22px;
        margin-top: 0;
        margin-bottom: 1px;
      }

      & .ant-form-item label {
        font-weight: 500 !important;
        font-size: 12px !important;
        height: 18px !important;
      }

      & .ant-form-item-label {
        max-height: 20px !important;
      }

      .ant-select-selection-overflow {
        top: -6px;

        .ant-select-selection-item {
          height: 18px;
          align-items: center;
        }
      }

      & .ant-form-item {
        margin-bottom: 0 !important;
      }

      & .ant-form-item-control-input-content > input:not(textarea),
      & .ant-input-wrapper,
      & .ant-input-number,
      & .ant-select-selector,
      & .ant-picker,
      & .ant-input-group-wrapper,
      & .ant-input-affix-wrapper,
      & .react-tel-input,
      & .react-tel-input input,
      & .ant-input-number-affix-wrapper {
        &:not(.custom-height) {
          height: 25px !important;
          font-weight: 400 !important;
          font-size: 12px !important;
          align-items: center;
        }
      }

      .ant-input-affix-wrapper .ant-input {
        height: 22px !important;
      }

      & .ant-checkbox-inner,
      & .ant-picker {
        border-color: #c9c9c9;
      }

      & .custom_height {
        font-weight: 500 !important;
        font-size: 12px !important;
        align-items: center;
        height: 100% !important;
      }

      & .remove_addon_style {
        padding: 0 5px 0 0;
        width: 100%;
        border: none;

        & .ant-input-number-wrapper {
          height: 100%;
          height: 100%;
          display: flex;
          align-items: center;
        }

        & .ant-input-number-group-addon {
          border: none;
          background: none;
        }
      }

      & .ant-input-wrapper input {
        font-weight: 500 !important;
        font-size: 12px !important;
        align-items: center;
        height: 25px;
      }

      & .ant-input-password input {
        height: 22px !important;
      }

      & .ant-input,
      & .ant-input-password,
      & .ant-select-selector {
        padding: 4px 8px !important;
      }

      & .ant-select-selector .ant-select-selection-search {
        left: 8px;
      }

      & .ant-input-number-affix-wrapper .ant-input-number {
        height: 22px !important;
      }

      & .ant-picker input,
      & textarea {
        font-weight: 500 !important;
        font-size: 12px !important;
        align-items: center;
      }

      & .ant-form-item-explain {
        font-size: 10px !important;
      }

      & .react-tel-input {
        input {
          padding: 0 10px 0 42px;
          border-radius: 2px;
        }

        .flag-dropdown {
          border-radius: 2px 0 0 2px;

          .selected-flag {
            width: 35px;
            padding: 0 0 0 5px;
            border-radius: 2px 0 0 2px;
          }
        }
      }
    `}
  ${props =>
    props.type === 'blue' &&
    css`
      & .ant-form-item .ant-form-item-label {
        padding-bottom: 0 !important;

        label {
          font-size: 13px !important;
          color: rgb(128, 128, 128) !important;
          margin-bottom: 0 !important;
        }
      }

      .ant-form-item:focus-within .ant-form-item-label > label {
        color: ${mainTextColor} !important;

        &:before {
          color: ${mainTextColor} !important;
        }
      }

      & input,
      & .ant-input-number,
      & .ant-select-selector,
      & .ant-picker,
      & .ant-input-affix-wrapper,
      & textarea,
      & .ant-input-number {
        width: 100% !important;
        border-color: rgb(238, 238, 238) !important;
        box-shadow: none !important;
      }

      & input:focus,
      & input:hover,
      & textarea:focus,
      & textarea:hover,
      & .ant-input-affix-wrapper:focus,
      & .ant-input-affix-wrapper:hover,
      & .ant-picker:focus,
      & .ant-picker:hover,
      & .ant-input-number:focus,
      & .ant-input-number:hover,
      & .ant-select-selector:focus,
      & .ant-select-selector:hover {
        border-color: rgb(0, 75, 220) !important;
      }

      & .ant-picker-focused,
      & .ant-picker:hover {
        .ant-picker-suffix {
          border-right: 1px solid rgb(0, 75, 220) !important;
        }
      }

      & .ant-input-group-addon,
      & .ant-picker-suffix {
        color: rgba(89, 89, 89, 1) !important;
        font-size: 13px;
        padding: 0 10px !important;
      }

      & .ant-form-item-explain-error {
        white-space: pre;
        //padding-top: 2px;
        padding-top: 0;
        font-size: 13px;
      }

      & .ant-input-disabled:not(.filter_input) {
        background: transparent !important;
      }

      & .ant-input-group-wrapper {
        padding: 0 !important;
        border: none !important;
      }

      & .ant-form-item-explain {
        font-size: 13px !important;
      }

      & .ant-form-item {
        margin-bottom: 6px;

        & input,
        & .ant-input:not(textarea),
        & .ant-select-selector,
        & .ant-input-group,
        & .ant-picker,
        & .ant-picker-input,
        & .ant-input-group-wrapper,
        & .ant-input-number {
          height: 30px !important;

          & input {
            height: 30px !important;
          }
        }

        & .ant-input-affix-wrapper {
          height: 30px !important;

          & input,
          & .ant-input {
            height: 100% !important;
          }
        }

        .ant-select-multiple .ant-select-selection-item {
          font-size: 12px;
          height: 22px;
          margin-top: 0;
          margin-bottom: 1px;
        }
      }
    `}
  ${props =>
    props.type === 'orange' &&
    css`
      & .orange_outlined.ant-checkbox-wrapper .ant-checkbox-inner,
      & .orange_outlined.ant-input,
      & .orange_outlined.ant-picker,
      & .orange_outlined .ant-select-selector,
      & .orange_outlined .ant-checkbox-inner,
      & .orange_outlined.ant-btn,
      & .orange_outlined.ant-input:hover,
      & .orange_outlined:hover .ant-select-selector,
      & .orange_outlined.ant-btn:hover,
      & .orange_outlined.ant-input:focus,
      & .orange_outlined:focus,
      & .orange_outlined.ant-btn:focus,
      & .orange_outlined.ant-select-focused .ant-select-selector {
        border: 1px solid ${salesMainColor} !important;
        border-color: ${salesMainColor} !important;
      }

      & .ant-input-group-wrapper.orange_outlined {
        & input,
        & .ant-input-group-addon {
          border-color: ${salesMainColor} !important;
        }
      }

      & .ant-checkbox-checked .ant-checkbox-inner,
      & .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        background-color: ${darkBlue} !important;
      }

      & input:focus,
      & input:hover,
      & textarea:focus,
      & textarea:hover,
      & .ant-input-affix-wrapper:focus,
      & .ant-input-affix-wrapper:hover,
      & .ant-picker:focus,
      & .ant-picker:hover,
      & .ant-input-number:focus,
      & .ant-input-number:hover,
      & .ant-select-selector:focus,
      & .ant-input-number-affix-wrapper:hover,
      & .ant-input-number-affix-wrapper:focus,
      &
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector,
      & .ant-select-selector:hover {
        border-color: ${darkBlue} !important;
      }

      & .ant-select-clear,
      & .ant-select-arrow,
      & .ant-picker-suffix,
      & .ant-picker-clear {
        color: #000000d9 !important;
      }
      & .ant-select-suffix::after {
        border-top-color: #000000d9 !important;
      }
      & label {
        color: #000000d9 !important;
      }

      & .ant-input-disabled,
      & .ant-select-disabled .ant-select-selection-item {
        color: #000000d9 !important;
      }

      .ant-form-item:focus-within .ant-form-item-label > label {
        color: ${darkBlue} !important;

        &:before {
          color: ${darkBlue} !important;
        }
      }

      & .orange_outlined .ant-select-clear {
        right: 8px;
      }

      & .ant-input-wrapper .ant-input-affix-wrapper input {
        border: none;
      }

      & .ant-form-item,
      & .ant-form-item-row,
      & .ant-form-item-control-input {
        height: 100% !important;
      }

      & .orange_outlined .ant-picker-suffix {
        display: flex;
        height: 100% !important;
        border-color: ${salesMainColor} !important;
        color: rgba(89, 89, 89, 1) !important;
        font-size: 13px;
        padding: 0 5px;
      }

      & .orange_outlined .ant-select-selection-search {
        border-right: 1px solid ${salesMainColor} !important;
      }

      & .orange_outlined .ant-select-arrow .anticon::after {
        right: 0;
      }

      & .ant-select-arrow {
        right: 8px;
      }

      & .ant-form-item .ant-form-item-label {
        padding-bottom: 0 !important;

        label {
          font-size: 12px;
          margin-bottom: 0 !important;
        }
      }

      & input,
      & .ant-input-number,
      & .ant-select-selector,
      & .ant-picker,
      & .ant-input-affix-wrapper,
      & textarea,
      & .ant-input-number,
      & .ant-input-number-affix-wrapper {
        width: 100% !important;
        box-shadow: none !important;
      }

      & .ant-input-group-addon {
        color: rgba(89, 89, 89, 1) !important;
        font-size: 13px;
        padding: 0 10px !important;
      }

      & .ant-input-group-addon,
      & .ant-picker-input .ant-picker-suffix,
      & .ant-checkbox-inner {
        border-color: #eeeeee !important;
      }

      & .ant-form-item-explain-error {
        white-space: pre;
        padding-top: 2px;
      }

      & .ant-input-disabled {
        background: transparent !important;
      }

      & .ant-input-group-wrapper {
        padding: 0 !important;
        border: none !important;
      }

      & .ant-form-item-explain {
        font-size: 11px !important;
      }

      .ant-select-multiple .ant-select-selection-item {
        font-size: 12px;
        height: 22px;
        margin-top: 0;
        margin-bottom: 1px;
      }

      & .ant-form-item label {
        font-weight: 500 !important;
        font-size: 12px !important;
        &:not(.ant-checkbox-wrapper) {
          height: 18px !important;
        }
        &.ant-checkbox-wrapper {
          height: 25px !important;
          & .ant-checkbox {
            font-size: 0px !important;
          }
        }
      }

      .ant-select-selection-overflow {
        top: -6px;

        .ant-select-selection-item {
          height: 18px;
          align-items: center;
        }
      }

      & .ant-form-item {
        margin-bottom: 0 !important;
      }

      & .ant-form-item-control-input-content > input:not(textarea),
      & .ant-input-wrapper,
      & .ant-input-number,
      & .ant-select-selector,
      & .ant-picker,
      & .ant-input-group-wrapper,
      & .ant-input-affix-wrapper,
      & .react-tel-input,
      & .react-tel-input input,
      & .ant-input-number-affix-wrapper {
        &:not(.custom-height) {
          height: 25px !important;
          font-weight: 400 !important;
          font-size: 12px !important;
          align-items: center;
        }
      }

      & .ant-checkbox-inner,
      & .ant-picker {
        border-color: #c9c9c9;
      }

      & .custom_height {
        font-weight: 500 !important;
        font-size: 12px !important;
        align-items: center;
        height: 100% !important;
      }

      & .remove_addon_style {
        padding: 0 5px 0 0;
        width: 100%;
        border: none;

        & .ant-input-number-wrapper {
          height: 100%;
          height: 100%;
          display: flex;
          align-items: center;
        }

        & .ant-input-number-group-addon {
          border: none;
          background: none;
        }
      }

      & .ant-input-wrapper input {
        font-weight: 500 !important;
        font-size: 12px !important;
        align-items: center;
        height: 25px;
      }

      & .ant-input-password input {
        height: 22px !important;
      }

      & .ant-input,
      & .ant-input-password,
      & .ant-select-selector {
        padding: 4px 8px !important;
      }

      & .ant-select-selector .ant-select-selection-search {
        left: 8px;
      }

      & .ant-input-number-affix-wrapper .ant-input-number {
        height: 22px !important;
      }

      & .ant-picker input,
      & textarea {
        font-weight: 500 !important;
        font-size: 12px !important;
        align-items: center;
      }

      & .ant-form-item-explain {
        font-size: 10px !important;
      }

      & .react-tel-input {
        input {
          padding: 0 10px 0 42px;
          border-radius: 2px;
        }

        .flag-dropdown {
          border-radius: 2px 0 0 2px;

          .selected-flag {
            width: 35px;
            padding: 0 0 0 5px;
            border-radius: 2px 0 0 2px;
          }
        }
      }
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
    ${props =>
    props.active_color &&
    css`
      & .ant-checkbox-checked .ant-checkbox-inner,
      & .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        background-color: ${props.active_color} !important;
        border-color: ${props.active_color} !important;
        box-shadow: none !important;
      }

      & .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover {
        & .ant-checkbox::after {
          border-color: ${props.active_color} !important;
        }
        & .ant-checkbox-inner {
          border-color: ${props.active_color} !important;
          box-shadow: none !important;
        }
      }

      & .ant-input-number {
        .ant-input-number-handler:hover .ant-input-number-handler-down-inner,
        .ant-input-number-handler:hover .ant-input-number-handler-up-inner {
          color: ${props.active_color} !important;
        }
      }
      & .ant-switch {
        border-color: ${props.active_color} !important;
        box-shadow: none !important;
      }
      & .ant-switch-checked {
        background-color: ${props.active_color} !important;
      }
      & .ant-checkbox:not(.ant-checkbox-disabled):focus-within,
      & .ant-checkbox:not(.ant-checkbox-disabled):hover {
        &::after {
          border-color: ${props.active_color} !important;
        }
        .ant-checkbox-inner {
          border-color: ${props.active_color} !important;
          box-shadow: none !important;
        }
      }

      .ant-radio-checked .ant-radio-inner {
        border-color: ${props.active_color} !important;
        &::after {
          background-color: ${props.active_color} !important;
        }
      }
      & input:focus,
      & input:hover,
      & textarea:focus,
      & textarea:hover,
      & .ant-radio:hover .ant-radio-inner,
      & .ant-input-affix-wrapper:focus,
      & .ant-input-affix-wrapper:hover,
      & .ant-picker:focus,
      & .ant-picker:hover,
      & .ant-input-number:focus,
      & .ant-input-number:hover,
      & .ant-select-selector:focus,
      & .ant-input-number-affix-wrapper:hover,
      & .ant-input-number-affix-wrapper:focus,
      &
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector,
      & .ant-select-selector:hover {
        border-color: ${props.active_color} !important;
      }
      .ant-form-item:focus-within .ant-form-item-label > label {
        color: ${props.active_color} !important;

        &:before {
          color: ${props.active_color} !important;
        }
      }
      & .ant-input-affix-wrapper:focus,
      & .ant-input-affix-wrapper:hover,
      & .ant-input-group:focus-within {
        & .ant-input-suffix {
          border-color: ${props.active_color} !important;
        }
      }

      & .ant-input-group-wrapper:focus,
      & .ant-input-group-wrapper:hover,
      & .ant-input-group:focus-within {
        & .ant-input-group-addon,
        & .ant-input-affix-wrapper,
        & .ant-input-affix-wrapper {
          border-color: ${props.active_color} !important;
        }
      }

      .ant-input-wrapper:hover,
      .ant-input-wrapper:focus,
      .ant-input-group:focus-within {
        .ant-input-group-addon,
        input {
          border-color: ${props.active_color} !important;
        }
      }

      .ant-btn:not(.select-action):not([disabled]) {
        background-color: ${props.active_color} !important;
        color: white !important;
      }
    `}
    ${props =>
    props.field_height &&
    css`
      & .ant-form-item-control-input-content > input:not(textarea),
      & .ant-input-wrapper,
      & .ant-input-number,
      & .ant-select-selector,
      & .ant-picker,
      & .ant-input-group-wrapper,
      & .ant-input-affix-wrapper,
      & .react-tel-input,
      & .react-tel-input input,
      & .ant-input-number-affix-wrapper {
        &:not(.custom-height) {
          height: ${props.field_height} !important;

          & input {
            height: ${props.field_height} !important;
          }
        }
      }

      & .ant-input-affix-wrapper {
        &:not(.custom-height) {
          height: ${props.field_height} !important;

          & input,
          & .ant-input {
            height: 100% !important;
          }
        }
      }
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.overflow &&
    css`
      overflow: ${props.overflow};
    `}
  ${props =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}
  ${props =>
    props.explain_fz &&
    css`
      & .ant-form-item-explain {
        font-size: ${props.explain_fz} !important;
      }
    `}
  ${props =>
    props.explain_bottom &&
    css`
      & .ant-form-item-explain {
        bottom: ${props.explain_bottom} !important;
      }
    `}
  ${props =>
    props.label_fz &&
    css`
      .ant-form-item label {
        font-size: ${props.label_fz} !important;
      }
    `}
  ${props =>
    props.label_fw &&
    css`
      .ant-form-item label {
        font-weight: ${props.label_fw} !important;
      }
    `}
  ${props =>
    props.label_align &&
    css`
      .ant-form-item .ant-form-item-label {
        text-align: ${props.label_align} !important;
      }
    `}
  ${props =>
    props.label_height &&
    css`
      .ant-form-item-control-input-content {
        display: flex;
      }
      .ant-form-item .ant-form-item-label {
        height: ${props.label_height} !important;

        & label {
          height: 100% !important;
        }
      }
    `}

  ${props =>
    props.label_max_height &&
    css`
      .ant-form-item-label {
        max-height: ${props.label_max_height} !important;
      }
    `}
  ${props =>
    props.label_height &&
    css`
      .ant-form-item label {
        height: ${props.label_height};
      }
    `}
  ${props =>
    props.label_active_color &&
    css`
      .ant-form-item:focus-within .ant-form-item-label > label {
        color: ${props.label_active_color} !important;

        &:before {
          color: ${props.label_active_color} !important;
        }
      }
    `}
  ${props =>
    (props.item_mb || props.item_mb === 0) &&
    css`
      .ant-form-item {
        margin-bottom: ${props.item_mb}px !important;
      }
    `}
  ${props =>
    (props.error_mt || props.error_mt === 0) &&
    css`
      .ant-form-item-explain-error {
        margin-top: ${props.error_mt}px!important;
      }
    `}
`;

export default Form;
