import styled, { css } from 'styled-components';
import { Row as row } from 'antd';

const Row = styled(row)`
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px!important;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
    ${props =>
    props.ml &&
    css`
      margin-left: ${props.ml}!important;
    `}
    ${props =>
    props.mr &&
    css`
      margin-right: ${props.mr}!important;
    `}
  ${props =>
    props.border &&
    css`
      border: ${props.border};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
  ${props =>
    props.display &&
    css`
      display: ${props.display};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding}!important;
    `}
  ${props =>
    props.pb &&
    css`
      padding-bottom: ${props.pb}!important;
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width}!important;
    `}

  ${props =>
    props.height &&
    css`
      height: ${props.height}!important;
    `}
  ${props =>
    props.min_height &&
    css`
      min-height: ${props.min_height}!important;
    `}
  ${props =>
    props.max_height &&
    css`
      max-height: ${props.max_height}!important;
    `}
  ${props =>
    props.position &&
    css`
      position: ${props.position}!important;
    `}
  ${props =>
    props.flex &&
    css`
      flex: ${props.flex}!important;
    `}
    ${props =>
    props.flex_direction &&
    css`
      flex-direction: ${props.flex_direction};
    `}
  ${props =>
    props.overflow &&
    css`
      overflow: ${props.overflow}!important;
    `}
  ${props =>
    props.overflow_y &&
    css`
      overflow-y: ${props.overflow_y}!important;
    `}
  ${props =>
    props.back_color &&
    css`
      background-color: ${props.back_color}!important;
    `}
  ${props =>
    props.border_bot &&
    css`
      border-bottom: ${props.border_bot};
    `}
  ${props =>
    props.border_top &&
    css`
      border-top: ${props.border_top};
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.odd_back &&
    css`
      &:nth-child(odd) {
        background-color: ${props.odd_back};
      }
    `}
    ${props =>
    props.lg_padding &&
    css`
      @media screen and (max-width: 992px) {
        padding: ${props.lg_padding}!important;
      }
    `}
  ${props =>
    props.scrollbar === 'thin' &&
    css`
      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        background: #f3f3f3;
      }

      &::-webkit-scrollbar-thumb {
        background: #c6c6c6;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #d8d8d8;
      }
      /* Works on Firefox */
      scrollbar-width: thin;
      scrollbar-color: #c6c6c6 #f3f3f3;
    `}
`;

/** @component */
export default Row;
