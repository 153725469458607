import client from './main-client';

export const fetchAllDecorOptionsApi = params => client().get('/project-decor-options', { params });
export const fetchAllDecorOptionsCountApi = params =>
  client().get('/project-decor-options/count', { params });
export const fetchOneDecorOptionsApi = ({ id, ...params }) =>
  client().get(`/project-decor-options/${id}`, { params });
export const updateDecorOptionsApi = params =>
  client().put(`/project-decor-options/${params.id}`, params.values);
export const addDecorOptionsApi = data => client().post('/project-decor-options', data);
export const deleteDecorOptionsApi = params =>
  client().delete(`/project-decor-options/${params.id}`, { params });

export const fetchAllFloorPremiumsApi = params => client().get('/project-floor-levels', { params });
export const fetchAllFloorPremiumsCountApi = params =>
  client().get('/project-floor-levels/count', { params });
export const addFloorPremiumsApi = data => client().post('/project-floor-levels', data);
export const updateFloorPremiumsApi = params =>
  client().put(`/project-floor-levels/${params.id}`, params.values);
export const deleteFloorPremiumsApi = params =>
  client().delete(`/project-floor-levels/${params.id}`, { params });

export const fetchAllProjectFinishesApi = params =>
  client().get('/project-decor-selects', { params });
export const fetchAllProjectFinishesCountApi = params =>
  client().get('/project-decor-selects/count', { params });
export const addProjectFinishesApi = data => client().post('/project-decor-selects', data);
export const updateProjectFinishApi = params =>
  client().put(`/project-decor-selects/${params.id}`, params.values);
export const deleteProjectFinishesApi = params =>
  client().delete(`/project-decor-selects/${params.id}`, { params });

export const fetchAllModelPlansApi = params => client().get('/models', { params });
export const fetchOneModelPlansApi = ({ id, ...params }) =>
  client().get(`/models/${id}`, { params });
export const fetchAllModelPlansCountApi = params => client().get('/models/count', { params });
export const updateModelPlanApi = params => client().put(`models/${params.id}`, params.values);
export const addModelPlanApi = params => client().post('/models', params);
export const deleteModelPlanApi = params => client().delete(`models/${params?.id}`);

export const fetchAllModelRoomsApi = params => client().get('/project-model-rooms', { params });
export const fetchAllModelRoomsCountApi = params =>
  client().get('/project-model-rooms/count', { params });
export const addModelRoomApi = params => client().post('/project-model-rooms', params);
export const updateModelRoomApi = params =>
  client().put(`/project-model-rooms/${params.id}`, params.values);

export const deleteModelRoomApi = params => client().delete(`/project-model-rooms/${params?.id}`);

export const fetchAllProdRoomsApi = params => client().get('/dc-prod-rooms', { params });
export const fetchAllProdRoomCountApi = params => client().get('/dc-prod-rooms/count', { params });
export const updateProdRoomApi = params =>
  client().put(`dc-prod-rooms/${params.id}`, params.values);
export const addDecorProdRoomApi = params => client().post('/dc-prod-rooms', params);
export const addProdRoomApi = params => client().post('/custom/loadDecorProduct', params);
export const deleteProdRoomApi = params => client().delete(`dc-prod-rooms/${params?.id}`);

export const fetchAllUnitsSuitesApi = params => client().get('/units', { params });
export const updateUnitsSuitesApi = params => client().put(`units/${params.id}`, params.values);
export const addUnitsSuitesApi = params => client().post('/units', params);
export const deleteUnitsSuitesApi = params => client().delete(`units/${params?.id}`);
export const fetchAllUnitsSuitesCountApi = params => client().get('/units/count', { params });

// Parking & Locker
export const fetchProjectAmenitiesApi = params => client().get('/project-amenities', { params });
export const fetchProjectAmenitiesCountApi = params =>
  client().get('/project-amenities/count', { params });
export const deleteProjectAmenityApi = params =>
  client().delete(`/project-amenities/${params.id}`, { params });
export const addProjectAmenityApi = data => client().post(`/project-amenities/`, data);
export const updateProjectAmenityApi = params =>
  client().put(`/project-amenities/${params.id}`, params.values);

export const fetchSmAmenitiesApi = params => client().get('/system-master-amenities', { params });
export const fetchSmAmenitiesCountApi = params =>
  client().get('/system-master-amenities/count', { params });
export const updateSmAmenityApi = params =>
  client().put(`/system-master-amenities/${params.id}`, params.values);
export const addSmAmenityApi = params => client().post('/system-master-amenities', params);
export const deleteSmAmenityApi = params =>
  client().delete(`/system-master-amenities/${params.id}`);

export const fetchSmIncentivesApi = params => client().get('/sm-incentives', { params });
export const fetchSmIncentivesCountApi = params => client().get('/sm-incentives/count', { params });
export const updateSmIncentiveApi = params =>
  client().put(`/sm-incentives/${params.id}`, params.values);
export const addSmIncentiveApi = params => client().post('/sm-incentives', params);
export const deleteSmIncentiveApi = params => client().delete(`/sm-incentives/${params.id}`);

export const fetchBasicListsApi = params => client().get('/basic-informations', { params });
export const fetchBasicListsCountApi = params =>
  client().get('/basic-informations/count', { params });
export const updateBasicListApi = params =>
  client().put(`/basic-informations/${params.id}`, params.values);
export const addBasicListApi = params => client().post('/basic-informations', params);
export const deleteBasicListApi = params => client().delete(`/basic-informations/${params.id}`);

export const fetchSmPremiumsApi = params => client().get('/sm-premiums', { params });
export const fetchSmPremiumsCountApi = params => client().get('/sm-premiums/count', { params });
export const updateSmPremiumApi = params =>
  client().put(`/sm-premiums/${params.id}`, params.values);
export const addSmPremiumApi = params => client().post('/sm-premiums', params);
export const deleteSmPremiumApi = params => client().delete(`/sm-premiums/${params.id}`);

export const fetchSmSuppliersApi = params => client().get('/system-master-suppliers', { params });
export const fetchSmSuppliersCountApi = params =>
  client().get('/system-master-suppliers/count', { params });
export const updateSmSupplierApi = params =>
  client().put(`/system-master-suppliers/${params.id}`, params.values);
export const addSmSupplierApi = params => client().post('/system-master-suppliers', params);
export const deleteSmSupplierApi = params =>
  client().delete(`/system-master-suppliers/${params.id}`);

// Project Inactives
export const addIncentivesApi = data => client().post('/project-incentives', data);
export const fetchIncentivesApi = params => client().get('/project-incentives', { params });

export const fetchIncentivesCountApi = params =>
  client().get('/project-incentives/count', { params });
export const updateIncentivesApi = params =>
  client().put(`/project-incentives/${params.id}`, params.values);
export const deleteIncentivesApi = params =>
  client().delete(`/project-incentives/${params.id}`, { params });

export const fetchPremiumsApi = params => client().get('/project-premiums', { params });
export const addPremiumsApi = params => client().post('/project-premiums', params);
export const updatePremiumsApi = params =>
  client().put(`/project-premiums/${params.id}`, params.values);
export const deletePremiumsApi = params =>
  client().delete(`/project-premiums/${params.id}`, { params });
export const fetchPremiumsCountApi = params => client().get('/project-premiums/count', { params });

export const fetchProjectDocumentsApi = params => client().get('/pj-documents', { params });
export const addProjectDocumentApi = params => client().post('/pj-documents', params);
export const updateProjectDocumentApi = params =>
  client().put(`/pj-documents/${params.id}`, params.values);
export const deleteProjectDocumentApi = id => client().delete(`/pj-documents/${id}`);
export const fetchProjectDocumentsCountApi = params =>
  client().get('/pj-documents/count', { params });

export const fetchDepositSetsApi = params => client().get('/project-deposit-sets', { params });
export const updateDepositSetsApi = params =>
  client().put(`/project-deposit-sets/${params.id}`, params.values);
export const addDepositSetsApi = params => client().post('/project-deposit-sets', params);
export const deleteDepositSetsApi = params => client().delete(`/project-deposit-sets/${params}`);
export const fetchDepositDetsApi = params => client().get('/project-deposit-dets', { params });
export const updateDepositDetsApi = params =>
  client().put(`/project-deposit-dets/${params.id}`, params.values);
export const addDepositDetsApi = params => client().post('/project-deposit-dets', params);
export const deleteDepositDetsApi = params => client().delete(`/project-deposit-dets/${params}`);
export const fetchDepositSetsCountApi = params =>
  client().get('/project-deposit-sets/count', { params });
export const fetchDecorProductsApi = params => client().get('/dc-products', { params });

export const fetchUnitMeasureApi = params =>
  client().get('/basic-informations?type=UofMCode', { params });

export const fetchAllocationTypes = params =>
  client().get('/custom/allocationtypelist', { params });
export const fetchSalesSources = params => client().get('/custom/salessourcelist', { params });

export const fetchAgentClasses = params => client().get('/custom/agentclasslist', { params });
export const fetchModelRooms = params => client().get('/custom/modelrooms', { params });

// project-sales
export const fetchAllSalesOffersApi = params => client().get('/aps-sales-offers', { params });
export const fetchOneSalesProjectApi = ({ id, ...params }) =>
  client().get(`/aps-sales-offers/${id}`, { params });
export const fetchAllSalesOffersCountApi = params =>
  client().get('/aps-sales-offers/count', { params });
export const addSalesProjectApi = data => client().post('/aps-sales-offers', data);
export const updateSaleProjectApi = params =>
  client().put(`/aps-sales-offers/${params.id}`, params.values);
export const deleteSaleProjectApi = params =>
  client().delete(`/aps-sales-offers/${params.id}`, { params });

export const fetchOneSaleApi = ({ id, ...params }) => client().get(`/sale/${id}`, { params });
export const addSaleApi = data => client().post('/sale', data);
export const updateSaleSignApi = data => client().post('/sales-sign/SendToDocuSign', data);
export const updateSaleApi = params => client().put(`/sale/${params.id}`, params.values);

export const fetchProjectXAllocationsApi = params =>
  client().get('/project-broker-x-allocs', { params });
export const fetchProjectXAllocationsCountApi = params =>
  client().get('/project-broker-x-allocs/count', { params });
export const updateProjectXAllocationsApi = params =>
  client().put(`/project-broker-x-allocs/${params.id}`, params.values);
export const addProjectXAllocationsApi = data => client().post('/project-broker-x-allocs', data);
export const deleteProjectXAllocationsApi = params =>
  client().delete(`/project-broker-x-allocs/${params.id}`, { params });

export const fetchReBrokerAgentsApi = params => client().get('/re-broker-agents', { params });
export const fetchReBrokerAgentsCountApi = params =>
  client().get('/re-broker-agents/count', { params });
export const fetchOneReBrokerAgentApi = ({ id, ...params }) =>
  client().get(`/re-broker-agents/${id}`, { params });

export const fetchBrokerAgentsApi = params => client().get('/project-broker-agents', { params });
export const fetchProjectBrokerAgentsApi = params =>
  client().get('/project-broker-agents', { params });
export const fetchBrokerAgentsCountApi = params =>
  client().get('/project-broker-agents/count', { params });
export const updateBrokerAgentsApi = params =>
  client().put(`/project-broker-agents/${params.id}`, params.values);

export const addLoadBrokersApi = data => client().post('/custom/load-brokers', data);
export const addPreAllocateUnitsApi = data => client().post('/custom/pre-allocate-units', data);

export const fetchAllPageTabsApi = params => client().get('/project-web-asset-tabs', { params });

export const fetchAllPageTabsCountApi = params =>
  client().get('/project-web-asset-tabs/count', { params });

export const updatePageTabApi = params =>
  client().put(`/project-web-asset-tabs/${params.id}`, params.values);

export const addPageTabApi = data => client().post('/project-web-asset-tabs', data);

export const deletePageTabApi = params =>
  client().delete(`/project-web-asset-tabs/${params.id}`, { params });

export const fetchAllTabZonesApi = params => client().get('/project-web-zone-refs', { params });

export const fetchAllTabZonesCountApi = params =>
  client().get('/project-web-zone-refs/count', { params });

export const updateTabZoneApi = params =>
  client().put(`/project-web-zone-refs/${params.id}`, params.values);
export const addTabZoneApi = data => client().post('/project-web-zone-refs', data);

export const deleteTabZoneApi = params =>
  client().delete(`/project-web-zone-refs/${params.id}`, { params });

export const fetchAllZoneSpecsApi = params => client().get('/project-web-asset-refs', { params });

export const updateZoneSpecApi = params =>
  client().put(`/project-web-asset-refs/${params.id}`, params.values);

export const addZoneSpecApi = data => client().post('/project-web-asset-refs', data);

export const fetchAllZoneSpecsCountApi = params =>
  client().get('/project-web-asset-refs/count', { params });

export const deleteZoneSpecApi = params =>
  client().delete(`/project-web-asset-refs/${params.id}`, { params });
