import { CloseCircleOutlined } from '@ant-design/icons';
import FeaturedImages from 'components/organisms/brokers/projects-single/featured-images';
import useGetMainColor from 'Utils/hooks/useGetMainColor';
import { darkBlue } from 'constants/colors';
import ModalContent from './ModalContent';
import { Button, Col, ModalWrapper, Paragraph, Row } from '../atoms';

const ImageListModal = ({
  imageList = [],
  closeModal,
  maxCount,
  modalProps = {},
  title = '',
  subTitle = '',
  customWidth = '700px',
  imgProps = {},
  actualImageSize = false,
  isSale,
}) => {
  const [activeColor] = useGetMainColor();

  const footer = (
    <Row justify='end' align='middle'>
      <Button
        type='light_blue'
        onClick={closeModal}
        icon={<CloseCircleOutlined />}
        ml='10px'
        color={activeColor}
        {...(isSale
          ? { type: 'sales_blue', back_color: darkBlue, height: '25px', width: '130px' }
          : {})}
      >
        CANCEL
      </Button>
    </Row>
  );

  return (
    <ModalWrapper closeModal={closeModal}>
      <ModalContent
        type='blue'
        title={title}
        subTitle={subTitle}
        closeModal={closeModal}
        customWidth={customWidth}
        customMargin='30px 0'
        footer={footer}
        modalProps={{
          title_back_color: isSale ? '#03215F' : activeColor,
          title_color: 'white',
          ...modalProps,
        }}
      >
        <Row gutter={1} padding='15px' width='100%' margin='0'>
          {imageList?.length ? (
            <Col span={24}>
              <FeaturedImages
                allImages={imageList}
                imgHeight='150px'
                isEditable={false}
                maxCount={maxCount || 3}
                actualImageSize={actualImageSize}
                videoStyles={{
                  height: '150px',
                  borderRadius: '10px',
                  objectFit: 'cover',
                }}
                imgProps={{
                  preview: false,
                  ...(customWidth && actualImageSize
                    ? { max_width: `calc(${customWidth} - 100px)`, max_height: '78vh' }
                    : {}),
                }}
                customDoc={true}
                centerAlign={actualImageSize}
                containerStyles={{ border: 'none' }}
                {...imgProps}
              />
            </Col>
          ) : (
            <Col span={24}>
              <Paragraph align='center' fz={24} mb={0} padding='15px 0'>
                No Image to show
              </Paragraph>
            </Col>
          )}
        </Row>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ImageListModal;
