/* eslint-disable import/no-cycle */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { CaretDownOutlined, UserOutlined, LogoutOutlined, TeamOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import moment from 'moment';
import { Menu, Paragraph, Row, Col } from 'components/atoms/index';
import { DecorItemWrapper } from 'DashboardWrapper';
import ProfilePopup from 'components/organisms/profile-popup';
import useGetMainColor from 'Utils/hooks/useGetMainColor';
import { saveSalesData } from '../../app/slices/sales-data';
import { removeUser } from '../../app/slices/user';
import { getUser } from '../../app/services/selector-helpers';
import { updateUsersApi } from '../../services/users';
import useApiCall from '../../Utils/hooks/useApiCall';
import { emptyState, loadState, saveState } from '../../configs/local-storage';
import TeamsListPopup from './teams-list-popup';
import { setDecorFilters } from '../../app/slices/decor';

const UserDropDownWrapper = styled.div`
  display: flex;
  align-items: center;

  .user_logo_wrapper {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 2px;
    background: linear-gradient(205.96deg, #ffa7d7 7.6%, #cea7ff 87.33%);
    margin-right: 15px;

    .user_logo {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 2px solid #fff;
      background-color: #c4c4c4;
    }

    ${props =>
      props.logo_image &&
      css`
        .user_logo {
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url(${props.logo_image});
        }
      `}
  }

  .anticon-caret-down svg {
    font-size: 12px;
    margin: 5px 0 0 0;
    color: #c4c4c4;
  }
`;

const nameRoles = ['decorselection', 'admin', 'brokers', 'sale_office'];

const profileRoute = {
  profile: {
    pageTitle: 'My Profile',
    key: 'my-profile',
    icon: <UserOutlined />,
  },
};

const HeadUserDropdown = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const [activeColor] = useGetMainColor();
  const { id, username = '', firstName, lastName, role, avatar } = useSelector(getUser);
  const [teamModalOpen, setTeamModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [, , updateUserCall] = useApiCall(updateUsersApi);
  const userName = firstName ? `${firstName || ''} ${lastName || ''}` : username;
  const adminSwitchState = loadState('admin_switch_state');

  const handleSwitchBack = () => {
    saveState(adminSwitchState);
    emptyState('admin_switch_state');
    window.location.pathname = '/users';
  };

  const handleLogout = () => {
    updateUserCall({
      id: adminSwitchState ? adminSwitchState?.user?.id : id,
      values: { LastLogoutDate: moment().format() },
    });
    dispatch(removeUser());
    dispatch(saveSalesData(null));
    dispatch(setDecorFilters(null));
    navigate('/sign-in');
    emptyState('admin_switch_state');
  };

  const closeTeamModal = () => setTeamModalOpen(false);

  return (
    <>
      {modalOpen && <ProfilePopup closeModal={() => setModalOpen(false)} />}
      {teamModalOpen && (
        <TeamsListPopup
          title='My Team | User Accounts'
          closeTableModal={closeTeamModal}
          subTitle={username}
        />
      )}
      <UserDropDownWrapper logo_image={avatar?.url || ''}>
        <Row align='middle'>
          <div className='user_logo_wrapper'>
            <div className='user_logo'></div>
          </div>
          <Col align='start' className={role?.type === 'brokers' ? 'broker_header' : ''}>
            {nameRoles.includes(role?.type) && (
              <Paragraph
                fz={18}
                mb={0}
                margin='0 30px 0 0'
                fw={500}
                max_width='350px'
                ellipsis={{ tooltip: true }}
              >
                {userName}
              </Paragraph>
            )}
            <Dropdown
              overlayStyle={{ zIndex: 99999 }}
              overlay={() => (
                <Menu className={role?.type === 'brokers' ? 'broker_menu' : ''}>
                  {role?.type === 'brokers' &&
                    Object.values({ ...profileRoute }).map(
                      menuitem =>
                        !menuitem?.hideInList && (
                          <Menu.Item key={menuitem.key} className='broker_item'>
                            <DecorItemWrapper
                              align='center'
                              margin='0 30px 0 0'
                              onClick={e => {
                                menuitem?.pathname
                                  ? navigate(menuitem.pathname)
                                  : setModalOpen(true);
                              }}
                              active_color={activeColor}
                            >
                              {menuitem.icon}
                              <Paragraph mb={0}>{menuitem?.pageTitle}</Paragraph>
                            </DecorItemWrapper>
                          </Menu.Item>
                        )
                    )}
                  {['admin', 'clients', 'decorselection'].includes(role?.type) && (
                    <Menu.Item
                      key='profile'
                      className='broker_item'
                      onClick={() => setModalOpen(true)}
                    >
                      <DecorItemWrapper
                        align='center'
                        margin='0 30px 0 0'
                        active_color={activeColor}
                      >
                        <UserOutlined />
                        <Paragraph mb={0} margin='0 0 0 5px'>
                          My Profile
                        </Paragraph>
                      </DecorItemWrapper>
                    </Menu.Item>
                  )}
                  <Menu.Item
                    key='my_team'
                    margin='0 30px 0 0'
                    disabled={role?.type !== 'brokers' || user?.LeadUser_id?.id}
                    onClick={() => setTeamModalOpen(true)}
                  >
                    <DecorItemWrapper
                      align='center'
                      margin='0 30px 0 0'
                      active_color={activeColor}
                      cursor={
                        role?.type !== 'brokers' || user?.LeadUser_id?.id
                          ? 'not-allowed'
                          : 'pointer'
                      }
                    >
                      <TeamOutlined />
                      <Paragraph mb={0}>My Team</Paragraph>
                    </DecorItemWrapper>
                  </Menu.Item>
                  {adminSwitchState && (
                    <Menu.Item onClick={handleSwitchBack} key='switch'>
                      <DecorItemWrapper
                        align='center'
                        margin='0 30px 0 0'
                        active_color={activeColor}
                      >
                        <Paragraph mb={0}>Switch Back</Paragraph>
                      </DecorItemWrapper>
                    </Menu.Item>
                  )}
                  <Menu.Item onClick={handleLogout} key='logout'>
                    <DecorItemWrapper align='center' margin='0 30px 0 0' active_color={activeColor}>
                      <LogoutOutlined />
                      <Paragraph mb={0}>Log out</Paragraph>
                    </DecorItemWrapper>
                  </Menu.Item>
                </Menu>
              )}
              placement='bottomRight'
              trigger={['click']}
            >
              <CaretDownOutlined />
            </Dropdown>
          </Col>
        </Row>
      </UserDropDownWrapper>
    </>
  );
};

export default HeadUserDropdown;
