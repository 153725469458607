import styled, { css } from 'styled-components';
import { Button as button } from 'antd';
import { darkBlue, mainColor, mainTextColor, salesMainColor } from '../../../constants/colors';

const Button = styled(button)`
  background: ${mainColor};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.35);
  //border-radius: 50px;
  //height: 44px;
  height: 35px;
  border: none;
  font-weight: 500;
  color: #fff;
  //padding: 8px 16px;
  padding: 0 16px;
  font-size: 14px;

  &[disabled] {
    background: #e7e7e7 !important;
    & span {
      color: ${darkBlue} !important;
    }
  }

  & .anticon.anticon-plus > svg,
  & .anticon.anticon-minus > svg {
    shape-rendering: unset !important;
  }
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color}!important;
    `}
  ${props =>
    props.decoration &&
    css`
      span {
        text-decoration: ${props.decoration}!important;
      }
    `}
  ${props =>
    props.icon_height &&
    css`
      svg {
        height: ${props.icon_height}!important;
      }
    `}
  ${props =>
    props.icon_pos === 'right' &&
    css`
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: center;

      span:not(.anticon) {
        margin-left: 0 !important;
      }
      span.anticon {
        margin-left: 8px;
      }
    `}
    ${props =>
    props.type === 'blue' &&
    css`
      background: ${mainColor} !important;
      border-radius: unset !important;
      & span {
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 21px !important;
        color: white !important;
      }
    `}
    ${props =>
    props.type === 'sales_blue' &&
    css`
      background: ${darkBlue};
      border: 1px solid ${salesMainColor};
      border-radius: unset !important;
      & span {
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 21px !important;
        color: white !important;
      }
      &:hover {
        background: ${darkBlue};
        color: #fff;
      }
      &:active {
        background: ${darkBlue};
        color: #fff;
      }
      &:focus {
        background: ${darkBlue};
        color: #fff;
      }
      &[disabled] {
        background: #e7e7e7;
        & span {
          color: ${darkBlue};
        }
      }
    `}
    ${props =>
    props.type === 'soft_blue' &&
    css`
      background: ${mainColor} !important;
      border-radius: unset !important;
      & span {
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 21px !important;
        color: white !important;
      }
    `}
    ${props =>
    props.type === 'light_blue' &&
    css`
      background: #f1f2f3;
      color: ${mainTextColor};
      box-shadow: 3px 3px 4px #0000002e;
      min-width: 115px;
      height: 30px;
      font-size: 14px;
      font-weight: 500;
      &:hover {
        background: #f1f2f3;
        color: ${mainTextColor};
      }

      &:active {
        background: #f1f2f3;
        color: ${mainTextColor};
      }

      &:focus {
        background: #f1f2f3;
        color: ${mainTextColor};
      }

      &[disabled] {
        background: #f1f2f3;
        color: #616161;
      }
    `}
  ${props =>
    props.type === 'primary' &&
    css`
      background: ${mainColor};
      &:hover {
        background: ${mainColor};
        color: #fff;
      }
      &:active {
        background: ${mainColor};
        color: #fff;
      }
      &:focus {
        background: ${mainColor};
        color: #fff;
      }
      &[disabled] {
        background: #e7e7e7;
        color: #616161;
      }
    `}
    ${props =>
    props.sec_type === 'sales_slide' &&
    css`
      &.slick-next {
        right: -23px !important;
      }
      &.slick-prev {
        left: -23px !important;
      }
      background-color: transparent !important;
      box-shadow: none !important;
      transform: translateY(-25%);
      width: 40px !important;
      height: 40px !important;
      padding: 10px !important;
    `}
    ${props =>
    props.type === 'slide' &&
    css`
      &.slick-next {
        right: 10px;
      }
      &.slick-prev {
        left: 10px;
      }
      transform: translateY(-25%);
      background: #00000070 !important;
      width: 40px !important;
      height: 40px !important;
      padding: 10px !important;
      &:hover {
        background: #00000099 !important;
        color: #fff !important;
      }
      &:active {
        // background: #0000004d !important;
        color: #fff !important;
      }
      &:focus {
        background: #00000070 !important;
        color: #fff !important;
      }
      &[disabled] {
        background: #e7e7e7 !important;
        color: #616161 !important;
      }
    `}
  ${props =>
    props.type === 'blue' &&
    css`
      background: ${mainColor};

      &:hover {
        background: ${mainColor};
        color: #fff;
      }

      &:active {
        background: ${mainColor};
        color: #fff;
      }

      &:focus {
        background: ${mainColor};
        color: #fff;
      }

      &[disabled] {
        background: ${mainColor};
        color: #616161;
      }
    `}
  ${props =>
    props.type === 'light_blue' &&
    css`
      background: #f1f2f3;
      color: ${mainTextColor};
      box-shadow: 3px 3px 4px #0000002e;
      min-width: 115px;
      height: 25px;
      font-size: 14px;
      font-weight: 500;
      &:hover {
        background: #f1f2f3;
        color: ${mainTextColor};
      }

      &:active {
        background: #f1f2f3;
        color: ${mainTextColor};
      }

      &:focus {
        background: #f1f2f3;
        color: ${mainTextColor};
      }

      &[disabled] {
        background: #f1f2f3;
        color: #616161;
      }
    `}
  ${props =>
    props.type === 'white' &&
    css`
      background: #fff;
      color: ${mainTextColor};
      &:hover {
        background: #fff;
        color: ${mainTextColor};
      }

      &:active {
        background: #fff;
        color: ${mainTextColor};
      }

      &:focus {
        background: #fff;
        color: ${mainTextColor};
      }

      &[disabled] {
        background: #fff;
        color: #616161;
      }
    `}
  ${props =>
    props.type === 'grey' &&
    css`
      background: #c4c4c4;
      color: #fff;

      &:hover {
        background: #0202a5;
        color: #fff;
      }

      &:active {
        background: #c4c4c4;
        color: #fff;
      }

      &:focus {
        background: #c4c4c4;
        color: #fff;
      }

      &[disabled] {
        background: #c4c4c4;
        color: #616161;
      }
    `}
  ${props =>
    props.type === 'primary_grey' &&
    css`
      background: #c4c4c4;
      color: #593b93;
      border: 1px solid #593b93;

      &:hover {
        background: #c4c4c4;
        color: #593b93;
        border-color: #593b93;
      }

      &:active {
        background: #c4c4c4;
        color: #593b93;
        border-color: #593b93;
      }

      &:focus {
        background: #c4c4c4;
        color: #593b93;
        border-color: #593b93;
      }

      &[disabled] {
        background: #c4c4c4;
        color: #616161;
      }
    `}
  ${props =>
    props.sectype === 'ghost' &&
    css`
      color: ${mainTextColor};
      background: transparent;
      box-shadow: none;
      &:hover {
        background: transparent;
        color: #756399;
      }
      &:active {
        background: transparent;
        color: #756399;
      }
      &:focus {
        background: transparent;
        color: #756399;
      }
    `}
  ${props =>
    props.sectype === 'blue_ghost' &&
    css`
      color: ${mainTextColor};
      background: transparent;
      box-shadow: none;
      &:hover {
        background: transparent;
        color: ${mainTextColor};
      }
      &:active {
        background: transparent;
        color: ${mainTextColor};
      }
      &:focus {
        background: transparent;
        color: ${mainTextColor};
      }
    `}
  ${props =>
    props.sectype === 'destructive_ghost' &&
    css`
      color: #ff4f46;
      background: transparent;
      box-shadow: none;
      &:hover {
        background: transparent;
        color: #ff4f46;
      }
      &:active {
        background: transparent;
        color: #ff4f46;
      }
      &:focus {
        background: transparent;
        color: #ff4f46;
      }
    `}
  ${props =>
    props.sectype === 'secondary' &&
    css`
      color: #717579;
      &:hover {
        color: #717579;
      }
      &:active {
        color: #717579;
      }
      &:focus {
        color: #717579;
      }
    `}
  ${props =>
    props.type === 'blue_outline' &&
    css`
      background: none;
      border: 1px solid ${mainColor};
      color: ${mainColor};
      &:hover {
        color: ${mainColor};
        border-color: ${mainColor};
      }
      &:active {
        color: ${mainColor};
        border-color: ${mainColor};
      }
      &:focus {
        color: ${mainColor};
        border-color: ${mainColor};
      }
    `}
  ${props =>
    props.type === 'outline' &&
    css`
      background: none;
      border: 1px solid ${props.bordercolor};
      //border-radius: 50px;
      color: ${mainTextColor};
      &:hover {
        color: #6138fb;
        border-color: #6138fb;
      }
      &:active {
        color: ${mainTextColor};
        border-color: ${mainColor};
      }
      &:focus {
        color: ${mainTextColor};
        border-color: ${mainColor};
      }
    `}
  ${props =>
    props.type === 'dark_outline' &&
    css`
      background: #fff;
      border: 1px solid #eeeeee;
      //border-radius: 10px;
      color: #171b1e;
      box-shadow: none;
      &:hover {
        color: #171b1e;
        border-color: #171b1e;
      }
      &:active {
        color: #171b1e;
        border-color: #171b1e;
      }
      &:focus {
        color: #171b1e;
        border-color: #171b1e;
      }
    `}
    ${props =>
    props.type === 'sale_outline' &&
    css`
      background: #fff;
      border: 1px solid ${darkBlue};
      //border-radius: 10px;
      color: ${darkBlue};
      box-shadow: none;
      &:hover {
        color: ${darkBlue};
        border-color: ${darkBlue};
      }
      &:active {
        color: ${darkBlue};
        border-color: ${darkBlue};
      }
      &:focus {
        color: ${darkBlue};
        border-color: ${darkBlue};
      }
    `}
  ${props =>
    props.type === 'white_outline' &&
    css`
      background: transparent;
      border: 1px solid #fff;
      color: #fff;
      box-shadow: none;
      &:hover {
        color: #fff;
        background: transparent;
        border-color: #fff;
      }
      &:active {
        color: #fff;
        border-color: #fff;
        background: transparent;
      }
      &:focus {
        color: #fff;
        border-color: #fff;
        background: transparent;
      }
    `}
  ${props =>
    props.type === 'black_outline' &&
    css`
      background: #fff;
      border: 1px solid #171b1e;
      color: #171b1e;
      box-shadow: none;
      &:hover {
        color: #171b1e;
        border-color: #171b1e;
      }
      &:active {
        color: #171b1e;
        border-color: #171b1e;
      }
      &:focus {
        color: #171b1e;
        border-color: #171b1e;
      }
    `}
  ${props =>
    props.type === 'white_back_outlined' &&
    css`
      background: #fff;
      border: 1px solid ${mainColor};
      color: ${mainColor};
      box-shadow: none;
      &:hover {
        color: ${mainColor};
        border-color: ${mainColor};
      }
      &:active {
        color: ${mainColor};
        border-color: ${mainColor};
      }
      &:focus {
        color: ${mainColor};
        border-color: ${mainColor};
      }
      &[disabled] {
        color: #0000003f;
        border-color: #d9d9d9;
        background: #f5f5f5;
      }
    `}
  ${props =>
    props.type === 'destructive' &&
    css`
      background: #f8817b;
      border: 1px solid #f8817b;
      //border-radius: 10px;
      color: #fff;
      box-shadow: none;
      &:hover {
        color: #ff4f46;
        border-color: #ff4f46;
      }
      &:active {
        color: #ff4f46;
        border-color: #ff4f46;
      }
      &:focus {
        color: #ff4f46;
        border-color: #ff4f46;
      }
    `}
  ${props =>
    props.type === 'grey_destructive' &&
    css`
      border: 1px solid #c4c4c4;
      box-shadow: none;
      color: #ff2222;
      background: #c4c4c4;
      &:hover {
        color: #ff2222;
        background: #c4c4c4;
        border: 1px solid #c4c4c4;
      }
      &:active {
        color: #ff2222;
        background: #c4c4c4;
        border: 1px solid #c4c4c4;
      }
      &:focus {
        color: #ff2222;
        background: #c4c4c4;
        border: 1px solid #c4c4c4;
      }
    `}
  ${props =>
    props.type === 'destructive_outline' &&
    css`
      background: #fff;
      border: 1px solid #f8817b;
      //border-radius: 10px;
      color: #f8817b;
      box-shadow: none;
      &:hover {
        color: #ff4f46;
        border-color: #ff4f46;
      }
      &:active {
        color: #ff4f46;
        border-color: #ff4f46;
      }
      &:focus {
        color: #ff4f46;
        border-color: #ff4f46;
      }
    `}
  ${props =>
    props.type === 'grey_ghost' &&
    css`
      color: #717579;
      background: transparent;
      box-shadow: none;
      &:hover {
        background: transparent;
        color: #717579;
      }
      &:active {
        background: transparent;
        color: #717579;
      }
      &:focus {
        background: transparent;
        color: #717579;
      }
    `}
  ${props =>
    props.type === 'dark_ghost' &&
    css`
      color: #171b1e;
      background: transparent;
      box-shadow: none;
      &:hover {
        background: transparent;
        color: #171b1e;
      }
      &:active {
        background: transparent;
        color: #171b1e;
      }
      &:focus {
        background: transparent;
        color: #171b1e;
      }
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
    ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
    ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.pl &&
    css`
      padding-left: ${props.pl};
    `}
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
    ${props =>
    props.ml &&
    css`
      margin-left: ${props.ml};
    `}
    ${props =>
    props.ml_span &&
    css`
      & > .anticon + span,
      & > span + .anticon {
        margin-left: ${props.ml_span} !important;
      }
    `}
    ${props =>
    props.mr &&
    css`
      margin-right: ${props.mr};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color}!important;
    `}
  ${props =>
    props.shadow &&
    css`
      box-shadow: ${props.shadow} !important;
    `}
  ${props =>
    props.back_color &&
    css`
      background-color: ${props.back_color}!important;
    `}
    ${props =>
    props.z_index &&
    css`
      z-index: ${props.z_index}!important;
    `}
  ${props =>
    props.display &&
    css`
      display: ${props.display}!important;
    `}
  ${props =>
    props.align &&
    css`
      display: flex;
      align-items: ${props.align}!important;
    `}
  ${props =>
    props.justify &&
    css`
      display: flex;
      justify-content: ${props.justify}!important;
    `}
  ${props =>
    props.line_height &&
    css`
      line-height: ${props.line_height}!important;
    `}
    ${props =>
    props.fz &&
    css`
      span {
        font-size: ${props.fz}!important;
      }
    `}
    ${props =>
    props.icon_fz &&
    css`
      span svg {
        font-size: ${props.icon_fz}!important;
      }
    `}
      ${props =>
    props.fw &&
    css`
      & span {
        font-weight: ${props.fw}!important;
      }
    `}
    ${props =>
    props.border &&
    css`
      border: ${props.border}!important;
    `}
    ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.min_width &&
    css`
      min-width: ${props.min_width}!important;
    `}
    ${props =>
    props.max_height &&
    css`
      max-height: ${props.max_height}!important;
    `}
    ${props =>
    props.active_color &&
    css`
      &:not([disabled]) {
        background: ${props.active_color} !important;
        &,
        & span {
          color: white;
        }
      }
    `}
`;

export default Button;
