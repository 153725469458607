import client from './main-client';

export const userLoginApi = credentials => client().post('/auth/local', credentials);
export const userLogoutApi = () => new Promise();
export const userRegisterApi = data => client().post('/auth/local/register', data);
export const userAuthmanagementApi = credentials => client().post('/authmanagement', credentials);
export const forgotPasswordApi = params => client().post(`/auth/forgot-password`, params);
export const resetPasswordApi = params => client().post(`/auth/reset-password`, params);

export const changePasswordApi = data => client().post('/custom/change-password', data);

export const fetchAdminUsersApi = (url, params) => client().get(url, { params });
export const fetchAdminUsersCountApi = (url, params) => client().get(`${url}/count`, { params });
export const getUsersApi = params => client().get('/users', { params });
export const getUsersCountApi = params => client().get('/users/count', { params });
export const getUsersRolesApi = params => client().get('/users-permissions/roles', { params });
export const getUserApi = params => client().get(`/users/${params.id}`, { params });
export const addNewUserApi = data => client().post('/auth/local/register', data);
export const deleteUsersApi = params => client().delete(`/users/${params.id}`);
export const updateUsersApi = params => client().put(`/users/${params.id}`, params.values);

export const fetchPurchasersApi = params => client().get('/cx-sales-txn-refs', { params });
export const fetchPurchasersCountApi = params =>
  client().get('/cx-sales-txn-refs/count', { params });
export const updatePurchaserApi = params =>
  client().put(`/cx-sales-txn-refs/${params.id}`, params.values);
export const fetchBrokerAgentsApi = params => client().get('/re-broker-agents', { params });

export const getUsersFullInfoApi = data => client().post('/custom/getUserFullInfo', data);
