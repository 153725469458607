import styled, { css } from 'styled-components';
import { Steps as steps } from 'antd';
import { darkBlue } from 'constants/colors';

const Steps = styled(steps)`
  ${props =>
    props.type === 'sales' &&
    css`
      display: flex;
      align-items: end;
      height: auto;
      position: relative;
      align-items: stretch;

      &::after {
        content: '';
        position: absolute;
        top: 35%;
        left: 0;
        right: 0;
        border-top: 1px solid #d9d9d9;
      }

      .ant-steps-label-vertical .ant-steps-item-tail {
        display: none;
      }
      .ant-steps-item-icon {
        padding: 3px 9px;
        border: none;
        background: ${darkBlue} !important;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px !important;
        width: 35px !important;
        border-radius: 50%;
        & svg {
          color: white;
        }
        & .ant-steps-icon {
          font-weight: 500;
          font-size: 14px;
          display: flex;
          width: fit-content !important;
          height: fit-content !important;
          position: unset;
        }
      }
      .ant-steps-item-process .ant-steps-item-icon {
        background: ${darkBlue} !important;
      }

      .ant-steps-item-wait .ant-steps-item-icon {
        background: white !important;
        border: 1px solid #d9d9d9 !important;
        & .ant-steps-icon {
          color: #d9d9d9;
        }
      }
      & .ant-steps-item-tail {
        display: none;
      }
      & .ant-steps-item-content {
        margin-top: 6px;
      }
      & .ant-steps-item-title {
        font-weight: 500;
        font-size: 12px;
        line-height: normal;

        color: #000 !important;
      }
      & .ant-steps-item-process .ant-steps-item-title {
        color: ${darkBlue} !important;
      }
      & .ant-steps-item {
        z-index: 4;
      }
      & .ant-steps-item-title {
        font-size: 9px !important;
      }
      & .ant-steps-item {
        & .ant-steps-item-content {
          width: 65px;
        }
        & .ant-steps-item-icon {
          margin-left: 18px;
        }
      }

      & .ant-steps-item:nth-child(1) {
        & .ant-steps-item-icon {
          margin-left: 16px !important;
        }
      }
      & .ant-steps-item:nth-child(7) {
        & .ant-steps-item-content {
          width: 73px !important;
        }
        & .ant-steps-item-icon {
          margin-left: 16px !important;
        }
      }
    `}
`;

export default Steps;
