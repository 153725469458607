/* eslint-disable import/no-cycle */
import { CheckOutlined, CloseOutlined, GlobalOutlined } from '@ant-design/icons';
import moment from 'moment';
import { notification } from 'antd';
import { EditOutlineColor, mainColor } from 'constants/colors';
import { additionalQuestionsKeys, ssnPattern } from '../constants/etc';
import { Paragraph, Tooltip } from '../components/atoms';
import ActionIcon from '../assets/images/custom-icons/Action';
import { API_ROOT } from '../configs/env-vars';
import { emptyState, getAccessToken, loadState, saveState } from '../configs/local-storage';

export const rgba = (hex, alpha) => {
  return hex
    ? `rgba(${
        /^rgb/gi.test(hex)
          ? hex.match(/\d+/g)
          : hex
              .match(/\w\w/g)
              .map(x => +`0x${x}`)
              .slice(-3)
      },${alpha})`
    : '';
};

export const defText = (
  text,
  align,
  isPrice,
  { dollar, showDecimals, ...extraProps } = {},
  emptyValue
) => {
  let rowContent = '';
  if (!text) {
    rowContent = emptyValue ? '' : '-';
  } else {
    rowContent = text;
    if (isPrice) {
      rowContent = isPrice?.onlyLocale
        ? Number(text).toLocaleString()
        : Number(text).toLocaleString(undefined, { minimumFractionDigits: 2 });
    }
    if (showDecimals) {
      rowContent = text?.toFixed(2);
    }
  }

  return (
    <Paragraph
      mb={0}
      align={align || 'left'}
      fw={350}
      justify={dollar ? 'space-between' : ''}
      ellipsis={{ tooltip: true }}
      {...extraProps}
    >
      {dollar && <span>$</span>}
      {rowContent}
    </Paragraph>
  );
};

export const createProspectListText = prospects => {
  return prospects?.length
    ? `${prospects[0]?.BuyerClient_id?.FirstName || ''} ${
        prospects[0]?.BuyerClient_id?.LastName || ''
      }`
    : '';
};

export const isSaleEditable = item => {
  return !['rescinded', 'released', 'canceled'].includes(item?.SaleStatus_id?.code.toLowerCase());
  // return item?.id
  //   ? !item?.DealFirmDate &&
  //       !item?.DealComplete &&
  //       !item?.CancelEffDate &&
  //       !item?.IsAPSDealLocked &&
  //       !item?.ActualCloseDate
  //   : true;
};

export const printFieldValue = (data, field) => {
  if (field.type === 'boolean') {
    return data[field.key] ? 'Yes' : 'No';
  }
  if (field.key === 'agency') {
    return data[field.key]?.firstName
      ? `${data[field.key]?.firstName} ${data[field.key]?.lastName}`
      : '-';
  }
  if (field.key === 'license') {
    return data[field.key]?.url ? (
      <a
        href={convertFileUrl(data[field.key]?.url)}
        target='_blank'
        rel='noreferrer'
        style={{
          color: mainColor,
          maxWidth: '300px',
          display: 'block',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {data[field.key]?.name}
      </a>
    ) : (
      '-'
    );
  }
  return (field?.parentKey ? data?.[field?.parentKey]?.[field.key] : data?.[field.key]) || '-';
};

export const preventNegative = num => (num > 0 ? num : 0);

export const setAllOnClear = (form, key, customValue) => {
  setTimeout(() => {
    form.setFieldValue(key, customValue === undefined ? '' : customValue);
  }, 0);
};

export const findBasicByCode = (data, value, key = 'code') =>
  data.find(el => el?.[key]?.toLowerCase?.() === value?.toLowerCase?.());

export const convertPriceFormat = (text, isPositive) => {
  const isMinus = !isPositive ? text.includes('-') || text?.[0]?.includes('-') : false;

  let digit = `${String(text)
    .replace(/([a-zA-Z]|\.|-|%|,)/g, '')
    .replace(/(^0+(?!$))/g, '')}`;

  if (!text.match(/\./g)) {
    digit = `${digit}00`;
  } else if (text.slice(-2)[0] === '.') {
    digit = `${digit}0`;
  }

  return `${isMinus ? '-' : ''}${digit.slice(0, -2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}.${
    digit.slice(-2)?.[0] || 0
  }${digit.slice(-1) || 0}`;
};

export const sortAllocData = data => {
  const filteredData = data.filter(el => el.AllocStatus_id?.code !== 'Canceled');
  const pendingData = filteredData.filter(
    el => el.AllocStatus_id?.code === 'Pending' || el.AllocStatus_id?.code === 'Allocated'
  );
  const leftData = filteredData.filter(
    el => el.AllocStatus_id?.code !== 'Pending' || el.AllocStatus_id?.code !== 'Allocated'
  );
  return [...pendingData, ...leftData];
};

export const convertProfileData = (defData, values) => {
  const defCopy = { ...defData };
  Object.keys(values).forEach(key => {
    if (additionalQuestionsKeys.includes(key)) {
      defCopy.additionalQuestions[key] = values[key];
    } else {
      defCopy[key] = values[key];
    }
  });
  return defCopy;
};

export const checkMultiple = count => (count && parseInt(count, 10) > 1 ? 's' : '');

export const checkMultipleText = (count, text) =>
  count && parseInt(count, 10) > 1 ? `${text}s` : text;

export const filterOption = (value, option) => {
  const children = Array.isArray(option?.children)
    ? (option?.children || []).join('')
    : option?.children;
  return (children || '').toLowerCase().includes(value.toLowerCase());
};

export const filterEmptyValues = values => {
  const extraData = {};
  Object.keys(values).forEach(key => {
    if (values[key]) {
      extraData[key] = values[key];
    }
  });
  return extraData;
};

export const nullifyEmpties = values => {
  const extraData = {};
  Object.keys(values).forEach(key => {
    if (!values[key]) {
      extraData[key] = null;
    }
  });
  return { ...values, ...extraData };
};

export const checkExistIcon = (item, greenCheck) =>
  item ? <CheckOutlined style={{ color: greenCheck ? '#14B354' : '#EF3E36' }} /> : '-';

export const checkExistCloseIcon = item =>
  item ? <CloseOutlined style={{ color: '#EF3E36' }} /> : '-';

export const checkActiveIcon = (item, props = {}) =>
  item ? (
    <CheckOutlined style={{ color: '#14B354' }} {...props} />
  ) : (
    <CloseOutlined style={{ color: '#EF3E36' }} {...props} />
  );

export const checkHeaderIcon = (type, customTitle) => (
  <Tooltip placement='top' title={customTitle || type}>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {
        {
          Active: <CheckOutlined style={{ color: '#14B354' }} />,
          Locked: <CheckOutlined style={{ color: '#EF3E36' }} />,
          Action: <ActionIcon style={{ color: '#EF3E36' }} />,
          Inactive: <CloseOutlined style={{ color: '#EF3E36' }} />,
          Web: <GlobalOutlined style={{ color: EditOutlineColor }} />,
          ScopeLimit: '-[ ]-',
        }[type]
      }
    </div>
  </Tooltip>
);

export const twoDigitFormat = num =>
  Number(num).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

export const checkAllSelect = (values = [], key, form, setState = () => {}, customAny) => {
  const any = customAny || 'any';
  if (values.includes('all')) {
    if (values.indexOf('all') !== 0) {
      setFieldValue(key, ['all'], setState, form);
    }
    if (values.length === 1 && values.indexOf('all') === 0) {
      setFieldValue(key, values, setState, form);
    }
    if (values.length > 1 && values.indexOf('all') === 0) {
      setFieldValue(
        key,
        values.filter(el => el !== 'all'),
        setState,
        form
      );
    }
  } else if (values.includes(any)) {
    if (values.indexOf(any) !== 0) {
      setFieldValue(key, [any], setState, form);
    }
    if (values.length === 1 && values.indexOf(any) === 0) {
      setFieldValue(key, values, setState, form);
    }
    if (values.length > 1 && values.indexOf(any) === 0) {
      setFieldValue(
        key,
        values.filter(el => el !== any),
        setState,
        form
      );
    }
  } else {
    setFieldValue(
      key,
      values.filter(el => el !== 'all' && el !== 'any'),
      setState,
      form
    );
  }
};

const setFieldValue = (key, value, setState, form) => {
  form.setFieldsValue({ [key]: value });
  if (setState) {
    setState(value);
  }
};

export const createPrintUrl = (fileId, type) =>
  `${API_ROOT}converto/custom/downloadFile?fileId=${fileId}&type=${type}`;

export const dataURItoBlob = dataURI => {
  if (!dataURI) return {};
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  // const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: 'image/png' });
};

export const convertPrice = (price, checkNegative, showDollar, showEmpty) => {
  if (showEmpty && !price) {
    return '';
  }

  let num = Number(price || 0).toLocaleString(undefined, {
    minimumFractionDigits: 2,
  });
  if (checkNegative && Number(price || 0) < 0) {
    num = (
      <span style={{ color: 'red' }}>
        ({showDollar && '$ '}
        {Number(Math.abs(price || 0)).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}
        )
      </span>
    );
  } else if (showDollar) {
    num = `$ ${num}`;
  }
  return num;
};

export const convertFileUrl = url => {
  if (!url) return '';
  return url?.includes('https://') ? url : `https://${url}`;
};

export const checkValidUrl = url => {
  return url?.startsWith('http') ? url : `//${url}`;
};
export const convertModelChoice = el => {
  return `${el?.ModelName || el?.ModelCode} ${el?.ModelUnitType ? `| ${el?.ModelUnitType}` : ''}`;
};

export const convertUnitLabel = el =>
  `${el?.UnitNo}${el?.ProjModel_id?.ModelName ? ` | ${el?.ProjModel_id?.ModelName}` : ''}${
    el?.ModelUnitType ? ` | ${el?.ModelUnitType}` : ''
  }`;

export const columnTextSort = (a, b) => {
  const first = a || '';
  const last = b || '';
  if (first < last) {
    return -1;
  }
  if (first > last) {
    return 1;
  }
  return 0;
};

export const convertPSF = el =>
  Math.round(Number(el?.SalesListPrice || 0) / Number(el?.UnitFloorArea || 0));

export const convertDateToMomentObj = (data, keys) => {
  let object = {};
  keys?.forEach(item => {
    object = {
      ...object,
      [item]: data?.[item] ? moment(data?.[item]) : null,
    };
  });
  return object;
};

export const convertDateToMomentFormatted = (data, keys, format = '') => {
  let object = {};
  keys?.forEach(item => {
    object = {
      ...object,
      [item]: data?.[item] ? moment(data?.[item]).format(format) : '',
    };
  });
  return object;
};

export const defValueForMasked = ([data, keys]) => {
  let object = {};
  keys?.forEach(item => {
    object = {
      ...object,
      [item]: data?.[item] || '',
    };
  });
  return object;
};

export const convertAllocTotals = (data, priceKey) => {
  const filteredData = data.filter(el => el?.ProjUnit_id);
  const psfSum = filteredData
    ?.map(el => el.UnitAllocPSF || 0)
    ?.reduce((prev, curr) => +prev + +curr, 0);

  return {
    units: filteredData?.length,
    price: filteredData
      ?.map(el => (priceKey ? el[priceKey] : el.SalesListPrice) || 0)
      ?.reduce((prev, curr) => +prev + +curr, 0),
    psf: Math.round(psfSum / filteredData?.length) || 0,
  };
};

export const sortSaleDecorOptions = (data, isCategory, extraSortData, nested) =>
  extraSortData
    ? data?.sort((a, b) => {
        const keys = extraSortData?.key.split('.');
        if (nested) {
          if (
            (a?.[keys[0]]?.[keys[1]] || 'zzzzz')?.toLowerCase() <
            (b?.[keys[0]]?.[keys[1]] || 'zzzzz')?.toLowerCase()
          ) {
            return extraSortData?.order === 'ascend' ? -1 : 1;
          }
          if (
            (a?.[keys[0]]?.[keys[1]] || 'zzzzz')?.toLowerCase() >
            (b?.[keys[0]]?.[keys[1]] || 'zzzzz')?.toLowerCase()
          ) {
            return extraSortData?.order === 'ascend' ? 1 : -1;
          }
        } else {
          if (
            (a?.[extraSortData?.key] || 'zzzzz')?.toLowerCase() <
            (b?.[extraSortData?.key] || 'zzzzz')?.toLowerCase()
          ) {
            return extraSortData?.order === 'ascend' ? -1 : 1;
          }
          if (
            (a?.[extraSortData?.key] || 'zzzzz')?.toLowerCase() >
            (b?.[extraSortData?.key] || 'zzzzz')?.toLowerCase()
          ) {
            return extraSortData?.order === 'ascend' ? 1 : -1;
          }
        }
        return 0;
      })
    : data?.sort((a, b) =>
        isCategory
          ? (a?.OptType_id?.SeqNo || 0) - (b?.OptType_id?.SeqNo || 0) ||
            (a?.Category_id?.SelectSeqNo || 0) - (b?.Category_id?.SelectSeqNo || 0) ||
            (a?.SubCateg_id?.SelectSeqNo || 0) - (b?.SubCateg_id?.SelectSeqNo || 0)
          : (a?.OptType_id?.SeqNo || 0) - (b?.OptType_id?.SeqNo || 0) ||
            (a?.SubCateg_id?.SelectSeqNo || 0) - (b?.SubCateg_id?.SelectSeqNo || 0)
      );

export const convertItemRefsSortList = list =>
  list?.[0]?.column ? { key: list?.[0]?.column?.sortKey, order: list?.[0]?.order } : null;

export const checkSmallestFormat = formats =>
  formats?.thumbnail?.url || formats?.small?.url || formats?.medium?.url || formats?.large?.url;

export const sortEditAlloc = data => ({
  ...data,
  rqAllocBuyers_Id: data?.rqAllocBuyers_Id?.sort((a, b) => a.id - b.id),
});

export const filterAgentsFunc = data => [
  ...new Map(
    data?.filter(el => el?.BrokerAgent_id?.id)?.map(item => [item?.BrokerAgent_id?.id, item])
  ).values(),
];

export const uniqueAgents = data =>
  [...new Set(data.map(item => item?.id))]?.map(el => data.find(item => item?.id === el));

export const filterUniqueDataIds = data =>
  [...new Set(data.map(item => item?.id))]?.map(el => data.find(item => item?.id === el));

export const checkFileRefActive = data => !!(data?.AcceptUser_id || data?.ApproveUser_id);
export const checkFileRefLocked = data => !!data?.LockUser_id;
export const checkFileRefReviewed = data => !!data?.AuditUser_id;

const SSNReg = new RegExp(ssnPattern);

export const isValidSSN = str => SSNReg.test(str);

export const downloadFileWithHeader = (url, fileName = '', setLoading = () => {}) => {
  setLoading(true);
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);

  const headers = new Headers();
  headers.append('Authorization', `Bearer ${getAccessToken()}`);

  fetch(url, { headers })
    .then(async response => {
      if (response.status === 400) {
        const res = await response.json();
        return Promise.reject(new Error(res?.message || ''));
      }
      return response.blob();
    })
    .then(blobby => {
      const objectUrl = window.URL.createObjectURL(blobby);
      setLoading(false);
      anchor.href = objectUrl;
      anchor.download = fileName || 'file';
      anchor.click();
      window.URL.revokeObjectURL(objectUrl);
    })
    .catch(err => {
      notification.error({ description: err?.message });
      setLoading(false);
    });
};

export const downloadFileWithPOST = (data, fileName = '', setLoading = () => {}) => {
  setLoading(true);
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);
  fetch(`${API_ROOT}custom/DownloadImagePost`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(async response => {
      if (response.status === 400) {
        const res = await response.json();
        return Promise.reject(new Error(res?.message || ''));
      }
      return response.blob();
    })
    .then(blobby => {
      const objectUrl = window.URL.createObjectURL(blobby);
      setLoading(false);
      anchor.href = objectUrl;
      anchor.download = fileName || 'file';
      anchor.click();
      window.URL.revokeObjectURL(objectUrl);
    })
    .catch(err => {
      notification.error({ description: err?.message });
      setLoading(false);
    });
};

export const getValidIdType = (idTypes = [], type) =>
  idTypes.find(el => el.value === type)?.id || null;

export const backToAdminFromSale = () => {
  const adminState = loadState('admin_state');
  saveState(adminState);
  emptyState('sales_data');
  emptyState('admin_state');
  emptyState('applicationId');
  window.location.pathname = '/project-sales';
};

export const toStartOfDay = date => (date ? moment(date).startOf('day') : moment().startOf('day'));

export const blobToBase64 = blob => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};
