import Icon from '@ant-design/icons';

const CaseSvg = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20.0846 6.42841H15.5188V4.58864C15.5188 3.71264 14.8062 3 13.9302 3H9.26865C8.39265 3 7.67997 3.71264 7.67997 4.58864V6.42841H3.11425C1.95117 6.42841 1.0049 7.37468 1.0049 8.53776C1.0049 9.59425 0.896702 10.543 1.69731 11.61C2.15028 12.2137 2.77276 12.6444 3.49742 12.8553L10.5344 14.9042C11.231 15.107 11.9676 15.1071 12.6643 14.9042L19.7013 12.8553C20.426 12.6443 21.0484 12.2137 21.5014 11.61C22.3056 10.5382 22.1938 9.5797 22.1938 8.53776C22.1939 7.37468 21.2476 6.42841 20.0846 6.42841ZM9.0256 4.58864C9.0256 4.45462 9.13463 4.34563 9.26865 4.34563H13.9302C14.0642 4.34563 14.1732 4.45467 14.1732 4.58864V6.42841H9.0256V4.58864Z'
      fill='#717579'
    />
    <path
      d='M20.0776 14.1474C12.4874 16.3573 12.5856 16.402 11.5995 16.402C10.6204 16.402 10.8526 16.3984 3.12138 14.1474C2.30623 13.91 1.5843 13.4725 1.005 12.8694V19.3265C1.005 20.4896 1.95123 21.4358 3.11436 21.4358H20.0846C21.2477 21.4358 22.194 20.4896 22.194 19.3265V12.8694C21.6146 13.4725 20.8927 13.91 20.0776 14.1474Z'
      fill='#717579'
    />
  </svg>
);

const CaseIcon = props => <Icon component={CaseSvg} {...props} />;

export default CaseIcon;
