import styled, { css } from 'styled-components';
import { Tabs as tabs } from 'antd';
import { darkBlue, mainColor, salesMainColor } from '../../../constants/colors';

const Tabs = styled(tabs)`
  .ant-tabs-tab {
    .ant-tabs-tab-btn {
      font-size: 24px;
      color: #717579;
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      font-weight: 600;
    }
  }

  .ant-tabs-ink-bar {
    background-color: ${mainColor};
    height: 3px;
    border-radius: 2px;
  }
  ${props =>
    props.tab_btn_color &&
    css`
      .ant-tabs-tab-btn {
        color: ${props.tab_btn_color}!important;
      }
    `}
  ${props =>
    props.tab_min_width &&
    css`
      .ant-tabs-tab {
        min-width: ${props.tab_min_width};
      }
    `}
  ${props =>
    props.tab_justify &&
    css`
      .ant-tabs-tab {
        display: flex;
        justify-content: ${props.tab_justify};
      }
    `}
  ${props =>
    props.list_padding &&
    css`
      .ant-tabs-nav-wrap {
        padding: ${props.list_padding}!important;
      }
    `}

  ${props =>
    props.tab_btn_fz &&
    css`
      .ant-tabs-tab-btn {
        font-size: ${props.tab_btn_fz}px!important;
      }
    `}
  ${props =>
    props.overflow &&
    css`
      overflow: ${props.overflow};
    `}
  ${props =>
    props.tab_btn_padding &&
    css`
      .ant-tabs-tab-btn {
        padding: ${props.tab_btn_padding}px!important;
      }
    `}
  ${props =>
    props.head_mb &&
    css`
      .ant-tabs-nav {
        margin-bottom: ${props.head_mb}!important;
      }
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width}!important;
    `}
  ${props =>
    props.active_tab_btn_fw &&
    css`
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          font-weight: ${props.active_tab_btn_fw} !important;
        }
      }
    `}
  ${props =>
    props.tab_btn_fw &&
    css`
      .ant-tabs-tab-btn {
        font-weight: ${props.tab_btn_fw} !important;
      }
    `}
  ${props =>
    props.active_tab_btn_color &&
    css`
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: ${props.active_tab_btn_color} !important;
        }
      }
    `}
  ${props =>
    props.active_tab_back_color &&
    css`
      .ant-tabs-tab-active {
        background-color: ${props.active_tab_back_color} !important;
        border-bottom-color: ${props.active_tab_back_color} !important;
      }
    `}
  ${props =>
    props.active_sider_color &&
    css`
      .ant-tabs-ink-bar {
        background-color: ${props.active_sider_color};
      }
    `}
  ${props =>
    props.bar_bottom &&
    css`
      .ant-tabs-ink-bar {
        bottom: ${props.bar_bottom}!important;
      }
    `}
    ${props =>
    props.type === 'sales' &&
    css`
      margin: 10px 0;
      & .ant-tabs-nav {
        margin-bottom: 0px !important;
        &::before {
          display: none;
        }
      }
      & .ant-tabs-ink-bar {
        display: none;
      }
      .ant-tabs-nav-wrap {
        width: 100%;
      }
      & .ant-tabs-nav-list {
        justify-content: space-between;
        align-items: center;
        flex: auto;
        max-width: 820px;
        & .ant-tabs-tab + .ant-tabs-tab {
          margin-left: 5px !important;
        }
      }
      & .ant-tabs-tab {
        margin-left: 0px;
        border-bottom: 5px double #e5e5e5;
        border-top: 1px solid #e5e5e5;
        padding: 5px !important;
        & .ant-tabs-tab-btn {
          font-size: 12px;
          line-height: 12px;
          margin: 0;
        }
      }
      & .ant-tabs-tab-active {
        border-bottom-color: ${salesMainColor} !important;
        border-top-color: ${darkBlue} !important;
        & .ant-tabs-tab-btn {
          color: ${darkBlue};
        }
      }
    `}
    ${props =>
    props.type === 'login' &&
    css`
      & > .ant-tabs-nav > .ant-tabs-nav-wrap {
        background: ${mainColor} !important;
        border-radius: 4px 4px 0 0;
        & .ant-tabs-tab-btn {
          color: white !important;
        }
        & .ant-tabs-ink-bar {
          background: white !important;
        }
      }
      & input,
      & .ant-input-password {
        box-shadow: none !important;
      }
      & input:hover,
      & input:focus,
      & .ant-input-password:focus,
      & .ant-input-password:hover {
        border-color: ${mainColor} !important;
      }
    `}
      ${props =>
    props.header_color &&
    css`
      & .ant-tabs-tab .ant-tabs-tab-btn,
      & .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${props.header_color} !important;
      }
      & .ant-tabs-ink-bar {
        background: ${props.header_color} !important;
      }
    `}
    ${props =>
    props.type === 'broker-dashboard' &&
    css`
      background: #ffffff;
      & .ant-tabs-nav {
        margin-bottom: 5px !important;
      }
      & .ant-list-item-action li {
        padding: 0 !important;
        & em {
          display: none;
        }
      }

      & .ant-tabs-nav-list {
        & .ant-tabs-tab-btn {
          width: 285px;
          text-align: center;
          padding: 0 !important;
          color: #000;
        }
        & .ant-tabs-tab {
          height: 42px;
          margin-left: 0;
          padding: 0 !important;
          align-items: center;
          justify-content: center;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            right: -1px;
            bottom: 17%;
            border-right: 1px solid #d7d7d7;
            width: 1px;
            height: 30.8px;
          }
        }
        & .ant-tabs-ink-bar {
          display: none;
        }
        & .ant-tabs-tab-active {
          background: white;
          border-top: 1px solid #d7d7d7;
          border-right: 1px solid #d7d7d7;
          border-left: 1px solid #d7d7d7;
          &::before {
            display: none;
          }
        }
        & .ant-tabs-nav {
          display: none;
        }
      }
      @media screen and (max-width: 768px) {
        & .ant-tabs-nav-list {
          & .ant-tabs-tab-btn {
            width: 160px;
          }
        }
      }
    `}
`;

export default Tabs;
