export const API_ROOT = process.env.REACT_APP_API_ROOT || 'https://in2.digilabs.ca/';
export const APP_VERSION = process.env.REACT_APP_VERSION || '';
export const TINY_MCE_PATH =
  process.env.REACT_APP_TINY_MCE_PATH ||
  'https://nyc3.digitaloceanspaces.com/digilabs/lawvo/tinymce/tinymce.min.js';
export const SYNCFUSION_KEY =
  process.env.REACT_APP_SYNCFUSION_KEY ||
  'Ngo9BigBOggjHTQxAR8/V1NHaF5cWWdCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXdednVVQmJdVEN2W0E=';
export const PDF_VIEWER_SERVICE_URL =
  process.env.REACT_APP_PDF_VIEWER_SERVICE_URL || 'https://pdf.digilabs.ca/api/pdfviewer';
