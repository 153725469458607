/* eslint-disable import/no-cycle */
import { IconWrapper } from '../../../components/atoms';

const ActionSvg = () => (
  <svg width='16' height='16' viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.2791 3H29.4737C32.9425 3 36 5.99514 36 10V29C36 33.0049 32.9425 36 29.4737 36H13.2791C10.517 36 8.01576 34.101 7.11118 31.3054C6.87521 30.5761 6.24459 30 5.47811 30V30C4.57795 30 3.8637 30.7806 4.09126 31.6515C5.19812 35.8875 8.89232 39 13.2791 39H29.4737C34.7349 39 39 34.5228 39 29V10C39 4.47715 34.7349 0 29.4737 0H13.2791C9.30373 0 5.89714 2.55608 4.4694 6.18798C4.111 7.09968 4.85282 8 5.83243 8V8C6.53383 8 7.13275 7.52253 7.42797 6.88629C8.51702 4.53925 10.7923 3 13.2791 3Z'
      fill='black'
    />
    <path
      d='M6.923 10.5385C5.75146 11.71 5.38454 13.1023 5.38454 16.38C5.38454 20.0354 5.92223 20.8277 4.61531 21.1692C3.76916 21.3908 2.30762 21.7515 2.30762 22.0738C2.30762 22.9262 9.14531 28.3985 9.83608 28.0992C11.1453 27.5323 17.6345 22.4238 17.6622 21.9385C17.6784 21.65 16.9138 21.3038 15.9615 21.1692C14.4253 20.9508 14.2368 20.4977 14.2876 17.1377C14.3368 13.8085 14.6584 13.09 16.9507 11.1762L19.5568 9H14.0092C9.48685 9 8.17685 9.28462 6.923 10.5385Z'
      fill='black'
    />
    <path d='M10 29.7692L0 21.3076L20 21.3076L10 29.7692Z' fill='black' />
  </svg>
);

const ActionIcon = props => <IconWrapper component={ActionSvg} {...props} />;

export default ActionIcon;
